import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { Modal } from '../../../../modal';

import CheckoutForm from './checkoutForm';

import './style.scss';
// eslint-disable-next-line import/order
import { SubscriptionsViewModel } from '../../../../../shared/common/models/subscription';

const appearance = {
    theme: 'stripe',
};
const stripePromise = loadStripe((process.env as any).REACT_APP_STRIPE_PUBLISH_KEY);

export const PaymentForm = ({
    onClose,
    clientSecret,
    subscription,
}: {
    onClose: () => void;
    clientSecret: string;
    subscription: SubscriptionsViewModel;
}) => {
    const options = {
        clientSecret,
        appearance,
    };
    return (
        <Modal onClose={onClose} isOpen={true} title="PAYMENT">
            <div style={{ marginBottom: 12 }}>
                <span className="subscription-payment-info-text ">
                    Price for "{subscription.name}": {subscription.price} {subscription.currency}
                </span>
            </div>
            {clientSecret && (
                <Elements options={options as any} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
        </Modal>
    );
};
