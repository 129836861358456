import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { httpService } from '../../common/http';
import { useAuthValidation } from '../../hooks/useAuthValidation';
import { useCountries } from '../../hooks/useCountries';
import { AppDispatch } from '../../redux';
import { changeCountry, changePassword } from '../../redux/actions/users';
import { authenticatedUserSelector } from '../../redux/selectors/auth';
import { setUser } from '../../redux/slices/auth';
import { backendRoutes } from '../../routes/backend';

import { ProfileFormData } from './model';
import { View } from './view';

export const Profile = () => {
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector(authenticatedUserSelector);
    const [isOpen, setIsOpen] = useState(false);

    useAuthValidation();
    const countries = useCountries();

    const countriesOptions = useMemo(() => {
        return countries.map(({ id, name }) => ({ label: name, value: id }));
    }, [countries]);

    const methods = useForm<any>({
        defaultValues: {
            country: { label: user.country.name, value: user.country.id },
        },
    });

    const onChangePass = () => {
        setIsOpen(true);
    };
    const onClose = () => setIsOpen(false);

    const onChangePassSubmit = async ({ password, passwordConfirmation, oldPassword }: ProfileFormData) => {
        if (password !== passwordConfirmation) {
            methods.setError('passwordConfirmation', {
                message: "Password confirmation doesn't match Password",
            });
            return;
        }
        try {
            await dispatch(changePassword(password, oldPassword));
            toast.success('Password changed successfully');
            onClose();
            methods.reset();
        } catch (e: any) {
            if (e?.response?.status === 400) {
                methods.setError('oldPassword', {
                    message: 'incorrect old password',
                });
                return;
            }
        }
    };

    const onChangeRegionSubmit = async ({ country }: ProfileFormData) => {
        try {
            await dispatch(changeCountry(country.value));
            toast.success('Country changed successfully');
            const user = await httpService.get(backendRoutes.getUserInfo);
            dispatch(setUser(user));
        } catch (e: any) {
            toast.error('Сountry change error');
        }
    };

    return View({
        methods,
        onSubmit: isOpen ? onChangePassSubmit : onChangeRegionSubmit,
        onChangePass,
        countriesOptions,
        user,
        isOpen,
        onClose,
    });
};
