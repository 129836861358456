import React from 'react';
import { useSelector } from 'react-redux';

import { selectedLastItemSelector } from '../../redux/selectors/keyword';
import { recognizedWordsSelector } from '../../redux/selectors/recognizeText';

import { CategoryLabel } from './label';

import './style.scss';

export const SearchResultscreen = () => {
    const recognizedWords = useSelector(recognizedWordsSelector);
    const selectLastItem = useSelector(selectedLastItemSelector);

    if (selectLastItem || !recognizedWords.length) {
        return null;
    }

    return (
        <div className="search-results-container">
            <div className="labels-container">
                {recognizedWords.map((word, index) => (
                    <CategoryLabel key={index} label={word} isLast={recognizedWords.length === index + 1} />
                ))}
            </div>
        </div>
    );
};
