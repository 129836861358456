import { combineReducers } from '@reduxjs/toolkit';

import { internetResourcesReducer, InternetResourcesState } from './internetResources';
import { otherOptionsReducer, OtherOptionsState } from './otherOptions';
import { subscriptionsReducer, SubscriptionsState } from './subscriptions';

export interface SettingsState {
    internetResources: InternetResourcesState;
    otherOptions: OtherOptionsState;
    subscriptopns: SubscriptionsState;
}

export const settingsReducer = combineReducers<SettingsState>({
    internetResources: internetResourcesReducer,
    otherOptions: otherOptionsReducer,
    subscriptopns: subscriptionsReducer,
});
