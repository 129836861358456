import { Modal } from '../../modal';
import { KeywordInfo } from '../../../common/model/database';

import { Form } from './form';
import { EditingModalProps } from './model';

export const EditingModal = ({ editableNode, onClose, onDeleteNode }: EditingModalProps) => {
    const title = editableNode?.id === 'new' ? 'New item' : 'Editing the item';
    const onDelete = () => {
        if (editableNode && typeof editableNode.id === 'number') {
            onDeleteNode(editableNode as KeywordInfo);
        }
    };

    return (
        <Modal title={title} isOpen={Boolean(editableNode)} onClose={onClose}>
            {editableNode && <Form onClose={onClose} onDelete={onDelete} editableNode={editableNode} />}
        </Modal>
    );
};
