import { ReactComponent as EditSvg } from '../../assets/edit.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const EditIcon = (props: IconProps) => {
    return (
        <IconWrapper {...props}>
            <EditSvg />
        </IconWrapper>
    );
};
