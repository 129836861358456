import { DataTable } from '../../../components/dataTable';
import { Button } from '../../../components/button';
import { ButtonType } from '../../../common/enum/buttonType';

import { ViewProps } from './model';
import { SourceModal } from './subscriptionModal';

import './style.scss';

export const View = (props: ViewProps) => {
    const { columns, data, keyField, openNewSubscriptionModal, isSubscriptionModalOpen } = props;
    return (
        <div className="internet-resource">
            {isSubscriptionModalOpen && <SourceModal />}
            <Button
                className="add-resource-button"
                title="Add a new subscription"
                type={ButtonType.primary}
                onClick={openNewSubscriptionModal}
            />
            <DataTable className="internet-resource-table" columns={columns} data={data} keyField={keyField} />
        </div>
    );
};
