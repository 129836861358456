import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AppDispatch } from '../../../redux';
import { logout } from '../../../redux/actions/auth';
import { authenticatedUserSelector } from '../../../redux/selectors/auth';
import { UserTypes } from '../../../common/enum/userTypes';

import { AuthHeaderView } from './view';
import { getNavLinkData } from './constants';

export const AuthHeader = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector(authenticatedUserSelector);

    const navLinkData = getNavLinkData(user.type === UserTypes.admin);

    return AuthHeaderView({
        navLinkData,
        onLogout: () => {
            dispatch(logout());
            navigate('/', { replace: true });
        },
        isEmailConfirmed: user.isEmailConfirmed ?? false,
    });
};
