import cn from 'classnames';

import { FontTitle } from '../../../components/fontTitle';
import { Button } from '../../../components/button';
import { ButtonType } from '../../../common/enum/buttonType';
import { AddIcon } from '../../../components/icons/addIcon';
import { Pagination } from '../../../components/pagination';

import { ViewProps } from './model';
import { Article } from './article';

import './style.scss';

export const View = (props: ViewProps) => {
    const { className, articles, onNewArticleClick } = props;
    return (
        <div className={cn(className, 'catalog-articles')}>
            <div className="catalog-articles-header">
                <FontTitle level={2}>Section Articles</FontTitle>
                {props.showButton ? (
                    <Button
                        onClick={onNewArticleClick}
                        uppercase={false}
                        title="Add new article"
                        type={ButtonType.primary}
                        icon={<AddIcon />}
                    />
                ) : (
                    <></>
                )}
            </div>

            <div className="catalog-articles-list">
                {articles.map((article) => (
                    <Article key={article.id} {...article} />
                ))}
            </div>

            <Pagination
                className="catalog-articles-pagination"
                onPageChange={props.onChangePage}
                currentPage={props.page}
                pagesCount={props.pages}
            />
        </div>
    );
};
