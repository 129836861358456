import { ContentWrapper } from '../../components/contentWrapper';
import { FontTitle } from '../../components/fontTitle';
import { TableUsers } from '../../components/tableUsers';
import { User } from '../../components/tableUsers/model';

import './style.scss';

export const View = (users: User[], admin: User[]) => {
    return (
        <ContentWrapper>
            <div className="users-page">
                <div className="header-users">
                    <div className="users-page-header">
                        <FontTitle level={1}>Users</FontTitle>
                    </div>
                    <div className="infoGroup">
                        <p> All:{users.length}</p>
                        <p> Online:{users.length}</p>
                        <p>Administrator:{admin.length}</p>
                    </div>
                </div>
                <div>
                    <TableUsers users={users} />
                </div>
            </div>
        </ContentWrapper>
    );
};
