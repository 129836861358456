import React from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { FormField } from '../../../formField';
import { ParentNodeSelector } from '../../../parentNodeSelector';
import { Button } from '../../../button';
import { ButtonType } from '../../../../common/enum/buttonType';
import { DeleteIcon } from '../../../icons/deleteIcon';
import { AppDispatch } from '../../../../redux';
import { createKeyword, getKeywords, updateKeyword } from '../../../../redux/actions/keyword';
import { AddIcon } from '../../../icons/addIcon';
import { CreateExternalSource } from '../../../../common/model/externalSource';

import { FormProps, KeywordFormData } from './model';
import { mapFormDataToRequest, mapTreeNodeToFormData } from './utils';

import './styles.scss';

export const Form = ({ onClose, onDelete, editableNode }: FormProps) => {
    const dispatch: AppDispatch = useDispatch();
    const form = useForm<KeywordFormData>({
        defaultValues: mapTreeNodeToFormData(editableNode),
    });

    const { formState } = form;

    const {
        fields: synonyms,
        append,
        remove: removeSynonym,
    } = useFieldArray<KeywordFormData>({
        control: form.control,
        name: 'synonyms',
    });

    const onSubmit = async (data: KeywordFormData) => {
        try {
            let newNode;
            if (editableNode.id === 'new') {
                newNode = await dispatch(createKeyword(mapFormDataToRequest(data)));
            } else {
                newNode = await dispatch(
                    updateKeyword({
                        id: editableNode.id,
                        ...mapFormDataToRequest(data),
                    }),
                );
            }

            dispatch(getKeywords());
            if (editableNode.id === 'new') {
                onClose();
            }

            toast.success(
                `Keyword "${newNode.key}" was successfully ${editableNode.id === 'new' ? 'created' : 'updated'}`,
            );
        } catch (e: any) {
            toast.error(e?.message ?? 'Failed to create/update keyword');
        }
    };

    return (
        <FormProvider {...form}>
            <form className="keyword-form" onSubmit={form.handleSubmit(onSubmit)}>
                <div className="keyword-info">
                    <FormField type="text" name="key" title="Item name" rules={{ required: true }} />
                    <Controller
                        render={({ field }) => (
                            <ParentNodeSelector
                                hiddenNodeIds={typeof editableNode.id === 'number' ? [editableNode.id] : undefined}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                        name="parentNode"
                    />
                </div>

                <div className="synonyms-list">
                    {synonyms.map((item, index) => (
                        <div key={item.id} className="synonym-wrapper">
                            <FormField<CreateExternalSource>
                                error={formState.errors['synonyms']?.[index]?.value}
                                title="Synonym"
                                type="text"
                                rules={{ required: true }}
                                name={`synonyms[${index}].value`}
                            />
                            <DeleteIcon onClick={() => removeSynonym(index)} className="delete-synonym-icon" />
                        </div>
                    ))}
                </div>
                <Button
                    className="add-synonym-button"
                    onClick={() => append({ value: '' })}
                    uppercase={false}
                    title="Add synonym"
                    type={ButtonType.borderOffPrimary}
                    icon={<AddIcon />}
                />
                <div className="keyword-form-buttons-block">
                    <Button
                        title="Save"
                        type={ButtonType.primary}
                        buttonType="submit"
                        disabled={form.formState.isSubmitting}
                    />
                    <Button
                        title="Cancel"
                        type={ButtonType.secondary}
                        onClick={onClose}
                        disabled={form.formState.isSubmitting}
                    />
                    {editableNode.id !== 'new' && (
                        <Button
                            title="Delete item"
                            className="keyword-form-buttons-block-delete"
                            icon={<DeleteIcon />}
                            type={ButtonType.borderOff}
                            onClick={onDelete}
                            disabled={form.formState.isSubmitting}
                        />
                    )}
                </div>
            </form>
        </FormProvider>
    );
};
