import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as CheckIcon } from '../../assets/check-icon.svg';

import { ViewProps } from './model';
import './style.scss';

export const View = forwardRef<HTMLInputElement, ViewProps>(({ value, onChange, size = 18, label }, ref) => {
    return (
        <label className={classNames('base-checkbox-container')}>
            <input ref={ref} type="checkbox" checked={value} onChange={onChange} className="input-checkbox" />
            <div
                className={classNames('base-checkbox-icon-box', { ['base-checkbox-icon-box-checked']: value })}
                style={{ width: size, height: size }}
            >
                <CheckIcon className="base-checkbox-icon" />
            </div>
            <div className="base-checkbox-label">{label}</div>
        </label>
    );
});
