import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAdminValidation } from '../../../hooks/useAdminValidation';
import { AppDispatch, RootState } from '../../../redux';
import { getStatistic } from '../../../redux/actions/reports';

import { View } from './view';

export const RecognitionMinutes = () => {
    useAdminValidation();

    const dispatch: AppDispatch = useDispatch();
    const statisticData = useSelector((state: RootState) => state.reports.statisticData);

    useEffect(() => {
        dispatch(getStatistic());
    }, [dispatch]);

    return View({
        data: statisticData,
    });
};
