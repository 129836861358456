import React from 'react';

import { FontTitle } from '../../../../../components/fontTitle';
import { EditableItem } from '../../../../../components/editableItem';
import { Button } from '../../../../../components/button';
import { ButtonType } from '../../../../../common/enum/buttonType';
import { AddIcon } from '../../../../../components/icons/addIcon';
import { EditModal } from '../editModal';
import { ModalState } from '../editModal/model';

import { FIELD_KEY_NAME, ViewProps } from './model';

export const View = ({
    fields,
    title,
    modalData,
    onShowModal,
    modalTitle,
    onCloseModal,
    addButtonTitle,
    onDelete,
    onAddUpdate,
}: ViewProps) => (
    <>
        <section className="section">
            <FontTitle marginBottom={16} level={3}>
                {title}
            </FontTitle>
            <div className="items-list">
                {fields.map((field, index) => (
                    <EditableItem
                        key={field[FIELD_KEY_NAME]}
                        label={field.name}
                        onDeleteClick={() => onDelete(index)}
                        onEditClick={() => onShowModal(ModalState.Edit, field, index)}
                    />
                ))}
            </div>
            <Button
                className="add-button"
                uppercase={false}
                title={addButtonTitle}
                type={ButtonType.borderOff}
                icon={<AddIcon />}
                onClick={() => onShowModal(ModalState.New)}
            />
        </section>
        <EditModal
            onAddUpdate={onAddUpdate}
            data={modalData}
            isOpen={Boolean(modalData)}
            onClose={onCloseModal}
            title={modalTitle}
        />
    </>
);
