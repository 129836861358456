export const Routes = {
    home: '/',
    login: '/login',
    registration: '/registration',
    workzone: '/workzone',
    database: '/admin/database',
    databaseNewArticle: '/admin/database/article/new',
    databaseEditrticle: '/admin/database/article/:id',
    databaseCopyArticle: '/admin/database/article/copy/:id',
    users: '/admin/users',
    profile: '/profile',
    profilePaymentSuccesfull: '/profile/payment/success',
    profilePaymentHistory: '/profile/payment/history',
    profileSubscriptionUpdate: '/profile/subscription/choose',
    settings: '/admin/settings',
    reports: '/admin/reports',
    aboutUs: '/about-us',
    signUp: '/signup',
    documentDetails: (id: string | number) => `/workzone/document/${id}`,
} as const;
