import './style.scss';

import React from 'react';
import classnames from 'classnames';

import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { Modal } from '../modal';
import { Tree } from '../tree';
import { Button } from '../button';
import { ButtonType } from '../../common/enum/buttonType';

import { ViewProps } from './model';

export const View = ({
    showModal,
    setShowModal,
    node,
    onSelect,
    setLocalValue,
    data,
    hiddenNodeIds,
    showEdit,
}: ViewProps) => {
    return (
        <>
            <div className="parent-node-selector-box">
                <div className="parent-node-selector-value-box">
                    <span className={classnames('parent-node-selector-title', { ['with-value']: node })}>
                        Parent node
                    </span>
                    {node && <span className="parent-node-selector-value">{node.key}</span>}
                </div>
                {showEdit && (
                    <button type="button" className="parent-node-selector-button" onClick={() => setShowModal(true)}>
                        <EditIcon />
                    </button>
                )}
            </div>
            <Modal isOpen={showModal} title="Select the parent node" onClose={() => setShowModal(false)}>
                {/* <Input value={''} title="Search " type="text" className="parent-node-selector-modal-search" /> */}
                {data && (
                    <Tree
                        unselectable
                        items={data}
                        onSelect={setLocalValue}
                        defaultSelectedNode={node}
                        hiddenNodeIds={hiddenNodeIds}
                    />
                )}
                <div className="parent-node-selector-modal-buttons">
                    <Button title="Select" type={ButtonType.primary} onClick={onSelect} />
                    <Button title="Cancel" type={ButtonType.secondary} onClick={() => setShowModal(false)} />
                </div>
            </Modal>
        </>
    );
};
