export enum OtherOptionName {
    SupportEmail = 'support_email',
    SupportPhone = 'support_phone',
    LastFoundCount = 'last_found_count',
    MostPopularCount = 'most_popular_count',
    KeepDays = 'keep_days',
    LastRecognizedWordsCount = 'last_recognized_words_count',
}

export enum SupportOptionName {
    SupportEmail = 'support_email',
    SupportPhone = 'support_phone',
}

export type OtherOptions = Record<OtherOptionName, string>;

export type SupportOptions = Record<SupportOptionName, string>;
