import classNames from 'classnames';
import { useMemo } from 'react';

import { SelectOption } from '../../model';
import { Checkbox } from '../../../checkbox';

export interface Props {
    opened: boolean;
    onSelect: (opt: SelectOption) => void;
    onDeselect: (opt: SelectOption) => void;
    multiple: boolean;
    options?: SelectOption[];
    current?: SelectOption | SelectOption[];
}

export const Options = ({ opened, options: optionsData, current, onSelect, onDeselect, multiple }: Props) => {
    const options = useMemo(() => {
        if (!optionsData || !optionsData.length) {
            return null;
        }

        return optionsData.map((opt) => {
            if (multiple) {
                const isSelected =
                    Array.isArray(current) && current.findIndex(({ value }) => value === opt.value) !== -1;
                const handler = isSelected ? onDeselect : onSelect;

                return (
                    <div key={opt.value} className="base-select-list-item">
                        <Checkbox onChange={() => handler(opt)} label={opt.label} value={isSelected} />
                    </div>
                );
            }

            const isSelected = (current as SelectOption)?.value === opt.value;

            return (
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                        onSelect(opt);
                    }}
                    key={opt.value}
                    className={classNames('base-select-list-item', { ['selected']: isSelected })}
                >
                    {opt.label}
                </div>
            );
        });
    }, [current, multiple, onSelect, onDeselect, optionsData]);

    return <div className={classNames('base-select-list', { ['opened']: opened })}>{options}</div>;
};
