import { createSelector } from 'reselect';

import { UserTypes } from '../../../common/enum/userTypes';
import { User } from '../../../common/model/user';
import { RootState } from '../../index';

export const userSelector = (state: RootState) => state.users.user;

const Users = (state: RootState) => state.users.users;

export const admins = createSelector(Users, (users: User[]) => users.filter((i) => i.type === UserTypes.admin));
