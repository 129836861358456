import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatisticType } from '../../../shared/common/models/statistic';

export interface StatisticData {
    val: string | number;
    type: StatisticType;
}

export interface ResultRecognizeData {
    user: number;
    phrase: string;
    keywords: string;
}

export interface reportsState {
    statisticData: StatisticData[];
    resultRecognizeData: ResultRecognizeData[];
    resultListCount: number;
}

const initialState: reportsState = {
    statisticData: [],
    resultRecognizeData: [],
    resultListCount: 0,
};

export const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        setStatistic: (state, { payload }: PayloadAction<any[]>) => {
            state.statisticData = payload;
        },
        setResultRecognize: (state, { payload }: PayloadAction<any[]>) => {
            state.resultRecognizeData = payload;
        },
        setResultRecognizeCount: (state, { payload }: PayloadAction<number>) => {
            state.resultListCount = payload;
        },
    },
});

export const { setStatistic, setResultRecognize, setResultRecognizeCount } = reportsSlice.actions;

export default reportsSlice.reducer;
