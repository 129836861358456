import { ColumnDescription } from 'react-bootstrap-table-next';

import { ColumnProps } from './model';

export function mapColumnProps<T extends object>(columns: ColumnProps<T>[]): ColumnDescription<T>[] {
    return columns.map((col) => ({
        dataField: col.dataField,
        text: col.columnText ?? '',
        headerStyle: {
            width: col.width,
        },
        align: col.align,
        headerAlign: col.headerAlign,
        formatter: col.renderer != null ? (cell: T, row: T) => col.renderer && col.renderer(row) : undefined,
    }));
}
