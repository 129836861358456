import { Modal } from '../modal';
import { Button } from '../button';
import { ButtonType } from '../../common/enum/buttonType';
import { DeleteIcon } from '../icons/deleteIcon';

import { ViewProps } from './model';

import './style.scss';

export const View = (props: ViewProps) => {
    const { onFilesSelected, files, onClose, handleUploadClick, deleteFile } = props;
    return (
        <Modal isOpen={true} onClose={onClose} className="upload-modal">
            <div>
                <label htmlFor="files" className="upload-label">
                    Select Image
                </label>
                <input
                    multiple
                    accept="image/*"
                    type="file"
                    onChange={onFilesSelected}
                    id="files"
                    style={{
                        visibility: 'hidden',
                    }}
                />
                <div className="files-list">
                    {files.map((file, i) => (
                        <div key={i} className="file-wrapper">
                            <DeleteIcon className="delete-icon" onClick={() => deleteFile(file)} />
                            <div className="file-name">{file.name}</div>
                            <img
                                className="image-file"
                                width={100}
                                height="auto"
                                src={URL.createObjectURL(file)}
                                alt="your image"
                            />
                        </div>
                    ))}
                </div>

                <Button type={ButtonType.primary} title="Save" onClick={handleUploadClick} />
            </div>
        </Modal>
    );
};
