import React from 'react';
import ModalLib from 'react-modal';
import classnames from 'classnames';

import { ReactComponent as CloseIcon } from '../../assets/close.svg';

import { ModalProps } from './model';

import './style.scss';

export const Modal = (props: ModalProps) => {
    return (
        <ModalLib
            appElement={document.body}
            isOpen={props.isOpen}
            className={classnames('modal-base', { ['right-side-modal']: props.isRightSide }, props.className)}
            overlayClassName={classnames('modal-overlay', props.overlayClassName)}
            onRequestClose={props.onClose}
        >
            {props.title ? <h3 className={classnames('title', props.titleClassName)}>{props.title}</h3> : null}
            {props.onClose && (
                <button className={classnames('close-icon-button', props.closeButtonClassName)} onClick={props.onClose}>
                    <CloseIcon />
                </button>
            )}
            {props.children}
        </ModalLib>
    );
};
