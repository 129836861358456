import { AppThunk } from '../../../index';
import { httpService } from '../../../../common/http';
import { backendRoutes } from '../../../../routes/backend';
import { closeNewSubscriptionModal, setSubscriptions } from '../../../slices/settings/subscriptions';
import { SubscriptionsCreateModel } from '../../../../shared/common/models/subscription';

export const getSubscriptions = (): AppThunk => {
    return async (dispatch) => {
        const response = await httpService.get<any[]>(backendRoutes.getSubscriptions);
        dispatch(setSubscriptions(response));
    };
};

export const deleteSubscription = (id: number): AppThunk => {
    return async (dispatch) => {
        await httpService.delete(backendRoutes.deleteSubscription(id));
        dispatch(getSubscriptions());
    };
};

export const updateSubscription = (id: number, data: SubscriptionsCreateModel): AppThunk => {
    return async (dispatch) => {
        const sendSubscription = { ...data };
        sendSubscription.duration = (data.duration as any).value;
        sendSubscription.currency = (data.currency as any).value;
        await httpService.patch<any>(backendRoutes.updateSubscription(id), sendSubscription);
        dispatch(getSubscriptions());
        dispatch(closeNewSubscriptionModal());
    };
};

export const addSubscription = (subscription: SubscriptionsCreateModel): AppThunk => {
    return async (dispatch) => {
        const sendSubscription = { ...subscription };
        sendSubscription.duration = (subscription.duration as any).value;
        sendSubscription.currency = (subscription.currency as any).value;
        await httpService.post(backendRoutes.addSubscription, sendSubscription);
        dispatch(getSubscriptions());
        dispatch(closeNewSubscriptionModal());
    };
};
