import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Currency } from '../../../../common/model/currency';
import { LoadableData } from '../../../../common/types';
import { OtherOptions, SupportOptions } from '../../../../shared/common/models/settings';

export interface OtherOptionsState {
    options: LoadableData<OtherOptions>;
    supportOptions: LoadableData<SupportOptions>;
    currencies: Currency[];
}

const initialState: OtherOptionsState = {
    options: {
        data: null,
        error: null,
        isLoading: false,
    },
    supportOptions: {
        data: null,
        error: null,
        isLoading: false,
    },
    currencies: [],
};

const internetResourcesSlice = createSlice({
    name: 'OtherOptions',
    initialState,
    reducers: {
        setOtherOptions: (state, action: PayloadAction<OtherOptions | null>) => {
            state.options.data = action.payload;
        },
        setOtherOptionsLoading: (state, action: PayloadAction<boolean>) => {
            state.options.isLoading = action.payload;
        },
        setOtherOptionsError: (state, action: PayloadAction<string | null>) => {
            state.options.error = action.payload;
        },
        setCurrencies: (state, action: PayloadAction<Currency[]>) => {
            state.currencies = action.payload;
        },
        setSupportOptions: (state, action: PayloadAction<SupportOptions | null>) => {
            state.supportOptions.data = action.payload;
        },
        setSupportOptionsLoading: (state, action: PayloadAction<boolean>) => {
            state.options.isLoading = action.payload;
        },
        setSupportOptionsError: (state, action: PayloadAction<string | null>) => {
            state.options.error = action.payload;
        },
    },
});

export const {
    setOtherOptions,
    setOtherOptionsLoading,
    setOtherOptionsError,
    setCurrencies,
    setSupportOptions,
    setSupportOptionsLoading,
    setSupportOptionsError,
} = internetResourcesSlice.actions;
export const otherOptionsReducer = internetResourcesSlice.reducer;
