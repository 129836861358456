import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { AppThunk } from '../../index';
import { setSubscription } from '../../slices/userSubscription';

export const getMySubscription = (): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        if (!state.auth.token || !state.auth.user) {
            return;
        }
        // TODO - NOT THIS URL
        const currentSubscription = await httpService.get(backendRoutes.getMySubscription);
        dispatch(setSubscription(currentSubscription));
    };
};

export const subscribeTariff = (subscribeId: number): AppThunk => {
    return async (dispatch, getState) => {
        const state = getState();
        if (!state.auth.token || !state.auth.user) {
            return;
        }
        const newUserSubscription = await httpService.post(backendRoutes.addSubcription, { id: subscribeId });
        dispatch(setSubscription(newUserSubscription));
    };
};
