import { ReactComponent as CloseSvg } from '../../assets/close.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const CloseIcon = (props: IconProps) => {
    return (
        <IconWrapper {...props}>
            <CloseSvg />
        </IconWrapper>
    );
};
