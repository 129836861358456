import React from 'react';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';

import { ArrowRightIcon } from '../icons/arrowRightIcon';
import { ArrowLeftIcon } from '../icons/arrowLeftIcon';

import { PaginationProps } from './model';

import './style.scss';

export const View = (props: PaginationProps) => {
    const { pagesCount, currentPage } = props;
    return (
        <ReactPaginate
            className={cn('pagination', props.className)}
            pageClassName="page-item"
            pageLinkClassName="page-item-link"
            activeClassName="page-item--active"
            previousClassName="previous"
            nextClassName="next"
            breakLabel={'...'}
            nextLabel={<ArrowRightIcon className="next" />}
            previousLabel={<ArrowLeftIcon className="prev" />}
            onPageChange={props.onPageChange}
            pageCount={pagesCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            forcePage={currentPage}
        />
    );
};
