import { KeywordCreatorModel } from '../../../../common/model/database';
import { EditableTreeNode } from '../../model';

import { KeywordFormData } from './model';

export const mapFormDataToRequest = (data: KeywordFormData): KeywordCreatorModel => {
    return {
        key: data.key,
        level: data.level,
        parentId: data.parentNode ? data.parentNode.value : undefined,
        synonyms: data.synonyms,
    };
};

export const mapTreeNodeToFormData = (data: EditableTreeNode): KeywordFormData => {
    return {
        id: typeof data.id === 'number' ? data.id : undefined,
        key: data.key,
        level: data.level,
        parentNode: data.parent ? { label: data.parent.key, value: data.parent.id } : null,
        synonyms: data.synonyms,
    };
};
