import { AppThunk } from '../../index';
import { httpService } from '../../../common/http';
import { ExternalSource } from '../../../common/model/externalSource';
import { backendRoutes } from '../../../routes/backend';
import { setExternalSources, setSelectedKeywordDocument } from '../../slices/catalog';
import { KeywordDocument } from '../../../common/model/database';

export const getExternalSources = (): AppThunk => {
    return async (dispatch) => {
        const response = await httpService.get<ExternalSource[]>(backendRoutes.externalSource);
        dispatch(setExternalSources(response));
    };
};

export const getKeywordDocument = (documentId: number): AppThunk => {
    return async (dispatch) => {
        const response = await httpService.get<KeywordDocument>(backendRoutes.getDocument(documentId));
        dispatch(setSelectedKeywordDocument(response));
    };
};
