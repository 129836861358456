import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';

import authReducer from './slices/auth';
import usersReducer from './slices/users';
import recognizeReducer from './slices/recognizeText';
import countryReducer from './slices/country';
import keywordReducer from './slices/keyword';
import { settingsReducer } from './slices/settings';
import documentsReducer from './slices/documents';
import { catalogReducer } from './slices/catalog';
import dictionariesReducer from './slices/dictionaries';
import reportsReducer from './slices/reports';
import userSubscription from './slices/userSubscription';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        recognize: recognizeReducer,
        users: usersReducer,
        country: countryReducer,
        keyword: keywordReducer,
        settings: settingsReducer,
        documents: documentsReducer,
        dictionaries: dictionariesReducer,
        catalog: catalogReducer,
        reports: reportsReducer,
        userSubscription: userSubscription,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type AppThunk<R = void> = ThunkAction<R | Promise<R>, RootState, unknown, AnyAction>;
