import { toast } from 'react-toastify';

import { httpService } from '../../../common/http';
import { RecognizeResult } from '../../../common/model/recognize';
import { AppThunk } from '../../index';
import { setRecognizeResult } from '../../slices/recognizeText';

export const recognize = (phrase: string, recognizeSessionId: number): AppThunk => {
    return async (dispatch) => {
        try {
            const result = await httpService.post<RecognizeResult>(
                'api/recognize',
                { phrase },
                {
                    'recognize-session-id': recognizeSessionId,
                },
            );
            dispatch(setRecognizeResult(result));
        } catch (e: any) {
            const msg = e?.message ?? 'Failed to recognize phrase';
            toast.error(msg);
        }
    };
};
