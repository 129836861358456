import { KeywordInfo } from '../../../common/model/database';

export function getParentKeys(
    keywords: KeywordInfo[] | null,
    child: KeywordInfo | null | undefined,
    result: string[] = [],
): string[] {
    if (!keywords || !child) return [];

    if (keywords.length === 0) return [child.key];

    for (const keyword of keywords) {
        const stringifyKeyword = JSON.stringify(keyword);
        if (stringifyKeyword.includes(`"id":${child.id},`)) {
            result.push(keyword.key);
            getParentKeys(keyword.children, child, result);
        }
    }

    return result;
}
