import { useDispatch, useSelector } from 'react-redux';

import { useAdminValidation } from '../../hooks/useAdminValidation';
import { AppDispatch } from '../../redux';
import { setDocumentsSearchPhrase } from '../../redux/slices/keyword';
import { documentsSearchPhraseSelector } from '../../redux/selectors/keyword';

import { View } from './view';

export const Database = () => {
    const dispatch: AppDispatch = useDispatch();
    const search = useSelector(documentsSearchPhraseSelector);
    const onSearchChanged = (value: string) => {
        dispatch(setDocumentsSearchPhrase(value));
    };

    useAdminValidation();

    return <View search={search} setSearch={onSearchChanged} articles={[]} />;
};
