import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { AppDispatch } from '../../redux';
import { UserTypes } from '../../common/enum/userTypes';
import { Routes } from '../../routes/routes';
import { authTokenSelector, authUserSelector } from '../../redux/selectors/auth';
import { logout } from '../../redux/slices/auth';

export const useAdminValidation = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector(authUserSelector);
    const token = useSelector(authTokenSelector);

    useEffect(() => {
        if (!token) {
            dispatch(logout());
            navigate('/', { replace: true });
        }
        if (user?.type !== UserTypes.admin) {
            navigate(Routes.workzone, { replace: true });
        }
    }, [dispatch, navigate, user?.type, token]);

    return user?.type == UserTypes.admin;
};
