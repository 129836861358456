import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { httpService } from './common/http';
import { Footer } from './components/footer';
import { Header } from './components/header';
import Content from './content';
import { AppDispatch } from './redux';
import { logout } from './redux/actions/auth';
import { getMySubscription } from './redux/actions/userSubscribe';
import { authTokenSelector, authUserSelector } from './redux/selectors/auth';
import { setUser } from './redux/slices/auth';
import { backendRoutes } from './routes/backend';

export const App = () => {
    const dispatch: AppDispatch = useDispatch();
    const authTokenUser = useSelector(authTokenSelector);
    const user = useSelector(authUserSelector);

    const getInfoUser = () => {
        if (authTokenUser) {
            httpService
                .get(backendRoutes.getUserInfo)
                .then((user) => {
                    if (user?.isBlocked) {
                        dispatch(logout());
                        return;
                    }
                    dispatch(setUser(user));
                    return user;
                })
                .then(() => dispatch(getMySubscription()));
        }
    };

    useEffect(getInfoUser, [user?.isBlocked, authTokenUser, dispatch]);

    return (
        <div className="App">
            <Header>
                <Content />
            </Header>
            <Footer />
        </div>
    );
};
