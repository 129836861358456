export enum StatisticType {
  fullBilled = "full_billed",
  fullBilledDay = "full_billed_day",
  fullBilledWeek = "full_billed_week",
  fullBilledMonth = "full_billed_month",
  userCount = "user_count",
  userActivityDay = "user_activity_day",
  userActivityWeek = "user_activity_week",
  userActivityMonth = "user_activity_month",
}

export interface StatisticViewModel {
  val: string | number;
  type: StatisticType;
}
