import './style.scss';

import { Modal } from '../../components/modal';
import { PaymentCard } from '../../components/paymentCard';

import { ViewProps } from './model';
import { Form } from './components/form';

export const View = ({ data, isLoading, onClose }: ViewProps) => {
    if (isLoading || !data) return null;

    return (
        <Modal isRightSide isOpen title="User" onClose={onClose} className="user-modal">
            <div className="user-content">
                <Form data={data} onClose={onClose} />
                <PaymentCard isAdminView={true} userId={data.id} />
                <div style={{ width: 100 }} />
            </div>
        </Modal>
    );
};
