import { toast } from 'react-toastify';
import { stringify } from 'qs';

import { AppThunk } from '../../index';
import { KeywordCreatorModel, KeywordInfo, KeywordUpdaterModel } from '../../../common/model/database';
import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import {
    setKeywords,
    setKeywordsError,
    setKeywordsLoading,
    setSelectedNodeDocuments,
    setSelectedNodeDocumentsCount,
} from '../../slices/keyword';

export const getKeywords = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setKeywordsLoading(true));
            const data = await httpService.get<KeywordInfo[]>(backendRoutes.getKeywords);
            dispatch(setKeywords(data));
        } catch (e: any) {
            const msg = e?.message ?? 'Failed to get keywords tree';
            dispatch(setKeywordsError(msg));
            toast.error(msg);
        } finally {
            dispatch(setKeywordsLoading(false));
        }
    };
};

export const createKeyword = (data: KeywordCreatorModel): AppThunk<KeywordInfo> => {
    return async () => {
        return await httpService.post(backendRoutes.createKeyword, data);
    };
};

export const updateKeyword = (data: KeywordUpdaterModel): AppThunk<KeywordInfo> => {
    return async () => {
        return await httpService.put(backendRoutes.updateKeyword, data);
    };
};

export const deleteKeyword = (id: number): AppThunk<KeywordInfo> => {
    return async () => {
        return await httpService.delete(`${backendRoutes.deleteKeyword}/${id}`);
    };
};

export const getKeywordDocuments = (page = 1, size = 6): AppThunk => {
    return async (dispatch, getState) => {
        const selectedNode = getState().keyword.selectedNode;
        const baseUrl = backendRoutes.getDocumentsForCategory.replace(
            ':categoryId',
            (selectedNode?.id || '').toString(),
        );
        const url = `${baseUrl}?page=${page}&size=${size}`;
        const response = await httpService.get(url);
        dispatch(setSelectedNodeDocuments(response.documents));
        dispatch(setSelectedNodeDocumentsCount(response.count));
    };
};

export const searchDocuments = (search: string, page = 1, size = 6): AppThunk => {
    return async (dispatch) => {
        const query = stringify({
            search,
            filterByCountry: true,
            page,
            size,
        });
        const response = await httpService.get(`${backendRoutes.searchDocuments}?${query}`);
        dispatch(setSelectedNodeDocuments(response.documents));
        dispatch(setSelectedNodeDocumentsCount(response.count));
    };
};
