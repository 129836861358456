import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAdminValidation } from '../../hooks/useAdminValidation';
import { AppDispatch } from '../../redux';
import { getUsers } from '../../redux/actions/users';
import { usersSelector } from '../../redux/selectors/auth';
import { admins } from '../../redux/selectors/users';

import { View } from './view';

export const Users = () => {
    const dispatch: AppDispatch = useDispatch();

    const users = useSelector(usersSelector);
    const admin = useSelector(admins);

    useAdminValidation();

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch]);

    return View(users, admin);
};
