import { format } from 'date-fns';

import { SubscriptionUserViewModel } from '../../../../shared/common/models/subscription';

import './style.scss';

export const HistoryCard = ({ item }: { item: SubscriptionUserViewModel }) => {
    return (
        <div className="subscription-history-card-in-modal">
            <span>Name: {item.subscribe.name}</span>
            <span>
                Price: {item.purchasePrice ?? 0} {item.subscribe.currency}
            </span>
            <span>Purchased at: {format(new Date(item.purchasedAt), 'dd MMMM yyyy hh:mm')}</span>
            {item.isActual ? <span className="is-active-label">CURRENT PLAN</span> : null}
        </div>
    );
};
