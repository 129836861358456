import React from 'react';
import { FormProvider } from 'react-hook-form';

import { FontTitle } from '../../../../../components/fontTitle';
import { FormField } from '../../../../../components/formField';
import { OtherOptionName } from '../../../../../shared/common/models/settings';
import { Button } from '../../../../../components/button';
import { ButtonType } from '../../../../../common/enum/buttonType';
import { ButtonsWrapper } from '../../../../../components/buttonsWrapper';
import { FormItems } from '../formItems';

import { OtherOptionsFormData, ViewProps } from './model';

const TITLE_MARGIN_BOTTOM = 16;

export const View = ({ form, onSubmit }: ViewProps) => (
    <FormProvider {...form}>
        <form className="other-options-form" onSubmit={onSubmit}>
            <section className="section">
                <FontTitle marginBottom={TITLE_MARGIN_BOTTOM} level={3}>
                    Support contacts
                </FontTitle>
                <FormField<OtherOptionsFormData>
                    className="support-field"
                    type="text"
                    name={OtherOptionName.SupportEmail}
                    title="Support email"
                />
                <FormField<OtherOptionsFormData>
                    className="support-field"
                    type="text"
                    name={OtherOptionName.SupportPhone}
                    title="Support phone"
                />
            </section>
            {/*<section className="section section-working-with-articles">
                    <FontTitle marginBottom={TITLE_MARGIN_BOTTOM} level={3}>
                        Working with articles
                    </FontTitle>
                    <label className="article-input-wrapper">
                        <FormField<OtherOptionsFormData>
                            className="article-input"
                            type="text"
                            name={OtherOptionName.LastFoundCount}
                        />
                        of displayed articles in the "Last found" section
                    </label>
                    <label className="article-input-wrapper">
                        <FormField<OtherOptionsFormData>
                            className="article-input"
                            type="text"
                            name={OtherOptionName.MostPopularCount}
                        />
                        of displayed articles in the "Most Popular" section
                    </label>
                    <label className="article-input-wrapper">
                        <FormField<OtherOptionsFormData>
                            className="article-input"
                            type="text"
                            name={OtherOptionName.KeepDays}
                        />
                        days to keep articles in history
                    </label>
                    <label className="article-input-wrapper">
                        <FormField<OtherOptionsFormData>
                            className="article-input"
                            type="text"
                            name={OtherOptionName.LastRecognizedWordsCount}
                        />
                        the last recognized words, among which keywords are searched for
                    </label>
                </section>*/}
            <FormItems
                name="deleteKeywords"
                title="Delete keywords"
                addButtonTitle="Add keyword"
                modalTitle={'delete keyword'}
            />
            <FormItems
                name="noGoKeywords"
                title="No Go keywords"
                addButtonTitle="Add keyword"
                modalTitle={'No Go keyword'}
            />
            <FormItems name="regions" title="Region" addButtonTitle="Add region" modalTitle={'region'} />
            <ButtonsWrapper columnGap={24}>
                <Button
                    disabled={!form.formState.isDirty || form.formState.isSubmitting}
                    title="Save changes"
                    type={ButtonType.primary}
                    buttonType="submit"
                />
                <Button
                    title="Cancel"
                    type={ButtonType.secondary}
                    disabled={form.formState.isSubmitting}
                    onClick={() => form.reset()}
                />
            </ButtonsWrapper>
        </form>
    </FormProvider>
);
