import { useState, forwardRef } from 'react';

import { InputProps } from './model';
import { InputView } from './view';

export const Input = forwardRef<HTMLInputElement, InputProps>((props, forwardedRef) => {
    const [focused, setFocused] = useState(false);
    const [hideValue, setHideValue] = useState(props.type === 'password');

    return InputView({
        ...props,
        forwardedRef,
        focused,
        setFocused,
        hideValue,
        setHideValue,
    });
});
