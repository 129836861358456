import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { signUp } from '../../redux/actions/signUp';
import { AppDispatch } from '../../redux';
import { useCountries } from '../../hooks/useCountries';

import { View } from './view';
import { signUpSchema } from './validation';
import { SignUpFormData } from './model';

export const SignUp = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const countries = useCountries();
    const methods = useForm<SignUpFormData>({
        mode: 'onSubmit',
        resolver: yupResolver(signUpSchema),
    });
    const [isSuccess, setIsSuccess] = useState(false);

    const countriesOptions = useMemo(() => {
        return countries.map(({ id, name }) => ({ label: name, value: id }));
    }, [countries]);

    const onClose = () => {
        navigate('/', { replace: true });
    };

    const onSubmit = async (data: SignUpFormData) => {
        try {
            await dispatch(
                signUp({
                    login: data.login,
                    email: data.email,
                    password: data.password,
                    countryId: data.country.value,
                }),
            );

            setIsSuccess(true);
        } catch (e) {
            let msg = 'Failed to register new profile';
            if (e instanceof AxiosError && e.response?.data?.errors) {
                const { errors } = e.response.data;
                msg = Array.isArray(errors) ? errors.join(' ') : errors;
            }

            toast.error(msg);
            setIsSuccess(false);
        }
    };

    return View({
        onClose,
        onSubmit,
        methods,
        isSuccess,
        countriesOptions,
    });
};
