import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../redux';
import { getSupportOptions } from '../../redux/actions/settings/otherOptions';
import { Title } from '../title';

import './style.scss';

export const Footer = () => {
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector((state: RootState) => state.auth.user);

    useEffect(() => {
        dispatch(getSupportOptions());
    }, [dispatch]);

    if (user) return null;
    return (
        <div className="footer">
            <div className="left-footer-block">
                <div className="title-block">
                    <Title type="footer" />
                    <span className="year-info">© {new Date().getFullYear()}</span>
                </div>
                {<div style={{ height: 60, width: 180, marginLeft: 93, background: 'inherit' }}></div>}
            </div>
            {/*<div className="right-footer-block">
                <div className="info-block">
                    <InfoItem type="location" text="12720 Hillcrest Road Suite 980, Dallas, TX 75230" />
                    <div />
                </div>
                <div className="info-block">
                    <InfoItem type="phone" text={supportOptions.data?.support_phone ?? ''} />
                    <InfoItem type="mail" text={supportOptions.data?.support_email ?? ''} />
                </div>
    </div>*/}
            <div className="right-footer-back" />
        </div>
    );
};
