import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

import { ColumnProps } from '../../../components/dataTable/model';
import { ExternalSource } from '../../../common/model/externalSource';
import { DeleteIcon } from '../../../components/icons/deleteIcon';
import { AppDispatch } from '../../../redux';
import {
    externalSourcesSelector,
    isSourceModalOpenSelector,
} from '../../../redux/selectors/settings/internetResources';
import { openNewResourceModal, setEditSourceId } from '../../../redux/slices/settings/internetResources';
import { deleteSource, getExternalSources } from '../../../redux/actions/settings/internetResources';
import { EditIcon } from '../../../components/icons/editIcon';

import { View } from './view';

export const InternetResources = () => {
    const dispatch: AppDispatch = useDispatch();
    const resources = useSelector(externalSourcesSelector);

    const columns = useMemo<ColumnProps<ExternalSource>[]>(() => {
        return [
            {
                dataField: 'id',
                columnText: '',
                width: 40,
                align: 'center',
            },
            {
                dataField: 'title',
                columnText: 'Title',
                width: 300,
                align: 'left',
            },
            {
                dataField: 'url',
                columnText: 'Url template',
                align: 'left',
            },
            {
                dataField: 'delete',
                columnText: 'Delete',
                align: 'center',
                headerAlign: 'center',
                width: 140,
                renderer: (data) => (
                    <div className="icons">
                        <EditIcon onClick={() => dispatch(setEditSourceId(data))} />
                        <DeleteIcon onClick={() => dispatch(deleteSource(data.id))} />
                    </div>
                ),
            },
        ];
    }, [dispatch]);

    const isSourceModalOpen = useSelector(isSourceModalOpenSelector);

    const openNewResourceModalCallback = useCallback(() => {
        dispatch(openNewResourceModal());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getExternalSources());
    }, [dispatch]);

    return View({
        isSourceModalOpen,
        data: resources,
        columns,
        keyField: 'id',
        openNewResourceModal: openNewResourceModalCallback,
    });
};
