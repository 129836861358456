import React from 'react';

import { ReactComponent as AdvantageIcon } from '../../assets/logo80.svg';

import { AdvantageCard } from './content';

import './style.scss';

const mockData = [
    {
        Icon: AdvantageIcon,
        title: 'Up to date',
        content: 'Latest peer-reviewed information',
    },
    {
        Icon: AdvantageIcon,
        title: 'Vendor Neutral',
        content: 'Can be used with any voice dictation system',
    },
    {
        Icon: AdvantageIcon,
        title: 'Curated information',
        content: 'When you need it',
    },
    {
        Icon: AdvantageIcon,
        title: 'Time-Saving',
        content: 'Saves you time and frees you from distraction',
    },
];

export const Advantage = () => {
    return (
        <div className="container-advantage">
            <div className="container-cards">
                {mockData.map((data) => (
                    <AdvantageCard key={data.title} {...data} />
                ))}
            </div>
        </div>
    );
};
