import React, { useRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as Eye } from '../../assets/eye.svg';
import { ReactComponent as Search } from '../../assets/search.svg';

import { InputViewProps } from './model';
import './style.scss';

export const InputView = (props: InputViewProps) => {
    const ref = useRef<any>();

    return (
        <div
            ref={props.forwardedRef}
            className={classNames('base-input-container', props.className, {
                'with-title': !!props.title,
                'with-error': !!props.error,
            })}
        >
            {props.title ? (
                <span
                    onClick={() => {
                        if (!props.focused) {
                            ref.current.focus();
                        }
                    }}
                    className={classNames('input-title', {
                        focused: props.focused || !!props.value,
                        error: !!props.error,
                    })}
                >
                    {props.title}
                    {props.error ? ` (${props.error})` : null}
                </span>
            ) : null}
            {props.children && (
                <div
                    onClick={() => {
                        if (!props.focused) {
                            ref.current.focus();
                        }
                    }}
                    className={classNames('input-children', {
                        focused: props.focused || !!props.value,
                    })}
                >
                    {props.children}
                </div>
            )}

            <input
                type={props.hideValue ? 'password' : 'text'}
                ref={ref}
                value={props.value ?? ''}
                onChange={props.onChange}
                onBlur={() => {
                    props.onBlur?.();
                    props.setFocused(false);
                }}
                onFocus={() => {
                    props.setFocused(true);
                }}
                placeholder={props.placeholder}
                className={classNames('base-input', props.inputClassName)}
            />

            {props.type === 'password' ? (
                <Eye className="eye-svg-button" onClick={() => props.setHideValue(!props.hideValue)} />
            ) : null}
            {props.icon && <span className="icon">{props.icon}</span>}
            {props.type === 'search' ? <Search className="search-svg" /> : null}
        </div>
    );
};
