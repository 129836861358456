import { convertToHTML, IConvertToHTMLConfig } from 'draft-convert';
import { convertFromRaw, RawDraftContentState } from 'draft-js';
import { useMemo } from 'react';

import './style.scss';

const convertHtmlConfig: IConvertToHTMLConfig = {
    entityToHTML: (entity, originalText) => {
        if (entity.type == 'LINK') {
            return (
                <a href={entity.data.url} className="doc-link" target="_blank">
                    <span className="link-icon">
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.001 16H2.00098C1.47054 16 0.961836 15.7893 0.586763 15.4142C0.21169 15.0391 0.000976563 14.5304 0.000976562 14V3C0.000976562 2.46957 0.21169 1.96086 0.586763 1.58579C0.961836 1.21071 1.47054 1 2.00098 1H6.00098V3H2.00098V14H13.001V10H15.001V14C15.001 14.5304 14.7903 15.0391 14.4152 15.4142C14.0401 15.7893 13.5314 16 13.001 16ZM7.70098 9.707L6.29098 8.293L12.584 2H9.00098V0H16.001V7H14.001V3.415L7.70098 9.707Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>

                    <span className="link-text">{originalText}</span>
                </a>
            );
        }

        if (entity.type === 'IMAGE') {
            return `<img alt="image" src="${entity.data.src}" />`;
        }

        return <span>{originalText}</span>;
    },
};

export function useConvertContentToHTML(content: RawDraftContentState | undefined | null) {
    return useMemo(() => {
        if (!content) return '';
        return convertToHTML(convertHtmlConfig)(convertFromRaw(content));
    }, [content]);
}
