import { RefObject, useEffect } from 'react';

export const useClickOutside = <T extends HTMLElement>(
    ref: RefObject<T>,
    callback?: (el: HTMLElement | null) => void,
) => {
    useEffect(() => {
        const onClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                callback?.(event.target as HTMLElement);
            }
        };

        document.addEventListener('click', onClick);
        return () => {
            document.removeEventListener('click', onClick);
        };
    }, [ref, callback]);
};
