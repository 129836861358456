import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { AppThunk } from '../../index';

import { SignUpActionProps } from './model';

export const signUp = (data: SignUpActionProps): AppThunk => {
    return async () => {
        await httpService.post(backendRoutes.signUp, data);
    };
};

export const verifyEmail = (code: string, type: string) => async () => {
    await httpService.post(backendRoutes.confirmRegistration, {
        code,
        type,
    });
};
