import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { AppDispatch } from '../../redux';
import { countriesSelector } from '../../redux/selectors/country';
import { getCountries } from '../../redux/actions/country';
import { Country } from '../../common/model/country';

export const useCountries = (): Country[] => {
    const dispatch: AppDispatch = useDispatch();

    const { data, error } = useSelector(countriesSelector);

    useEffect(() => {
        if (!data && !error) {
            dispatch(getCountries());
        }
    }, [data, dispatch, error]);

    return data ?? [];
};
