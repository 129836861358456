import { ReactComponent as ExternalLinkSVG } from '../../assets/external-link.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const ExternalLinkIcon = (props: IconProps) => {
    return (
        <IconWrapper {...props}>
            <ExternalLinkSVG />
        </IconWrapper>
    );
};
