import React from 'react';
import { useNavigate } from 'react-router';

import { ButtonType } from '../../common/enum/buttonType';
import { Routes } from '../../routes/routes';
import { Button } from '../button';

import { DescriptionProps } from './model';
import './style.scss';

export const Description = (props: DescriptionProps) => {
    const { title, image, showButton, videoUrl, text } = props;

    const navigate = useNavigate();
    const toLoginPageClick = () => {
        navigate(Routes.login);
    };

    return (
        <div className="container-desc">
            <div className="desc">
                <h1 className="title-desc">{title}</h1>
                <p className="text">{text}</p>
                {/* <ul>
                    {lists.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul> */}
                {showButton && <Button type={ButtonType.primary} title="try for free" onClick={toLoginPageClick} />}
            </div>
            {image ? (
                <div>
                    <img alt="test-pic" src={image} />
                </div>
            ) : (
                <div className="video-box">
                    <iframe
                        width="100%"
                        height="100%"
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="TITLE"
                    />
                </div>
            )}
        </div>
    );
};
