import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SubscriptionsState {
    subscriptions: any[];
    isSubscriptionModalOpen: boolean;
    editableSubscription: any | null;
}

const initialState: SubscriptionsState = {
    subscriptions: [],
    isSubscriptionModalOpen: false,
    editableSubscription: null,
};

const subscriptionsSlice = createSlice({
    name: '@radiology/subscriptions',
    initialState,
    reducers: {
        setSubscriptions: (state, action: PayloadAction<any[]>) => {
            state.subscriptions = action.payload;
        },
        openNewSubscriptionModal: (state) => {
            state.isSubscriptionModalOpen = true;
        },
        closeNewSubscriptionModal: (state) => {
            state.isSubscriptionModalOpen = false;
            state.editableSubscription = null;
        },
        setEditSubscriptionId: (state, action: PayloadAction<any>) => {
            state.editableSubscription = action.payload;
        },
    },
});

export const { setSubscriptions, openNewSubscriptionModal, closeNewSubscriptionModal, setEditSubscriptionId } =
    subscriptionsSlice.actions;
export const subscriptionsReducer = subscriptionsSlice.reducer;
