import { AppThunk } from '../..';
import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { setResultRecognize, setResultRecognizeCount, setStatistic } from '../../slices/reports';

export const getStatistic = (): AppThunk => {
    return async (dispatch) => {
        try {
            const result = await httpService.get(backendRoutes.getStatistic);
            dispatch(setStatistic(result));
        } catch (e) {
            console.error(e);
        }
    };
};

export const getResultRecognize = (page = 1, size = 6): AppThunk => {
    return async (dispatch) => {
        try {
            const result = await httpService.get(`${backendRoutes.getListResultRecognize}?page=${page}&size=${size}`);
            dispatch(setResultRecognize(result.listResult));
            dispatch(setResultRecognizeCount(result.count));
        } catch (e) {
            console.error(e);
        }
    };
};
