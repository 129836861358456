import {
    AboutIcon,
    CatalogIcon,
    DatabaseIcon,
    ProfileIcon,
    ReportsIcon,
    SettingsIcon,
    UsersIcon,
} from '../../../assets/menu';
import { Routes } from '../../../routes/routes';

import { NavLinkData } from './model';

export const getNavLinkData = (isAdmin: boolean): NavLinkData[] => {
    const data: NavLinkData[] = [
        {
            name: 'Catalog',
            to: Routes.workzone,
            Icon: CatalogIcon,
        },
        {
            name: 'Profile',
            to: Routes.profile,
            Icon: ProfileIcon,
        },
        {
            name: 'About',
            to: Routes.aboutUs,
            Icon: AboutIcon,
        },
    ];

    if (!isAdmin) return data;

    return data.concat([
        {
            name: 'Users',
            to: Routes.users,
            Icon: UsersIcon,
        },
        {
            name: 'Database',
            to: Routes.database,
            Icon: DatabaseIcon,
        },
        {
            name: 'Reports',
            to: Routes.reports,
            Icon: ReportsIcon,
        },
        {
            name: 'Settings',
            to: Routes.settings,
            Icon: SettingsIcon,
        },
    ]);
};
