import { FormProvider } from 'react-hook-form';

import { Modal } from '../../../../components/modal';
import { FontTitle } from '../../../../components/fontTitle';
import { FormField } from '../../../../components/formField';
import { CreateExternalSource } from '../../../../common/model/externalSource';
import { ButtonsWrapper } from '../../../../components/buttonsWrapper';
import { Button } from '../../../../components/button';
import { ButtonType } from '../../../../common/enum/buttonType';
import { CURRENCIES } from '../../../../redux/actions/settings/otherOptions/mocks';

import { ViewProps } from './model';
import './style.scss';
import { durationOptions } from './const';

const CURRENCY_OPTIONS = CURRENCIES.map((C) => ({ label: C.currencyCode, value: C.currencyCode }));

export const View = (props: ViewProps) => {
    const { methods, onSubmit, onClose } = props;
    return (
        <Modal className="add-subscription-modal" isOpen={true} onClose={onClose}>
            <FontTitle marginBottom={32} level={2}>
                New Subscription
            </FontTitle>
            <FormProvider {...methods}>
                <form className="subscription-form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="subscription-fields">
                        <FormField<CreateExternalSource> title="Title" type="text" name="name" />
                        <FormField<CreateExternalSource>
                            title="Duration"
                            type="select"
                            options={durationOptions}
                            name="duration"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                gap: 16,
                            }}
                        >
                            <FormField<CreateExternalSource>
                                title="Price"
                                type="text"
                                name="price"
                                className="row-element"
                            />
                            <FormField<CreateExternalSource>
                                title="Currency"
                                className="row-element"
                                options={CURRENCY_OPTIONS}
                                type="select"
                                name="currency"
                            />
                        </div>
                        <div className="padding-last">
                            <FormField<CreateExternalSource> title="Trial Period" type="checkbox" name="isTrial" />
                        </div>
                        <ButtonsWrapper columnGap={24}>
                            <Button title="Set up" type={ButtonType.primary} buttonType="submit" />
                            <Button onClick={onClose} title="Cancel" type={ButtonType.secondary} />
                        </ButtonsWrapper>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};
