import cn from 'classnames';

import { Tree } from '../../../components/tree';
import { FontTitle } from '../../../components/fontTitle';

import { ViewProps } from './model';

import './style.scss';

export const View = ({ items, className }: ViewProps) => {
    return (
        <div className={cn('catalog-tree', className)}>
            <FontTitle className="catalog-tree-title" level={2}>
                Catalog
            </FontTitle>
            {items && <Tree items={items} />}
        </div>
    );
};
