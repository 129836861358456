import { ReactComponent as MinusSvg } from '../../assets/minus-square.svg';
import { ReactComponent as PlushSvg } from '../../assets/plus-square.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const CollapseIcon = ({ open, ...props }: { open: boolean } & IconProps) => {
    return open ? (
        <IconWrapper {...props}>
            <MinusSvg />
        </IconWrapper>
    ) : (
        <IconWrapper {...props}>
            <PlushSvg />
        </IconWrapper>
    );
};
