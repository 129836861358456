import { useEffect, useState } from 'react';

import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { SubscriptionUserViewModel } from '../../../shared/common/models/subscription';

import { HistoryCard } from './historyCard';

export const PaymentHistoryModal = () => {
    const [subscriptionHistory, setSubscriptionHIstory] = useState<SubscriptionUserViewModel[]>([]);

    useEffect(() => {
        httpService.get(backendRoutes.getMuSubscriptionHistory).then((res) => setSubscriptionHIstory(res));
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            {subscriptionHistory.map((s) => {
                return <HistoryCard item={s} />;
            })}
        </div>
    );
};
