import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { confirmPasswordRecovery, getRecoveryUrl } from '../../redux/actions/auth';
import { AppDispatch } from '../../redux';

import { View } from './view';
import { RecoveryPassFormData } from './model';

export const PasswordRecovery = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const form = useForm<RecoveryPassFormData>();
    const [isSuccess, setIsSuccess] = useState(false);

    const [params] = useSearchParams();
    const hasCode = params.has('code') && params.has('type');

    const onLoginSubmit = async ({ login }: RecoveryPassFormData) => {
        try {
            await dispatch(getRecoveryUrl(login));
            setIsSuccess(true);
        } catch (e: any) {
            setIsSuccess(false);
            if (e.response.status === 404) {
                form.setError('login', { message: "User with such email or login doesn't exist" });
            }
        }
    };
    const onPassSubmit = async ({ password, passwordConfirmation }: RecoveryPassFormData) => {
        if (password !== passwordConfirmation) {
            form.setError('passwordConfirmation', {
                message: "Password confirmation doesn't match Password",
            });
            return;
        }

        try {
            await dispatch(confirmPasswordRecovery(params.get('code') ?? '', params.get('type') ?? '', password));
            navigate('/login');
            // todo: success notification
        } catch (e: any) {
            if (e?.response?.status === 400) {
                // todo: bad notification
            }
            navigate('/password-recovery');
        }
    };
    const onClose = () => navigate('/login');

    return View({
        form,
        onSubmit: hasCode ? onPassSubmit : onLoginSubmit,
        onClose,
        isSuccess,
        hasCode,
    });
};
