import { SchemaOf } from 'yup';
import * as yup from 'yup';

import { ExternalSourceParameter } from '../../../../common/model/externalSource';

export const createExternalSourceSchema = yup.object().shape({
    title: yup.string().required(),
    url: yup.string().url().required(),
    parameters: yup
        .array()
        .of<SchemaOf<ExternalSourceParameter>>(
            yup.object().shape({
                key: yup.string().required('Key is required'),
                value: yup.string().required('Value is required'),
            }),
        )
        .required()
        .min(1, 'One or more parameters are required'),
});
