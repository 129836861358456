import { AxiosError } from 'axios';

import { httpService } from '../../../common/http';
import { User } from '../../../common/model/user';
import { backendRoutes } from '../../../routes/backend';
import { AppThunk } from '../../index';
import { setUser, setUserError, setUserLoading, setUsers } from '../../slices/users';

export const getUser = (id: number): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setUserError(null));
            dispatch(setUserLoading(true));

            const user = await httpService.get(`${backendRoutes.getUserInfo}/${id}`);
            dispatch(setUser(user));
        } catch (e: any) {
            dispatch(setUser(null));

            let errorMessage = `Failed to get user with id ${id}`;
            if (e instanceof AxiosError && e.response?.data?.message) {
                errorMessage = e.response.data.message;
            }

            dispatch(setUserError(errorMessage));
        } finally {
            dispatch(setUserLoading(false));
        }
    };
};

export const updateUser = (user: User): AppThunk => {
    return async (dispatch) => {
        await Promise.all([
            httpService.patch(backendRoutes.changeRole, { userId: user.id, newType: user.type }),
            httpService.patch(backendRoutes.changeBlocking, { userId: user.id, isBlocked: user.isBlocked }),
        ]);

        dispatch(setUser(user));
    };
};

export const getUsers = (): AppThunk => {
    return async (dispatch) => {
        try {
            const users = await httpService.get(`${backendRoutes.getUsers}`);
            dispatch(setUsers(users));
        } catch (e: any) {
            console.error(e);
        }
    };
};

export const changePassword = (password: string, oldPassword: string): AppThunk => {
    return async () => {
        await httpService.patch(`${backendRoutes.changePassword}`, {
            password,
            oldPassword,
        });
    };
};

export const changeCountry = (countryId: number): AppThunk => {
    return async () => {
        await httpService.patch(`${backendRoutes.changeCountry}`, {
            countryId,
        });
    };
};
