import React from 'react';
import classNames from 'classnames';

import { Props } from './model';
import './style.scss';

export const Title = (props: Props) => {
    return (
        <span onClick={props.onClick} className={classNames('title', `${props.type}-style`)}>
            2ND OPINION
        </span>
    );
};
