import { Pagination } from '../../../components/pagination';

import { ViewProps } from './model';
import { TableResultRecognize } from './tableRecognizeResult';

export const View = (props: ViewProps) => {
    const { data, page, setPage, countData, count } = props;

    return (
        <div>
            <TableResultRecognize data={data} />
            <Pagination
                className="catalog-articles-pagination"
                pagesCount={Math.ceil(countData / count)}
                currentPage={page}
                onPageChange={(i: any) => setPage(i.selected)}
            />
        </div>
    );
};
