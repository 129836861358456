import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setChosenItem } from '../../redux/slices/keyword';
import { RootState } from '../../redux';

import { View } from './view';
import { EditableTreeNode, TreeProps } from './model';
import { TreeNode } from './treeNode/model';

export const Tree = ({
    items,
    onSelect,
    creatingMode = 'inline',
    unselectable = false,
    hiddenNodeIds = [],
}: TreeProps) => {
    const [editableNode, setEditableNode] = useState<EditableTreeNode | null>(null);
    const [deletedNode, setDeletedNode] = useState<TreeNode | null>(null);

    const selectedNode = useSelector((state: RootState) => state.keyword.selectedNode);

    const dispatch = useDispatch();

    const onCreateEditNode = useCallback((node: EditableTreeNode, parentNode?: TreeNode) => {
        setEditableNode({ ...node, parent: parentNode });
    }, []);
    const onDeleteNode = useCallback((node: TreeNode) => {
        setDeletedNode(node);
    }, []);
    const onCloseModal = useCallback(() => {
        setEditableNode(null);
    }, []);
    const onCloseDeletingModal = useCallback(() => {
        onCloseModal();
        setDeletedNode(null);
    }, [onCloseModal]);

    const onChooseNode = useCallback(
        (node: TreeNode | null) => {
            dispatch(setChosenItem(node));
        },
        [dispatch],
    );

    const onSelectNode = useCallback(
        (node: TreeNode | null) => {
            onChooseNode(node);
            onSelect?.(node);
        },
        [onChooseNode, onSelect],
    );

    useEffect(() => {
        return () => {
            onChooseNode(null);
        };
    }, [onChooseNode]);

    return (
        <View
            items={items}
            creatingMode={creatingMode}
            unselectable={unselectable}
            hiddenNodeIds={hiddenNodeIds}
            selectedNode={selectedNode}
            editableNode={editableNode}
            deletedNode={deletedNode}
            onSelectNode={onSelectNode}
            onCreateEditNode={onCreateEditNode}
            onDeleteNode={onDeleteNode}
            onCloseModal={onCloseModal}
            onCloseDeletingModal={onCloseDeletingModal}
        />
    );
};
