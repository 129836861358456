import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useNavigate } from 'react-router';

import { FavouritesIcon } from '../../icons/favoritesIcon';
import { Routes } from '../../../routes/routes';
import { useConvertContentToHTML } from '../../../hooks/useConvertContentToHTML/useConvertContentToHTML';

import { ItemCatalogProps } from './model';

import './style.scss';

export const ItemCatalog = (props: ItemCatalogProps) => {
    const navigate = useNavigate();
    const { name, content, createdAt, id, isExpanded, onLoadMoreClick } = props;

    const [isFavourite, setIsFavourite] = useState(false);

    const buttonHandler = () => {
        setIsFavourite((current) => !current);
    };

    const html = useConvertContentToHTML(content);

    return (
        <div className="item-catalog-container">
            <div className="item-catalog-content">
                <h1 className="item-catalog-title" onClick={() => navigate(Routes.documentDetails(id))}>
                    {name}
                </h1>
                <span className="date-catalog">{format(parseISO(createdAt), 'dd.MM.yyyy')}</span>
                {isExpanded ? (
                    <p className="desc-catalog" dangerouslySetInnerHTML={{ __html: html }}></p>
                ) : (
                    <p className="desc-catalog">
                        {content?.blocks
                            .map((x) => x.text)
                            .join(' ')
                            .slice(0, 256) + '...'}
                        <span onClick={onLoadMoreClick} className="load-more-label">
                            load more
                        </span>
                    </p>
                )}
            </div>
            <div className="svg-container">
                <FavouritesIcon className="favourite-svg" active={isFavourite} onClick={buttonHandler} />
            </div>
        </div>
    );
};
