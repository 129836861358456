import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RecognizeResult } from '../../../common/model/recognize';

export interface RecognizeTextState {
    history: string[];
    text: string;
    recognizeSessionId: string;
    timing: {
        current: number;
        total: number;
    };
    recognizeResult: RecognizeResult;
}

const initialState: RecognizeTextState = {
    history: [],
    text: '',
    recognizeSessionId: '',
    timing: {
        current: 0,
        total: 0,
    },
    recognizeResult: {
        recognizedKeywords: [],
        lastKeyword: undefined,
    },
};

export const recognizeSlice = createSlice({
    name: 'recognizeSlice',
    initialState,
    reducers: {
        addText: (state, { payload }: PayloadAction<string>) => {
            state.text = payload;
        },
        addHistory: (state, { payload }: PayloadAction<string>) => {
            state.history = [...state.history, payload];
        },
        addTiming: (state, { payload }: PayloadAction<number>) => {
            state.timing.total = state.timing.total + payload - state.timing.current;
            state.timing.current = payload;
        },
        reset: (state) => {
            return {
                ...initialState,
                timing: {
                    current: 0,
                    total: state.timing.total,
                },
            };
        },
        addRecognizeSessionId: (state, { payload }: PayloadAction<string>) => {
            state.recognizeSessionId = payload;
        },
        setRecognizeResult: (state, { payload }: PayloadAction<RecognizeResult>) => {
            state.recognizeResult = payload;
        },
    },
});

export const { addText, addHistory, addTiming, reset, addRecognizeSessionId, setRecognizeResult } =
    recognizeSlice.actions;

export default recognizeSlice.reducer;
