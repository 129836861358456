import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { createKeyword, getKeywords } from '../../../redux/actions/keyword';
import { AppDispatch } from '../../../redux';

import { View } from './view';
import { AddItemNodeProps } from './model';

export const AddItemNode = ({ onClick, mode, text, node }: AddItemNodeProps) => {
    const dispatch: AppDispatch = useDispatch();

    const [showInput, setShowInput] = useState(false);
    const [value, setValue] = useState('');
    const [creating, setCreating] = useState(false);

    const onLocalClick = () => {
        if (mode === 'modal') {
            onClick?.();
        } else {
            setShowInput(true);
        }
    };
    const onCancel = () => {
        setValue('');
        setShowInput(false);
    };
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    const onCreate = async () => {
        if (!value) return;

        setCreating(true);

        try {
            const newNode = await dispatch(
                createKeyword({
                    key: value,
                    level: node ? node.level + 1 : 1,
                    parentId: node?.id,
                }),
            );
            dispatch(getKeywords());
            toast.success(`Keyword "${newNode.key}" was successfully created`);
        } catch (e: any) {
            toast.error(e?.message ?? 'Failed to create/update keyword');
        }

        setCreating(false);
        onCancel();
    };

    return (
        <View
            creating={creating}
            text={text}
            value={value}
            onChange={onChange}
            onClick={onLocalClick}
            showInput={showInput}
            onCancel={onCancel}
            onCreate={onCreate}
        />
    );
};
