import cn from 'classnames';

import { CollapseIcon } from '../../icons/collapseIcon';
import { EditIcon } from '../../icons/editIcon';
import { DeleteIcon } from '../../icons/deleteIcon';
import { AddItemNode } from '../../../pages/database/addItemNode';
import { AddIcon } from '../../icons/addIcon';

import { ViewProps } from './model';

import { TreeNode } from './index';

import './style.scss';

export const View = ({
    node,
    parentNode,
    selectedNode,
    onSelect,
    onCreateEditNode,
    onDelete,
    config,
    mode,
    unselectable,
    hiddenNodeIds,
    children,
    open,
    showMore,
    withChildren,
    onLocalSelect,
    onCreateChild,
    onLocalDelete,
    collapse,
    onShowMoreClick,
}: ViewProps) => {
    const addItemLabel = config.addItemsLabelsMap[node.level] ?? config.defaultAddItemLabel;
    const isSelected = selectedNode?.id === node.id;
    const isLastChildWithChildren = children && children.length && children[children.length - 1].children?.length;

    return (
        <div className="tree-node">
            {open && <div className="line" />}
            <div className="tree-node-label" onClick={withChildren ? collapse : undefined}>
                <CollapseIcon
                    className={cn('collapse-icon', { 'collapse-icon__show': withChildren })}
                    open={Boolean(open)}
                />
                <div
                    onClick={onLocalSelect}
                    className={cn('tree-node-label-name', { 'tree-node-label-name__selected': isSelected })}
                >
                    {node.key}
                </div>
                <div className="tree-node-buttons">
                    <AddIcon className="icon" onClick={onCreateChild} />
                    <EditIcon
                        className="icon"
                        onClick={(e?: MouseEvent) => {
                            e?.stopPropagation();
                            onCreateEditNode(node, parentNode);
                        }}
                    />
                    <DeleteIcon className="icon" onClick={onLocalDelete} />
                </div>
            </div>
            {open && (
                <div className="children">
                    {children.map((child) => {
                        if (hiddenNodeIds.includes(child.id)) {
                            return null;
                        }

                        return (
                            <TreeNode
                                key={child.id}
                                node={child}
                                parentNode={node}
                                selectedNode={selectedNode}
                                onSelect={onSelect}
                                onCreateEditNode={onCreateEditNode}
                                onDelete={onDelete}
                                config={config}
                                mode={mode}
                                unselectable={unselectable}
                                hiddenNodeIds={hiddenNodeIds}
                            />
                        );
                    })}
                </div>
            )}
            {open && showMore && (
                <div className="tree-node-more" onClick={onShowMoreClick}>
                    More
                </div>
            )}
            {open && (
                <div
                    className={cn('tree-node-add-item', {
                        ['tree-node-add-item__extra-margin']: !isLastChildWithChildren,
                    })}
                >
                    <AddItemNode text={addItemLabel} mode={mode} node={node} onClick={onCreateChild} />
                </div>
            )}
        </div>
    );
};
