import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import './style.scss';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useMemo } from 'react';

import { StatisticType } from '../../shared/common/models/statistic';

import { PageButtonRenderer, StatisticTypeTranslate, TableProps } from './model';

const columns = [
    {
        dataField: 'type',
        text: 'Type',
        sort: true,
    },
    {
        dataField: 'val',
        text: 'Value',
        sort: true,
    },
];

export const TableStatistic = (props: TableProps) => {
    const { data } = props;

    const pageButtonRenderer = ({ page, onPageChange }: PageButtonRenderer) => {
        const handleClick = (e: any) => {
            e.preventDefault();
            onPageChange(page);
        };
        return (
            <li className={'page-item-statistic'}>
                <a href="#" onClick={handleClick} className="page-link">
                    {page}
                </a>
            </li>
        );
    };

    const options = {
        pageButtonRenderer,
    };

    const renderData = useMemo(() => {
        return data.map((data) => {
            switch (data.type) {
                case StatisticType.fullBilled:
                    return {
                        type: StatisticTypeTranslate.fullBilled,
                        val: data.val,
                    };
                case StatisticType.fullBilledDay:
                    return {
                        type: StatisticTypeTranslate.fullBilledDay,
                        val: data.val,
                    };
                case StatisticType.fullBilledMonth:
                    return {
                        type: StatisticTypeTranslate.fullBilledMonth,
                        val: data.val,
                    };
                case StatisticType.fullBilledWeek:
                    return {
                        type: StatisticTypeTranslate.fullBilledWeek,
                        val: data.val,
                    };
                case StatisticType.userCount:
                    return {
                        type: StatisticTypeTranslate.userCount,
                        val: data.val,
                    };
                case StatisticType.userActivityDay:
                    return {
                        type: StatisticTypeTranslate.userActivityDay,
                        val: data.val,
                    };
                case StatisticType.userActivityWeek:
                    return {
                        type: StatisticTypeTranslate.userActivityWeek,
                        val: data.val,
                    };
                case StatisticType.userActivityMonth:
                    return {
                        type: StatisticTypeTranslate.userActivityMonth,
                        val: data.val,
                    };
                default:
                    return {
                        type: data.type,
                        val: data.val,
                    };
            }
        });
    }, [data]);

    return (
        <div className="container-table-statistic">
            <BootstrapTable
                bootstrap4
                condensed
                hover
                classes="table-class-statistic"
                bordered={false}
                keyField="id"
                data={renderData}
                columns={columns}
                headerClasses="headerClass-statistic"
                pagination={paginationFactory(options)}
            />
        </div>
    );
};
