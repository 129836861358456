import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../../redux';
import { CategoryBase } from '../../../common/model/category';
// eslint-disable-next-line import/order
import { ReactComponent as Arrow } from '../../../assets/arrow-select.svg';

import './style.scss';
import { addItemToWorkzoneTreeSelectedItemKeys, setWorkzoneLastItem } from '../../../redux/slices/keyword';

export const TreeItem = ({ item, parents }: { item: CategoryBase; parents: string[] }) => {
    const selectedItems = useSelector((state: RootState) => state.keyword.workzoneTreeSelectedItemKeys);
    const dispatch: AppDispatch = useDispatch();
    const [showMore, setShowMore] = useState(false);

    const selected = useMemo(() => {
        const currentItemParentWithSelfArray = [...parents, item.key];
        const res = currentItemParentWithSelfArray.reduce((prev, cur, curIndex) => {
            return selectedItems[curIndex] === cur && prev;
        }, true);
        return res;
    }, [item.key, selectedItems, parents]);

    const onTreeClick = () => {
        const chosenItems = [...parents];
        if (!selected) {
            chosenItems.push(item.key);
        }
        dispatch(addItemToWorkzoneTreeSelectedItemKeys(chosenItems));
        dispatch(setWorkzoneLastItem(item as any));
    };

    const childrenToView = useMemo(() => {
        if (!item.children?.length) {
            return null;
        }
        if (showMore) {
            return item.children;
        }

        return item.children.slice(0, 3);
    }, [item.children, showMore]);

    return (
        <div className="tree-item">
            <div onClick={onTreeClick} className="tree-item-label-container">
                <label className={classNames('tree-item-label', { 'choosen-label': selected })}>{item.key}</label>
                {item.children?.length ? <Arrow style={{ transform: `rotate(${selected ? 180 : 0}deg)` }} /> : null}
            </div>
            {selected ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="tree-item-children">
                        {childrenToView?.map((child) => {
                            return <TreeItem key={child.key} item={child} parents={[...parents, item.key]} />;
                        })}
                    </div>
                    {(item?.children?.length || 0) > 3 && !showMore ? (
                        <span onClick={() => setShowMore(true)} className="tree-item-show-more">
                            more...
                        </span>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};
