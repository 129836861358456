import { DEFAULT_TAB, ReportsTabs } from './model';

export function initializeTab(tab: ReportsTabs | undefined): ReportsTabs {
    if (
        tab !== ReportsTabs.CashIncome &&
        tab !== ReportsTabs.RecognitionMinutes &&
        tab !== ReportsTabs.RecognitionPhrase
    ) {
        return DEFAULT_TAB;
    }
    return tab;
}
