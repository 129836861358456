import React from 'react';

// import { CloseIcon } from '../../icons/closeIcon';
// import { Button } from '../../button';
// import { ButtonType } from '../../../common/enum/buttonType';

import { CategoryLabelProps } from './model';

export const CategoryLabel = ({ label /*, isLast*/ }: CategoryLabelProps) => {
    return <div className="category-label">{label}</div>;
};
