import React, { useMemo } from 'react';

import { ReactComponent as Micro } from '../../../assets/micro.svg';
import { ReactComponent as Pause } from '../../../assets/pause.svg';
import { ReactComponent as Play } from '../../../assets/play.svg';

import { SpeakerButtonProps } from './model';

import './style.scss';

export const SpeakerButton = ({ audioState, onStart, onResume, onPause }: SpeakerButtonProps) => {
    const { icon, onClick } = useMemo(() => {
        switch (audioState) {
            case 'running':
                return {
                    onClick: onPause,
                    icon: <Pause />,
                };
            case 'suspended':
                return {
                    onClick: onResume,
                    icon: <Play />,
                };
            case null:
            default:
                return {
                    onClick: onStart,
                    icon: <Micro />,
                };
        }
    }, [audioState, onPause, onResume, onStart]);

    return (
        <div className="speaker-button-wrapper">
            <button className="speaker-button" onClick={onClick}>
                {icon}
            </button>
        </div>
    );
};
