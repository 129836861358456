import { Controller, FormProvider } from 'react-hook-form';
import React from 'react';

import { Button } from '../../../../components/button';
import { ButtonType } from '../../../../common/enum/buttonType';
import { FormField } from '../../../../components/formField';
import { ParentNodeSelector } from '../../../../components/parentNodeSelector';
import { Wysiwyg } from '../../../../components/wysiwyg';
import { UploadResourcesModal } from '../../../../components/uploadResourcesModal';
import { SelectResourcesModal } from '../../../../components/selectResourcesModal';

import { ViewProps } from './model';

export const View = ({
    form,
    onSubmit,
    onClose,
    countriesOptions,
    isAddImageModalOpened,
    isSelectImageModalOpened,
    setIsSelectImageModalOpened,
    setIsAddImageModalOpened,
}: ViewProps) => {
    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit} className="article-form">
                {isAddImageModalOpened && <UploadResourcesModal onClose={() => setIsAddImageModalOpened(false)} />}
                {isSelectImageModalOpened && (
                    <SelectResourcesModal onClose={() => setIsSelectImageModalOpened(false)} />
                )}
                <div className="article-form-inputs-block">
                    <FormField type="text" name="title" title="Article title" rules={{ required: true }} />
                    <FormField
                        multiple
                        type="select"
                        options={countriesOptions}
                        name="country"
                        title="Region"
                        rules={{ required: true }}
                    />
                    <Controller
                        render={({ field }) => <ParentNodeSelector onChange={field.onChange} value={field.value} />}
                        name="parent_node"
                    />
                </div>
                <div className="images-controls-wrapper">
                    <Button
                        title={'Add image'}
                        type={ButtonType.secondary}
                        onClick={() => setIsAddImageModalOpened(true)}
                    />
                    <Button
                        title={'Select image'}
                        type={ButtonType.secondary}
                        onClick={() => setIsSelectImageModalOpened(true)}
                    />
                </div>
                <div className="article-form-editor-block">
                    <Controller
                        render={({ field }) => (
                            <Wysiwyg content={field.value} onChange={field.onChange} placeholder="Article text" />
                        )}
                        name="content"
                    />
                </div>
                <div className="article-form-buttons-block">
                    <Button
                        title="Save"
                        type={ButtonType.primary}
                        buttonType="submit"
                        disabled={form.formState.isSubmitting /* || !form.formState.isDirty*/}
                    />
                    <Button
                        title="Cancel"
                        type={ButtonType.secondary}
                        onClick={onClose}
                        disabled={form.formState.isSubmitting}
                    />
                </div>
            </form>
        </FormProvider>
    );
};
