import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LogOut } from '../../../assets/logout.svg';
import { PopupMessage } from '../../popupMessage';
import { ReactComponent as Logo } from '../../../assets/logo.svg';

import { ViewProps } from './model';
import './style.scss';

export const AuthHeaderView = ({ navLinkData, onLogout, isEmailConfirmed }: ViewProps) => {
    return (
        <>
            <div className="auth-header">
                <div className="auth-header-logo-wrapper">
                    <Logo width={40} height={40} />
                </div>
                <div className="auth-header-body">
                    <div className="auth-header-body-links">
                        {navLinkData.map(({ name, to, Icon }) => (
                            <NavLink key={to} to={to} className="auth-header-button">
                                <Icon />
                                <span>{name}</span>
                            </NavLink>
                        ))}
                    </div>
                    <button className="auth-header-button auth-header-logout" onClick={onLogout}>
                        <LogOut />
                        <span>Log out</span>
                    </button>
                </div>
            </div>
            {!isEmailConfirmed && <PopupMessage title="Confirmation" message="Please confirm e-mail" />}
        </>
    );
};
