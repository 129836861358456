import { ChangeEvent, useState } from 'react';

import { httpService } from '../../common/http';
import { backendRoutes } from '../../routes/backend';

import { View } from './view';
import { UploadResourcesModalProps } from './model';

export function UploadResourcesModal(props: UploadResourcesModalProps) {
    const { onClose } = props;

    const [files, setFiles] = useState<File[]>([]);

    const handleUploadClick = async () => {
        if (!files.length) {
            return;
        }

        const data = new FormData();

        files.forEach((file) => {
            data.append(`files[]`, file, file.name);
        });

        await httpService.post(backendRoutes.resources, data).then(() => onClose());
    };

    const onFilesSelected = (ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files) {
            const newFiles = [...files, ...Array.from(ev.target.files)];
            setFiles(newFiles);
        }
    };

    const deleteFile = (file: File) => setFiles((prev) => prev.filter((f) => f !== file));

    return View({
        onClose,
        onFilesSelected,
        handleUploadClick,
        files,
        deleteFile,
    });
}
