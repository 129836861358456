import { memo, useCallback, useMemo, useState, MouseEvent, useEffect } from 'react';

import { View } from './view';
import { TreeNodeProps } from './model';

const ITEMS_CHUNK = 12;

export const TreeNode = memo(
    ({
        node,
        parentNode,
        selectedNode,
        onSelect,
        onCreateEditNode,
        onDelete,
        config,
        mode,
        unselectable,
        hiddenNodeIds,
    }: TreeNodeProps) => {
        const [open, setOpen] = useState(false);
        const [renderItemsCount, setRenderItemsCount] = useState(ITEMS_CHUNK);

        const withChildren = (node.children?.length ?? 0) > 0;
        const showMore = renderItemsCount < (node.children?.length ?? 0);

        const children = useMemo(() => {
            if (!node.children?.length) return [];
            if (node.children.length <= ITEMS_CHUNK) return node.children;
            return node.children.slice(0, renderItemsCount);
        }, [node.children, renderItemsCount]);

        const collapse = useCallback(
            (ev: MouseEvent<HTMLDivElement>) => {
                ev.stopPropagation();
                if (node.children?.length) {
                    setOpen((prev) => !prev);
                }
            },
            [node.children?.length],
        );
        const onLocalSelect = useCallback(
            (ev: MouseEvent<HTMLDivElement>) => {
                ev.stopPropagation();
                onSelect?.(unselectable && node.id === selectedNode?.id ? null : node);
            },
            [onSelect, unselectable, node, selectedNode],
        );
        const onLocalDelete = useCallback(
            (e?: MouseEvent) => {
                e?.stopPropagation();
                onDelete(node);
            },
            [node, onDelete],
        );
        const onShowMoreClick = useCallback(() => {
            setRenderItemsCount((prev) => prev + ITEMS_CHUNK);
        }, []);
        const onCreateChild = useCallback(
            (e?: MouseEvent) => {
                e?.stopPropagation();
                setOpen(Boolean(node.children?.length));
                onCreateEditNode({ level: node.level + 1, id: 'new', key: '' }, node);
            },
            [node, onCreateEditNode],
        );

        useEffect(() => {
            if (!node.children?.length) {
                setOpen(false);
            }
        }, [node.children?.length]);

        return (
            <View
                node={node}
                parentNode={parentNode}
                selectedNode={selectedNode}
                onSelect={onSelect}
                onCreateEditNode={onCreateEditNode}
                onDelete={onDelete}
                config={config}
                mode={mode}
                unselectable={unselectable}
                hiddenNodeIds={hiddenNodeIds}
                children={children}
                open={open}
                showMore={showMore}
                withChildren={withChildren}
                onLocalSelect={onLocalSelect}
                onCreateChild={onCreateChild}
                onLocalDelete={onLocalDelete}
                collapse={collapse}
                onShowMoreClick={onShowMoreClick}
            />
        );
    },
);
