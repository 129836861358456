import { TabsList } from '../../components/tabs/tabsList';
import { TabItem } from '../../components/tabs/tabItem';
import { Tabs } from '../../components/tabs';
import { TabContent } from '../../components/tabs/tabContent';
import { useTabs } from '../../components/tabs/useTabs';
import { FontTitle } from '../../components/fontTitle';
import { ContentWrapper } from '../../components/contentWrapper';

import { SettingsTabs, ViewProps } from './model';
import { Subscription } from './subscription';
import { InternetResources } from './internetResources';
import { OtherOptions } from './otherOptions';

import './style.scss';

export const View = (props: ViewProps) => {
    const { tabsConfig, initialTab } = props;
    const { onTabItemClick, currentTabIndex } = useTabs({ initialIndex: initialTab, config: tabsConfig });

    return (
        <ContentWrapper>
            <div className="settings">
                <FontTitle marginBottom={49} level={1}>
                    Settings
                </FontTitle>
                <Tabs>
                    <TabsList>
                        <TabItem
                            currentTabIndex={currentTabIndex}
                            onClick={onTabItemClick}
                            index={SettingsTabs.Subscriptions}
                        >
                            Subscription
                        </TabItem>
                        <TabItem
                            currentTabIndex={currentTabIndex}
                            onClick={onTabItemClick}
                            index={SettingsTabs.InternetResources}
                        >
                            Internet resources
                        </TabItem>
                        <TabItem
                            currentTabIndex={currentTabIndex}
                            onClick={onTabItemClick}
                            index={SettingsTabs.OtherOptions}
                        >
                            Other options
                        </TabItem>
                    </TabsList>
                    <TabContent currentTabIndex={currentTabIndex} index={SettingsTabs.Subscriptions}>
                        <Subscription />
                    </TabContent>
                    <TabContent currentTabIndex={currentTabIndex} index={SettingsTabs.InternetResources}>
                        <InternetResources />
                    </TabContent>
                    <TabContent currentTabIndex={currentTabIndex} index={SettingsTabs.OtherOptions}>
                        <OtherOptions />
                    </TabContent>
                </Tabs>
            </div>
        </ContentWrapper>
    );
};
