import { useEffect, useState } from 'react';

import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { SubscriptionsViewModel } from '../../../shared/common/models/subscription';

import { SubscriptionCard } from './card';

export const PlanModal = () => {
    const [subscriptions, setSubscriptions] = useState<SubscriptionsViewModel[]>([]);

    useEffect(() => {
        httpService.get(backendRoutes.getSubscriptions).then((res) => setSubscriptions(res));
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            {subscriptions
                .filter((s) => !s.isDeleted)
                .map((s) => (
                    <SubscriptionCard subscription={s} key={s.name} />
                ))}
        </div>
    );
};
