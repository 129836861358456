import { useSelector } from 'react-redux';

import { RootState } from '../../../redux';
import { selectedLastItemSelector } from '../../../redux/selectors/keyword';
import { recognizedWordsSelector } from '../../../redux/selectors/recognizeText';

export function useLinkKeywords(): string[] {
    const parents = useSelector((state: RootState) => state.keyword.workzoneTreeSelectedItemKeys);
    const recognizedWords = useSelector(recognizedWordsSelector);

    const selectLastItem = useSelector(selectedLastItemSelector);
    const selectedLastItemWord = selectLastItem ? [selectLastItem.key] : [];

    return selectLastItem ? Array.from(new Set([...parents, ...selectedLastItemWord])) : [...recognizedWords];
}
