import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExternalSource } from '../../../common/model/externalSource';
import { KeywordDocument } from '../../../common/model/database';

export interface CatalogState {
    externalSources: ExternalSource[];
    selectedKeywordDocument: KeywordDocument | null;
}

const initialState: CatalogState = {
    externalSources: [],
    selectedKeywordDocument: null,
};

const catalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setExternalSources: (state, action: PayloadAction<ExternalSource[]>) => {
            state.externalSources = action.payload;
        },
        setSelectedKeywordDocument: (state, action: PayloadAction<KeywordDocument | null>) => {
            state.selectedKeywordDocument = action.payload;
        },
    },
});

export const { setExternalSources, setSelectedKeywordDocument } = catalogSlice.actions;

export const catalogReducer = catalogSlice.reducer;
