import React, { ChangeEvent, useLayoutEffect, useState } from 'react';

import { Modal } from '../../../../../components/modal';
import { Input } from '../../../../../components/input';
import { Button } from '../../../../../components/button';
import { ButtonType } from '../../../../../common/enum/buttonType';
import { ButtonsWrapper } from '../../../../../components/buttonsWrapper';

import { EditModalProps, ModalState } from './model';

export const EditModal = ({ data, isOpen, onAddUpdate, onClose, title }: EditModalProps) => {
    const [value, setValue] = useState('');

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };
    const onApply = () => {
        onAddUpdate(value);
        onClose();
    };

    useLayoutEffect(() => {
        setValue(data?.field?.name ?? '');
    }, [data]);

    return (
        <Modal
            className="other-options-edit-modal"
            title={`${data?.state === ModalState.New ? 'New' : 'Edit'} ${title}`}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Input className="other-options-edit-modal-input" value={value} onChange={onChange} title={title} />
            <ButtonsWrapper columnGap={24}>
                <Button
                    title={data?.state === ModalState.New ? 'Add' : 'Edit'}
                    type={ButtonType.primary}
                    onClick={onApply}
                />
                <Button title="Cancel" type={ButtonType.secondary} onClick={onClose} />
            </ButtonsWrapper>
        </Modal>
    );
};
