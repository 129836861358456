export const toolbar = {
    options: ['inline', 'fontSize', 'colorPicker', 'link', 'remove', 'image', 'list', 'history'],
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
    },
    fontSize: {
        icon: 'fontSize',
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    list: {
        inDropdown: false,
        options: ['unordered', 'ordered', 'indent', 'outdent'],
    },
    textAlign: {
        inDropdown: false,
        options: ['left', 'center', 'right', 'justify'],
    },
    colorPicker: {
        colors: [
            'rgb(0,0,0)',
            'rgb(204,204,204)',
            'rgb(255,255,255)',
            'rgb(97,189,109)',
            'rgb(26,188,156)',
            'rgb(84,172,210)',
            'rgb(44,130,201)',
            'rgb(147,101,184)',
            'rgb(61,142,185)',
            'rgb(41,105,176)',
            'rgb(85,57,130)',
            'rgb(40,50,78)',
            'rgb(235,107,86)',
            'rgb(226,80,65)',
            'rgb(163,143,132)',
            'rgb(239,239,239)',
            'rgb(250,197,28)',
            'rgb(243,121,52)',
            'rgb(184,49,47)',
            'rgb(124,112,107)',
        ],
    },
    link: {
        defaultTargetOption: '_self',
        options: ['link', 'unlink'],
    },
    image: {
        urlEnabled: true,
        alignmentEnabled: true,
        uploadEnabled: false, // todo: сделать загрузку на сервер
        uploadCallback: undefined,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: true },
        defaultSize: {
            height: '100',
            width: '100',
        },
    },
    history: {
        options: ['undo', 'redo'],
    },
};
