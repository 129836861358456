import { createSelector } from 'reselect';

import { RootState } from '../../index';

const catalogSelector = (state: RootState) => state.catalog;

export const externalSourcesSelector = createSelector(catalogSelector, (state) => state.externalSources);

export const selectedKeywordDocumentSelector = createSelector(
    catalogSelector,
    (state) => state.selectedKeywordDocument,
);
