import cn from 'classnames';

import { ButtonsWrapperProps } from './model';

import './style.scss';

export const View = (props: ButtonsWrapperProps) => {
    const { className, columnGap, children } = props;
    return (
        <div
            style={{
                columnGap: columnGap,
            }}
            className={cn(className, 'buttons-wrapper')}
        >
            {children}
        </div>
    );
};
