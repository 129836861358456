import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../redux';
import { getKeywordDocument } from '../../../redux/actions/catalog';
import { selectedKeywordDocumentSelector } from '../../../redux/selectors/catalog';
import { Routes } from '../../../routes/routes';
import { keywordsItemsDataSelector } from '../../../redux/selectors/keyword';
import { useConvertContentToHTML } from '../../../hooks/useConvertContentToHTML/useConvertContentToHTML';

import { View } from './view';
import { getParentKeys } from './services';

export const DocumentDetailsModal = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const keywordDocument = useSelector(selectedKeywordDocumentSelector);
    const keywordItemsData = useSelector(keywordsItemsDataSelector);

    useEffect(() => {
        if (!id) return;

        dispatch(getKeywordDocument(+id));
    }, [dispatch, id]);

    const keywords = useMemo(
        () => getParentKeys(keywordItemsData, keywordDocument?.keyword, []),
        [keywordDocument?.keyword, keywordItemsData],
    );

    const html = useConvertContentToHTML(keywordDocument?.content);

    if (!keywordDocument) return null;

    return View({
        keywordDocument,
        onClose: () => navigate(Routes.workzone),
        keywords,
        html,
    });
};
