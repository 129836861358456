import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider } from 'react-hook-form';

import { ReactComponent as Close } from '../../assets/close.svg';
import { Button } from '../button';
import { ButtonType } from '../../common/enum/buttonType';
import { recognizeTimingSelector } from '../../redux/selectors/recognizeText';
import { FormField } from '../formField';

import { SpeakerButton } from './speakerButton';
import { ViewProps } from './model';
import { AudioServiceState } from './services/audioService';

import './style.scss';

export const View = ({
    text,
    audioState,
    hideText,
    onStart,
    onStop,
    onPause,
    onResume,
    speakerForm,
    onSubmit,
}: ViewProps) => {
    const timing = useSelector(recognizeTimingSelector);

    return (
        <div className="speaker">
            <div className="speaker-text">
                {text && !hideText && !speakerForm.watch('phrase') ? (
                    text
                ) : (
                    <>
                        <FormProvider {...speakerForm}>
                            <form onSubmit={speakerForm.handleSubmit(onSubmit)}>
                                <FormField
                                    className="phrase-input"
                                    children={
                                        <>
                                            <span>
                                                When the button was pressed, the recording started and the key word was
                                                said&nbsp;
                                            </span>
                                            <span className="speaker-text-keyword">MRI</span>
                                        </>
                                    }
                                    type="text"
                                    name="phrase"
                                />
                            </form>
                        </FormProvider>
                    </>
                )}
            </div>
            {(audioState && audioState !== AudioServiceState.Closed) || speakerForm.watch('phrase') ? (
                <Button
                    title=""
                    type={ButtonType.borderOff}
                    icon={<Close width={32} height={32} />}
                    className="speaker-stop-button"
                    onClick={onStop}
                />
            ) : null}
            <SpeakerButton audioState={audioState} onStart={onStart} onPause={onPause} onResume={onResume} />
            {process.env.REACT_APP_ENV === 'dev' && (
                <div className="speaker-timing">
                    <div>Current time: {timing.current.toString()}s</div>
                    <div>Total time: {timing.total.toString()}s</div>
                </div>
            )}
        </div>
    );
};
