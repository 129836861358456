import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { httpService } from '../../../../common/http';
import { AppDispatch } from '../../../../redux';
import { getKeywordDocuments } from '../../../../redux/actions/keyword';
import { backendRoutes } from '../../../../routes/backend';
import { Routes } from '../../../../routes/routes';

import { ArticleProps } from './model';
import { View } from './view';

export const Article = (props: ArticleProps) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const onDelete = async () => {
        await httpService.delete(backendRoutes.deleteDocumentFromKeyword.replace(':id', props.id.toString()));
        dispatch(getKeywordDocuments());
    };

    const onSelect = async () => {
        navigate(Routes.databaseEditrticle.replace(':id', props.id.toString()));
    };

    const onCopy = () => {
        navigate(Routes.databaseCopyArticle.replace(':id', props.id.toString()));
    };

    return <View {...props} onDelete={onDelete} onSelect={onSelect} onCopy={onCopy} />;
};
