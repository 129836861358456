/* eslint-disable import/order */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getKeywords } from '../../redux/actions/keyword';
import { keywordsSelector } from '../../redux/selectors/keyword';
import { AppDispatch } from '../../redux';
import { TreeItem } from './treeItem';

import './style.scss';

export const CategoryTree = () => {
    const dispatch: AppDispatch = useDispatch();
    const keywords = useSelector(keywordsSelector);
    useEffect(() => {
        dispatch(getKeywords());
    }, [dispatch]);

    if (!keywords.data?.length) {
        return null;
    }
    return (
        <div className="catalog-tree-workzone">
            <div className="catalog-tree-header">
                <label className="catalog-tree-header-label">Catalog</label>
            </div>
            <div className="catalog-tree-data-container">
                {keywords.data.map((item) => {
                    return <TreeItem item={item} key={item.key} parents={[]} />;
                })}
            </div>
        </div>
    );
};
