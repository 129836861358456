import { convertToRaw } from 'draft-js';

import { ArticleRequestData } from '../../../../common/model/database';

import { ArticleFormData } from './model';

export const mapFormDataToRequest = (data: ArticleFormData): ArticleRequestData => {
    return {
        title: data.title,
        country: data.country.map(({ value }) => value),
        parent_node: data.parent_node?.value ?? 0,
        content: convertToRaw(data.content.getCurrentContent()),
    };
};
