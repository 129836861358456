import { useMemo } from 'react';

import { DataTableProps } from './model';
import { View } from './view';
import { mapColumnProps } from './services';

export const DataTable = <T extends object>(props: DataTableProps<T>) => {
    const { columns } = props;
    const tableColumns = useMemo(() => mapColumnProps(columns), [columns]);
    return <View<T> {...props} columns={tableColumns} />;
};
