import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../../common/model/user';

export interface AuthState {
    token?: string;
    user?: User;
}

const initialState: AuthState = {
    token: localStorage.getItem('token') || undefined,
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : undefined,
};

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload;
            localStorage.setItem('token', action.payload);
        },
        setUser: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload));
            state.user = action.payload;
        },
        setToken: (state, action: PayloadAction<string>) => {
            localStorage.setItem('token', action.payload);
            state.token = action.payload;
        },
        setFullInfo: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('token', action.payload.token);
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        logout: (state) => {
            localStorage.clear();
            state.token = undefined;
            state.user = undefined;
        },
    },
});

// Action creators are generated for each case reducer function
export const { login, logout, setUser, setToken, setFullInfo } = authSlice.actions;

export default authSlice.reducer;
