import { DeleteKeyword } from '../../../../../common/model/dictionaries';
import { FormItem } from '../formItems/model';

export const mapKeywordsToFormItems = (keywords: DeleteKeyword[]): FormItem[] => {
    return keywords.map(({ id, key }) => ({ id, name: key }));
};

export const mapFormItemsToKeywords = (items: FormItem[]): DeleteKeyword[] => {
    return items.map(({ id, name }) => ({ id, key: name }));
};
