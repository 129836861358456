import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { keywordsSelector } from '../../../redux/selectors/keyword';
import { getKeywords } from '../../../redux/actions/keyword';
import { AppDispatch } from '../../../redux';

import { CatalogTreeProps } from './model';
import { View } from './view';

export const CatalogTree = (props: CatalogTreeProps) => {
    const dispatch: AppDispatch = useDispatch();
    const { data, error, isLoading } = useSelector(keywordsSelector);

    useEffect(() => {
        if (isLoading) return;

        if (!data && !error) {
            dispatch(getKeywords());
        }
    }, [data, dispatch, error, isLoading]);

    return <View {...props} items={data} />;
};
