import { useForm } from 'react-hook-form';
import { EditorState, convertFromRaw } from 'draft-js';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCountries } from '../../../../hooks/useCountries';
import { AppDispatch } from '../../../../redux';
import { httpService } from '../../../../common/http';
import { selectedNodeSelector } from '../../../../redux/selectors/keyword';
import { getKeywordDocuments } from '../../../../redux/actions/keyword';
import { backendRoutes } from '../../../../routes/backend';

import { View } from './view';
import { ArticleFormData, FormProps } from './model';
import { mapFormDataToRequest } from './utils';

export const Form = ({ onClose, initialValue }: FormProps) => {
    const parentNode = useSelector(selectedNodeSelector);
    const dispatch: AppDispatch = useDispatch();

    const [isAddImageModalOpened, setIsAddImageModalOpened] = useState(false);
    const [isSelectImageModalOpened, setIsSelectImageModalOpened] = useState(false);

    const parentNodeCalc = useMemo(() => {
        if (!parentNode) {
            return initialValue?.keyword;
        }

        return parentNode;
    }, [parentNode, initialValue]);

    const initialCountries = initialValue?.countries.map((c: any) => {
        const countryOption = {
            label: c.name,
            value: c.id,
        };
        return countryOption;
    });
    const form = useForm<ArticleFormData>({
        defaultValues: {
            id: initialValue?.id || undefined,
            title: initialValue?.name || 'Title',
            country: initialCountries || [],
            parent_node: {
                label: parentNodeCalc?.key,
                value: parentNodeCalc?.id,
            },
            content: initialValue?.content
                ? EditorState.createWithContent(convertFromRaw(initialValue?.content))
                : EditorState.createEmpty(),
        },
    });

    const countries = useCountries();
    const countriesOptions = useMemo(() => {
        return countries.map(({ id, name }) => ({ label: name, value: id }));
    }, [countries]);

    const onSubmit = form.handleSubmit(async (data) => {
        const requestData = mapFormDataToRequest(data);
        const dto = {
            id: (data as any).id,
            name: requestData.title,
            keywordId: parentNode?.id,
            content: requestData.content,
            countryIds: requestData.country,
        };
        if (dto.id) {
            await httpService.put(backendRoutes.updateDocument, dto);
        } else {
            await httpService.post(backendRoutes.addDocument, dto);
        }
        dispatch(getKeywordDocuments());
        onClose();
    });

    return View({
        form,
        onSubmit,
        onClose,
        countriesOptions,

        isAddImageModalOpened,
        isSelectImageModalOpened,
        setIsAddImageModalOpened,
        setIsSelectImageModalOpened,
    });
};
