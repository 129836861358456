import React from 'react';
import { useRoutes } from 'react-router';

import { Login } from './pages/login';
import { SignUp } from './pages/signUp';
import { StartPage } from './pages/start';
import { UserWorkzone } from './pages/userWorkzone';
import { PasswordRecovery } from './pages/passwordRecovery';
import { Users } from './pages/users';
import { User } from './pages/user';
import { Database } from './pages/database';
import { Article, ArticleWrapper } from './pages/article';
import { Routes } from './routes/routes';
import { Profile } from './pages/profile';
import { Settings } from './pages/settings';
import { DocumentDetailsModal } from './pages/userWorkzone/documentDetailsModal';
import { Reports } from './pages/reports';
import { AboutUsPage } from './pages/aboutUs';
import { CopyModal } from './pages/database/catalogArticles/article/copyModal';
import { ProfilePaymentHistory } from './pages/profilePaymentHistory';
import { ProfileUpdatePlan } from './pages/profileUpdatePlan';
import { ProfileSuccessPayment } from './pages/profileSuccessPayment';

function Content() {
    return useRoutes([
        {
            path: Routes.home,
            element: <StartPage />,
            children: [
                { path: 'login', element: <Login /> },
                { path: 'password-recovery', element: <PasswordRecovery /> },
                { path: 'signup', element: <SignUp /> },
            ],
        },

        {
            path: Routes.workzone,
            element: <UserWorkzone />,
            children: [
                {
                    path: 'document/:id',
                    element: <DocumentDetailsModal />,
                },
            ],
        },
        {
            path: Routes.database,
            element: <Database />,
            children: [
                {
                    path: '/admin/database/article',
                    element: <ArticleWrapper />,
                    children: [
                        { path: Routes.databaseNewArticle, element: <Article isNew /> },
                        { path: Routes.databaseEditrticle, element: <Article /> },
                        { path: Routes.databaseCopyArticle, element: <CopyModal /> },
                    ],
                },
            ],
        },
        {
            path: Routes.users,
            element: <Users />,
            children: [
                {
                    path: ':id',
                    element: <User />,
                },
            ],
        },
        {
            path: Routes.profile,
            element: <Profile />,
            children: [
                {
                    path: Routes.profilePaymentHistory,
                    element: <ProfilePaymentHistory />,
                },
                {
                    path: Routes.profileSubscriptionUpdate,
                    element: <ProfileUpdatePlan />,
                },
                {
                    path: Routes.profilePaymentSuccesfull,
                    element: <ProfileSuccessPayment />,
                },
            ],
        },
        {
            path: Routes.settings,
            element: <Settings />,
            children: [
                {
                    path: ':tab',
                    element: <Settings />,
                },
            ],
        },
        {
            path: Routes.reports,
            element: <Reports />,
            children: [
                {
                    path: ':tab',
                    element: <Reports />,
                },
            ],
        },
        {
            path: Routes.aboutUs,
            element: <AboutUsPage />,
        },
    ]);
}

export default Content;
