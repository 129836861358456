import { Outlet } from 'react-router-dom';

import { ContentWrapper } from '../../components/contentWrapper';
import { Input } from '../../components/input';
import { SearchIcon } from '../../components/icons/searchIcon';
import { FontTitle } from '../../components/fontTitle';

import { CatalogTree } from './catalogTree';
import { CatalogArticles } from './catalogArticles';
import { ViewProps } from './model';

import './style.scss';

export const View = (props: ViewProps) => {
    const { search, setSearch, articles } = props;
    return (
        <>
            <ContentWrapper>
                <div className="database">
                    <div className="database-header">
                        <FontTitle level={1}>Database</FontTitle>
                        <Input
                            inputClassName="search"
                            className="search-wrapper"
                            value={search}
                            placeholder={'Search by title'}
                            onChange={(ev) => setSearch(ev.target.value)}
                            icon={<SearchIcon />}
                        />
                    </div>
                    <div className="database-content">
                        <CatalogTree className="database-catalog-tree" />
                        <CatalogArticles className="database-catalog-articles" articles={articles} />
                    </div>
                </div>
            </ContentWrapper>
            <Outlet />
        </>
    );
};
