import { View } from './view';
import { WysiwygProps } from './model';

export const Wysiwyg = ({ content, onChange, placeholder }: WysiwygProps) => {
    return View({
        placeholder,
        onEditorStateChange: onChange,
        defaultEditorState: content,
    });
};
