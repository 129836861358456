import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { logout as sliceLogout, setToken, setUser } from '../../slices/auth';
import { AppThunk } from '../../index';

import { LoginData } from './model';

export const login = (data: LoginData): AppThunk => {
    return async (dispatch) => {
        const token = await httpService.post(backendRoutes.login, data);
        dispatch(setToken(token));

        const user = await httpService.get(backendRoutes.getUserInfo);
        dispatch(setUser(user));
    };
};

export const logout = (): AppThunk => {
    return async (dispatch) => {
        // todo: call logout on server
        dispatch(sliceLogout());
    };
};

export const getRecoveryUrl = (login: string): AppThunk => {
    return async () => {
        await httpService.post(backendRoutes.getRecoveryUrl, {
            login,
        });
    };
};

export const confirmPasswordRecovery = (code: string, type: string, password: string): AppThunk => {
    return async () => {
        await httpService.post(backendRoutes.confirmPasswordRecovery, {
            code,
            type,
            password,
        });
    };
};
