import './style.scss';
import { useRef } from 'react';
import classNames from 'classnames';

import { ReactComponent as Arrow } from '../../assets/arrow-select.svg';
import { useClickOutside } from '../../hooks/useClickOutside';

import { SelectViewProps } from './model';
import { Options } from './components/options';
import { Selected } from './components/selected';

export const SelectView = ({
    title,
    options,
    value,
    focused,
    setFocused,
    error,
    onSelect,
    onDeselect,
    onClose,
    multiple = false,
}: SelectViewProps) => {
    const isEmpty = !value || (Array.isArray(value) && !value.length);
    const ref = useRef<HTMLDivElement>(null);
    useClickOutside(ref, onClose);

    return (
        <div
            ref={ref}
            className={classNames('base-select-container', { 'with-error': !!error })}
            onClick={() => setFocused((prev: boolean) => !prev)}
        >
            {title ? (
                <span className={classNames('base-select-title', { focused: focused || !isEmpty, error: !!error })}>
                    {title}
                    {error ? ` (${error})` : null}
                </span>
            ) : null}
            <div className="base-select">
                <Selected value={value} onDeselect={onDeselect} />
            </div>
            <div className={classNames('base-select-arrow', { focused })}>
                <Arrow className="arrow-svg" />
            </div>
            <Options
                multiple={multiple}
                opened={focused}
                current={value}
                options={options}
                onSelect={onSelect}
                onDeselect={onDeselect}
            />
        </div>
    );
};
