import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';

import { Editor } from 'react-draft-wysiwyg';

import { ViewProps } from './model';
import { toolbar } from './constants';

export const View = ({ defaultEditorState, onEditorStateChange, placeholder }: ViewProps) => {
    return (
        <Editor
            spellCheck
            placeholder={placeholder}
            wrapperClassName="wysiwyg-wrapper"
            toolbarClassName="wysiwyg-wrapper-toolbar"
            editorClassName="wysiwyg-wrapper-editor"
            toolbar={toolbar}
            defaultEditorState={defaultEditorState}
            onEditorStateChange={onEditorStateChange}
        />
    );
};
