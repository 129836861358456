import { useNavigate } from 'react-router';

import { Modal } from '../../components/modal';
import { PlanModal } from '../../components/paymentCard/planModal';
import { Routes } from '../../routes/routes';

export const ProfileUpdatePlan = () => {
    const navigate = useNavigate();
    const onClose = () => {
        navigate(Routes.profile);
    };

    return (
        <Modal className="payment-modal" title="UPDATE PLAN" isOpen onClose={onClose}>
            <PlanModal />
        </Modal>
    );
};
