import { EditIcon } from '../icons/editIcon';
import { CloseIcon } from '../icons/closeIcon';

import { EditableItemProps } from './model';

import './style.scss';

export const View = (props: EditableItemProps) => {
    const { label, onEditClick, onDeleteClick } = props;
    return (
        <span className="editable-item">
            {label}
            {<EditIcon className="editable-item-edit" onClick={onEditClick} />}
            {<CloseIcon className="editable-item-delete" onClick={onDeleteClick} />}
        </span>
    );
};
