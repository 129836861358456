import { TabsList } from '../../components/tabs/tabsList';
import { TabItem } from '../../components/tabs/tabItem';
import { Tabs } from '../../components/tabs';
import { TabContent } from '../../components/tabs/tabContent';
import { useTabs } from '../../components/tabs/useTabs';
import { FontTitle } from '../../components/fontTitle';
import { ContentWrapper } from '../../components/contentWrapper';

import { ReportsTabs, ViewProps } from './model';
import './style.scss';
import { RecognitionMinutes } from './recognitionMinutes';
import { СashIncome } from './cashIncome';
import { RecognitionPhrase } from './recognitionPhrase';

export const View = (props: ViewProps) => {
    const { tabsConfig, initialTab } = props;
    const { onTabItemClick, currentTabIndex } = useTabs({ initialIndex: initialTab, config: tabsConfig });

    return (
        <ContentWrapper>
            <div className="reports">
                <FontTitle marginBottom={49} level={1}>
                    Reports
                </FontTitle>
                <Tabs>
                    <TabsList>
                        <TabItem
                            currentTabIndex={currentTabIndex}
                            onClick={onTabItemClick}
                            index={ReportsTabs.CashIncome}
                        >
                            Сash income
                        </TabItem>
                        <TabItem
                            currentTabIndex={currentTabIndex}
                            onClick={onTabItemClick}
                            index={ReportsTabs.RecognitionMinutes}
                        >
                            Recognition minutes
                        </TabItem>
                        <TabItem
                            currentTabIndex={currentTabIndex}
                            onClick={onTabItemClick}
                            index={ReportsTabs.RecognitionPhrase}
                        >
                            Recognition phrase
                        </TabItem>
                    </TabsList>
                    <TabContent currentTabIndex={currentTabIndex} index={ReportsTabs.CashIncome}>
                        <СashIncome />
                    </TabContent>
                    <TabContent currentTabIndex={currentTabIndex} index={ReportsTabs.RecognitionMinutes}>
                        <RecognitionMinutes />
                    </TabContent>
                    <TabContent currentTabIndex={currentTabIndex} index={ReportsTabs.RecognitionPhrase}>
                        <RecognitionPhrase />
                    </TabContent>
                </Tabs>
            </div>
        </ContentWrapper>
    );
};
