import { ReactComponent as AddSvg } from '../../assets/add.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const AddIcon = (props: IconProps) => {
    return (
        <IconWrapper {...props}>
            <AddSvg />
        </IconWrapper>
    );
};
