import { TableStatistic } from '../../../components/tableStatistic';

import { ViewProps } from './model';
import './style.scss';

export const View = (props: ViewProps) => {
    const { data } = props;

    return (
        <div>
            <TableStatistic data={data} />
        </div>
    );
};
