import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { Modal } from '../../modal';
import { Button } from '../../button';
import { ButtonType } from '../../../common/enum/buttonType';
import { deleteKeyword, getKeywords } from '../../../redux/actions/keyword';
import { AppDispatch } from '../../../redux';

import { DeletingModalProps } from './model';

export const DeletingModal = ({ deletedNode, onClose }: DeletingModalProps) => {
    const [error, setError] = useState(false);

    const dispatch: AppDispatch = useDispatch();
    const onDelete = async () => {
        if (!deletedNode) return;

        try {
            await dispatch(deleteKeyword(deletedNode.id));
            dispatch(getKeywords());
            toast.success(`${deletedNode.key} was removed`);
            onClose();
        } catch (e) {
            toast.error(`Failed to remove ${deletedNode.key}`);
            setError(true);
        }
    };

    return (
        <Modal isOpen={Boolean(deletedNode)} onClose={onClose} title={error ? 'Error' : 'Delete node'}>
            <div className="tree-node-deleting-modal-text">
                {error
                    ? 'It is not possible to delete a node because it has tethered materials'
                    : `Are you sure you want to delete the ${deletedNode?.key} subdivision?`}
            </div>
            <div className="tree-node-deleting-modal-buttons">
                {!error && <Button title="Delete" type={ButtonType.primary} onClick={onDelete} />}
                <Button
                    title={error ? 'OK' : 'Cancel'}
                    type={error ? ButtonType.primary : ButtonType.secondary}
                    onClick={onClose}
                />
            </div>
        </Modal>
    );
};
