import { DEFAULT_TAB, SettingsTabs } from './model';

export function initializeTab(tab: SettingsTabs | undefined): SettingsTabs {
    if (
        tab !== SettingsTabs.Subscriptions &&
        tab !== SettingsTabs.InternetResources &&
        tab !== SettingsTabs.OtherOptions
    ) {
        return DEFAULT_TAB;
    }

    return tab;
}
