import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { keywordsSelector } from '../../redux/selectors/keyword';
import { getKeywords } from '../../redux/actions/keyword';
import { AppDispatch } from '../../redux';
import { TreeNode } from '../tree/treeNode/model';

import { View } from './view';
import { ParentNodeSelectorProps } from './model';

export const ParentNodeSelector = ({ value, onChange, hiddenNodeIds, showEdit }: ParentNodeSelectorProps) => {
    const [showModal, setShowModal] = useState(false);
    const [localValue, setLocalValue] = useState<TreeNode | null>(
        value ? ({ id: value.value, key: value.label } as TreeNode) : null,
    );

    const dispatch: AppDispatch = useDispatch();
    const { data, error, isLoading } = useSelector(keywordsSelector);

    useEffect(() => {
        if (isLoading) return;

        if (!data && !error) {
            dispatch(getKeywords());
        }
    }, [data, dispatch, error, isLoading]);

    const onSelect = () => {
        onChange?.(localValue ? { value: localValue.id, label: localValue.key } : null);
        setShowModal(false);
    };

    return View({
        node: localValue,
        data,
        showModal,
        setLocalValue,
        onSelect,
        setShowModal,
        hiddenNodeIds,
        showEdit,
    });
};
