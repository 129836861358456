const LocalAudioContext = window.AudioContext ?? (window as any).webkitAudioContext ?? null;

export enum AudioServiceState {
    Closed = 'closed',
    Running = 'running',
    Suspended = 'suspended',
}

export class AudioService {
    private workletPath = 'worklet/audio-processor.js';
    private workletName = 'worklet-audio-processor';
    private isInit = false;

    private context: AudioContext;
    private source: MediaStreamAudioSourceNode;
    private mediaStream: MediaStream;
    private worklet: AudioWorkletNode;

    private onaudioprocess?: (buffer: ArrayBuffer) => void;

    public get state(): AudioServiceState {
        switch (this.context?.state) {
            case 'running':
                return AudioServiceState.Running;
            case 'suspended':
                return AudioServiceState.Suspended;
            case 'closed':
                return AudioServiceState.Closed;
        }
    }

    public async start(
        onaudioprocess?: (buffer: ArrayBuffer) => void,
        onstatechange?: (state: AudioServiceState) => void,
    ) {
        if (this.isInit) return;
        if (!LocalAudioContext) {
            throw new Error('Your web browser is old for using audio recording devices.');
        }

        this.onaudioprocess = onaudioprocess;

        await this.initUserMicro();
        this.context = new LocalAudioContext({ latencyHint: 'interactive', sampleRate: 44100 });
        this.context.onstatechange = () => onstatechange?.(this.state);
        this.source = this.context.createMediaStreamSource(this.mediaStream);
        await this.initWorklet();

        this.isInit = true;
    }

    public async resume() {
        if (!this.isInit) return;
        await this.context.resume();
    }

    public async pause() {
        if (!this.isInit) return;
        await this.context.suspend();
    }

    public async stop() {
        if (!this.isInit) return;

        this.source.disconnect(this.worklet);
        this.worklet.disconnect(this.context.destination);
        await this.context.close();

        this.isInit = false;
    }

    private async initUserMicro() {
        try {
            this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
        } catch (e) {
            throw new Error('Microphone not found or you are blocking the device.');
        }
    }

    private async initWorklet() {
        await this.context.audioWorklet.addModule(this.workletPath);

        this.worklet = new AudioWorkletNode(this.context, this.workletName, {
            numberOfInputs: 1,
            numberOfOutputs: 1,
            channelCountMode: 'explicit',
            channelInterpretation: 'speakers',
        });
        this.worklet.port.onmessage = (d) => {
            this.onaudioprocess?.(d.data);
        };

        this.source.connect(this.worklet);
        this.worklet.connect(this.context.destination);
    }
}
