import { AppThunk } from '../../../index';
import { CreateExternalSource, ExternalSource, PatchExternalSource } from '../../../../common/model/externalSource';
import { httpService } from '../../../../common/http';
import { backendRoutes } from '../../../../routes/backend';
import { closeNewResourceModal, setExternalSources } from '../../../slices/settings/internetResources';

export const getExternalSources = (): AppThunk => {
    return async (dispatch) => {
        const response = await httpService.get<ExternalSource[]>(backendRoutes.externalSource);
        dispatch(setExternalSources(response));
    };
};

export const deleteSource = (id: number): AppThunk => {
    return async (dispatch) => {
        await httpService.delete(`${backendRoutes.externalSource}/${id}`);
        dispatch(getExternalSources());
    };
};

export const updateSource = (id: number, data: PatchExternalSource): AppThunk => {
    return async (dispatch) => {
        await httpService.patch<ExternalSource>(`${backendRoutes.externalSource}/${id}`, data);
        dispatch(getExternalSources());
        dispatch(closeNewResourceModal());
    };
};

export const addResource = (resource: CreateExternalSource): AppThunk => {
    return async (dispatch) => {
        await httpService.post(backendRoutes.externalSource, resource);
        dispatch(getExternalSources());
        dispatch(closeNewResourceModal());
    };
};
