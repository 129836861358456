// import { DownloadIcon } from '../../../../components/icons/downloadIcon';
import { EditIcon } from '../../../../components/icons/editIcon';
import { DeleteIcon } from '../../../../components/icons/deleteIcon';
import { CopyIcon } from '../../../../components/icons/copyIcon';

import { ViewArticleProps } from './model';

import './style.scss';

export const View = (props: ViewArticleProps) => {
    const { name, content } = props;
    return (
        <article className="article">
            <div className="article-header">
                <h3 className="article-title">{name}</h3>
                <div className="article-icons">
                    {/*<DownloadIcon className="article-icon" />*/}
                    <EditIcon onClick={props.onSelect} className="article-icon" />
                    <CopyIcon onClick={props.onCopy} className="article-icon" />
                    <DeleteIcon onClick={props.onDelete} className="article-icon" />
                </div>
            </div>

            <p className="article-text">
                {content?.blocks
                    .map((c) => c.text)
                    .join(' ')
                    .slice(0, 256)}
            </p>
            {/*<div className="article-load-more">load more</div>*/}
        </article>
    );
};
