import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { AppDispatch } from '../../redux';
import { getUser } from '../../redux/actions/users';
import { userSelector } from '../../redux/selectors/users';
import { setUserError } from '../../redux/slices/users';

import { View } from './view';

export const User = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { data, error, isLoading } = useSelector(userSelector);

    useEffect(() => {
        if (!id) {
            navigate('/admin/users', { replace: true });
        } else {
            dispatch(getUser(+id));
        }
    }, [dispatch, id, navigate]);
    useEffect(() => {
        if (!error) return;

        toast.error(error);
        dispatch(setUserError(null));
    }, [dispatch, error]);

    return View({
        data,
        isLoading,
        onClose: () => navigate('/admin/users'),
    });
};
