import { FieldValues, useFieldArray, useFormContext } from 'react-hook-form';
import { useState } from 'react';

import { OtherOptionsFormData } from '../form/model';
import { ModalData, ModalState } from '../editModal/model';

import { FIELD_KEY_NAME, FormItemsProps } from './model';
import { View } from './view';

export const FormItems = ({ name, ...props }: FormItemsProps) => {
    const [modalData, setModalData] = useState<ModalData | null>(null);

    const { control } = useFormContext<OtherOptionsFormData>();
    const { fields, remove, append, update } = useFieldArray({
        control,
        name,
        keyName: FIELD_KEY_NAME,
    });

    const onShowModal = (state: ModalState, field?: FieldValues, index?: number) => {
        setModalData({ state, field, index });
    };
    const onCloseModal = () => {
        setModalData(null);
    };
    const onAddUpdate = (newValue: string) => {
        if (!modalData) return;

        switch (modalData.state) {
            case ModalState.New:
                append({ id: 0, name: newValue });
                break;
            case ModalState.Edit:
                if (!modalData.index || !modalData.field) return;
                update(modalData.index, { id: modalData.field.id, name: newValue });
                break;
        }
    };

    return View({
        ...props,
        fields,
        modalData,
        onShowModal,
        onCloseModal,
        onAddUpdate,
        onDelete: remove,
    });
};
