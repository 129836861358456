import { Controller, useFormContext } from 'react-hook-form';

import { Input } from '../input';
import { Select } from '../select';
import { Checkbox } from '../checkbox';

import { FormFieldProps } from './model';

export const FormField = <T extends Record<string, unknown>>({
    type,
    name,
    title,
    rules,
    options,
    multiple,
    className,
    error,
    children,
}: FormFieldProps<T>) => {
    const formContext = useFormContext();
    const fieldError = Boolean(error) ? error : formContext.formState.errors[name as string];
    switch (type) {
        case 'text':
            return (
                <Controller
                    name={name as string}
                    control={formContext.control}
                    rules={rules || undefined}
                    render={({ field }) => (
                        <div className={className} style={{ marginBottom: 16 }}>
                            <Input
                                title={title}
                                {...field}
                                error={(fieldError?.message || fieldError?.type) as string}
                                children={children}
                            />
                        </div>
                    )}
                />
            );
        case 'password':
            return (
                <Controller
                    name={name as string}
                    control={formContext.control}
                    rules={rules || undefined}
                    render={({ field }) => (
                        <div className={className} style={{ marginBottom: 16 }}>
                            <Input
                                type="password"
                                title={title}
                                {...field}
                                error={(fieldError?.message || fieldError?.type) as string}
                            />
                        </div>
                    )}
                />
            );
        case 'select':
            return (
                <Controller
                    name={name as string}
                    control={formContext.control}
                    rules={rules || undefined}
                    render={({ field }) => (
                        <div className={className} style={{ marginBottom: 16 }}>
                            <Select
                                options={options}
                                title={title}
                                multiple={multiple}
                                {...field}
                                error={(error?.message || error?.type) as string}
                            />
                        </div>
                    )}
                />
            );
        case 'checkbox':
            return (
                <Controller
                    name={name as string}
                    control={formContext.control}
                    render={({ field }) => <Checkbox label={title ?? ''} {...field} />}
                />
            );
        case 'search':
            return (
                <Controller
                    name={name as string}
                    control={formContext.control}
                    rules={rules || undefined}
                    render={({ field }) => (
                        <div className={className} style={{ marginBottom: 16 }}>
                            <Input
                                type="search"
                                title={title}
                                {...field}
                                error={(error?.message || error?.type) as string}
                            />
                        </div>
                    )}
                />
            );
        default:
            return null;
    }
};
