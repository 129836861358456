import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '../../components/button';
import { ButtonType } from '../../common/enum/buttonType';
import { FormField } from '../../components/formField';
import { Modal } from '../../components/modal';

import { ViewProps } from './model';
import './style.scss';

export const View = ({ methods, onSubmit, onClose, onForgotPass }: ViewProps) => {
    return (
        <Modal title="Log in" isOpen onClose={onClose}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <FormField title="Login or Email" type="text" name="login" rules={{ required: true }} />
                    <FormField title="Password" type="password" name="password" rules={{ required: true }} />
                    <p className="error-message">{methods.formState.errors.errorBlock?.types?.errorBlock}</p>
                    <div className="login-form-buttons">
                        <div className="login-form-buttons-right">
                            <Button
                                buttonType="submit"
                                onClick={methods.handleSubmit(onSubmit)}
                                title="Enter"
                                type={ButtonType.primary}
                            />
                            <Button onClick={onClose} title="Cancel" type={ButtonType.secondary} />
                        </div>
                        <Button
                            onClick={onForgotPass}
                            title="Forgot password?"
                            type={ButtonType.borderOff}
                            className="login-form-forgot-pass-btn"
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};
