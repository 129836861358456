import { useForm } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AppDispatch } from '../../../../../redux';
import { updateOtherOptions } from '../../../../../redux/actions/settings/otherOptions';
import { OtherOptionName } from '../../../../../shared/common/models/settings';
import { updateCountries } from '../../../../../redux/actions/country';
import { updateDeleteKeywords, updateNoGoKeywords } from '../../../../../redux/actions/dictionaries';

import { OtherOptionsFormData, FormProps } from './model';
import { View } from './view';
import { mapFormItemsToKeywords, mapKeywordsToFormItems } from './utils';

const schema = yup.object().shape({
    [OtherOptionName.SupportEmail]: yup.string().optional().email('Support email must be valid email'),
    [OtherOptionName.LastFoundCount]: yup.number().required(),
    [OtherOptionName.MostPopularCount]: yup.number().required(),
    [OtherOptionName.KeepDays]: yup.number().required(),
    [OtherOptionName.LastRecognizedWordsCount]: yup.number().required(),
});

export const Form = ({ options, regions, deleteKeywords, noGoKeywords }: FormProps) => {
    const dispatch: AppDispatch = useDispatch();
    const form = useForm<OtherOptionsFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = useMemo(() => {
        const submit = async ({ regions, deleteKeywords, noGoKeywords, ...options }: OtherOptionsFormData) => {
            try {
                await Promise.all([
                    dispatch(updateOtherOptions(options)),
                    dispatch(updateDeleteKeywords(mapFormItemsToKeywords(deleteKeywords))),
                    dispatch(updateNoGoKeywords(mapFormItemsToKeywords(noGoKeywords))),
                    dispatch(updateCountries(regions)),
                ]);
            } catch (e: unknown) {
                toast.error('Failed to save other options');
            }
        };

        return form.handleSubmit(submit);
    }, [dispatch, form]);

    useEffect(() => {
        form.reset({
            ...options,
            regions,
            deleteKeywords: mapKeywordsToFormItems(deleteKeywords),
            noGoKeywords: mapKeywordsToFormItems(noGoKeywords),
        });
    }, [form, deleteKeywords, options, regions, noGoKeywords]);

    return View({ form, onSubmit });
};
