import BootstrapTable from 'react-bootstrap-table-next';
import cn from 'classnames';

import { ViewProps } from './model';
import './style.scss';

export const View = <T extends object>(props: ViewProps<T>) => {
    const { columns, data, keyField, className } = props;

    return (
        <BootstrapTable<T>
            rowClasses="data-table-row"
            bodyClasses="data-table-body"
            headerClasses="data-table-header"
            classes={cn('data-table', className)}
            columns={columns}
            data={data}
            keyField={keyField.toString()}
        />
    );
};
