import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { CreateExternalSource, PatchExternalSource } from '../../../../common/model/externalSource';
import { AppDispatch } from '../../../../redux';
import { addResource, updateSource } from '../../../../redux/actions/settings/internetResources';
import { closeNewResourceModal } from '../../../../redux/slices/settings/internetResources';
import { editableSourceSelector } from '../../../../redux/selectors/settings/internetResources';

import { createExternalSourceSchema } from './validation';
import { View } from './view';

export const SourceModal = () => {
    const dispatch: AppDispatch = useDispatch();
    const editableSource = useSelector(editableSourceSelector);
    const methods = useForm<CreateExternalSource>({
        defaultValues: {
            url: editableSource?.url,
            title: editableSource?.title,
            parameters: editableSource?.parameters,
        },
        resolver: yupResolver(createExternalSourceSchema),
    });
    const { fields, append, remove } = useFieldArray<CreateExternalSource>({
        control: methods.control,
        name: 'parameters',
        rules: {
            minLength: 1,
            required: true,
        },
    });

    const onSubmit = useCallback(
        (data: PatchExternalSource | CreateExternalSource) => {
            editableSource
                ? dispatch(updateSource(editableSource.id, data as PatchExternalSource))
                : dispatch(addResource(data));
        },
        [dispatch, editableSource],
    );

    const closeNewResourceModalCallback = useCallback(() => {
        dispatch(closeNewResourceModal());
    }, [dispatch]);

    return (
        <View
            methods={methods}
            isOpen={true}
            onClose={closeNewResourceModalCallback}
            parameters={fields}
            removeParameter={remove}
            addParameter={() =>
                append({
                    key: '',
                    value: '',
                })
            }
            onSubmit={onSubmit}
        />
    );
};
