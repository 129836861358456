import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoadableData } from '../../../common/types';
import { Country } from '../../../common/model/country';

export interface CountryState {
    countries: LoadableData<Country[]>;
}

const initialState: CountryState = {
    countries: {
        data: null,
        error: null,
        isLoading: false,
    },
};

export const countrySlice = createSlice({
    name: 'countrySlice',
    initialState,
    reducers: {
        setCountries: (state, { payload }: PayloadAction<Country[] | null>) => {
            state.countries.data = payload;
        },
        setCountriesLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.countries.isLoading = payload;
        },
        setCountriesError: (state, { payload }: PayloadAction<string | null>) => {
            state.countries.error = payload;
        },
        reset: () => initialState,
    },
});

export const { setCountries, setCountriesLoading, setCountriesError, reset } = countrySlice.actions;

export default countrySlice.reducer;
