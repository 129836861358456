import { AddItemNode } from '../../pages/database/addItemNode';

import { TreeNode } from './treeNode';
import { TreeConfig, ViewProps } from './model';
import { EditingModal } from './editingModal';
import { DeletingModal } from './deletingModal';

import './style.scss';

const TREE_CONFIG: TreeConfig = {
    addItemsLabelsMap: {
        1: 'New body system',
    },
    rootAddItemLabel: 'New modality',
    defaultAddItemLabel: 'Add item',
};

export const View = ({
    items,
    selectedNode,
    editableNode,
    unselectable,
    hiddenNodeIds,
    deletedNode,
    onSelectNode,
    onCreateEditNode,
    onDeleteNode,
    onCloseModal,
    onCloseDeletingModal,
    creatingMode,
}: ViewProps) => {
    return (
        <>
            <div className="tree">
                {items.map((node) => {
                    if (hiddenNodeIds.includes(node.id)) {
                        return null;
                    }

                    return (
                        <TreeNode
                            key={node.id}
                            parentNode={undefined}
                            node={node}
                            selectedNode={selectedNode}
                            onSelect={onSelectNode}
                            onCreateEditNode={onCreateEditNode}
                            onDelete={onDeleteNode}
                            config={TREE_CONFIG}
                            mode={creatingMode}
                            unselectable={unselectable}
                            hiddenNodeIds={hiddenNodeIds}
                        />
                    );
                })}
                <AddItemNode
                    mode={creatingMode}
                    text={TREE_CONFIG.rootAddItemLabel}
                    onClick={() => onCreateEditNode({ id: 'new', level: 1, key: '' })}
                />
            </div>
            <EditingModal editableNode={editableNode} onClose={onCloseModal} onDeleteNode={onDeleteNode} />
            <DeletingModal deletedNode={deletedNode} onClose={onCloseDeletingModal} />
        </>
    );
};
