import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AppDispatch, RootState } from '../../redux';
import { getByIdDocuments } from '../../redux/actions/documents';
import { documentsByIdsSelector, documentsCount } from '../../redux/selectors/documents';
import { Pagination } from '../pagination';
import { selectedLastItemSelector } from '../../redux/selectors/keyword';
import { Button } from '../button';
import { ButtonType } from '../../common/enum/buttonType';
import { addItemToWorkzoneTreeSelectedItemKeys, setWorkzoneLastItem } from '../../redux/slices/keyword';
import { ArrowBackIcon } from '../icons/arrowBack';
import { reset } from '../../redux/slices/documents';
import { Routes } from '../../routes/routes';
import { mainRecognizedKeywordIdSelector } from '../../redux/selectors/recognizeText';

import { ItemCatalog } from './ItemCatalog';

import './style.scss';

const count = 6;

export const CatalogResult = () => {
    const navigate = useNavigate();
    const recognizedId = useSelector(mainRecognizedKeywordIdSelector);
    const parents = useSelector((state: RootState) => state.keyword.workzoneTreeSelectedItemKeys);
    const selectLastItem = useSelector(selectedLastItemSelector);

    const dispatch: AppDispatch = useDispatch();
    const [page, setPage] = useState(0);

    const backToSearch = () => {
        dispatch(setWorkzoneLastItem(null));
    };

    useEffect(() => {
        if (!selectLastItem) {
            if (!recognizedId) {
                dispatch(reset());
                dispatch(addItemToWorkzoneTreeSelectedItemKeys([]));
            } else {
                dispatch(getByIdDocuments(page + 1, count, recognizedId, true));
            }
        }
    }, [dispatch, recognizedId, page, selectLastItem]);

    const documents = useSelector(documentsByIdsSelector);
    const countDocuments = useSelector(documentsCount);

    useEffect(() => {
        setPage(0);
    }, [selectLastItem]);

    useEffect(() => {
        if (selectLastItem?.id) {
            dispatch(getByIdDocuments(page + 1, count, selectLastItem.id, true));
        }
    }, [dispatch, page, selectLastItem?.id]);

    return (
        <div>
            {selectLastItem ? (
                <div className="header-resultCatalog">
                    <Button
                        icon={<ArrowBackIcon />}
                        onClick={backToSearch}
                        title="Back to current search"
                        type={ButtonType.borderOff}
                        className="back-to-search"
                    />
                    <div>
                        <h1>{selectLastItem?.key}</h1>
                        {parents?.length > 1 ? (
                            <span className="parents-title">{parents.slice(0, -1).join(' / ')}</span>
                        ) : null}
                    </div>
                </div>
            ) : null}
            {!selectLastItem ? (
                <div className="catalog-results">
                    {documents.length ? <div>{countDocuments} results found</div> : null}
                </div>
            ) : null}
            <div>
                {documents?.length
                    ? documents.map((document, index) => {
                          return (
                              <ItemCatalog
                                  onLoadMoreClick={() => navigate(Routes.documentDetails(document.id))}
                                  isExpanded={index === 0 && documents?.length < 3}
                                  key={document.id}
                                  {...document}
                              />
                          );
                      })
                    : null}
                {documents?.length ? (
                    <Pagination
                        className="catalog-articles-pagination"
                        pagesCount={Math.ceil(countDocuments / count)}
                        currentPage={page}
                        onPageChange={(i: any) => setPage(i.selected)}
                    />
                ) : null}
            </div>
        </div>
    );
};
