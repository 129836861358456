import { useNavigate } from 'react-router';

import { Modal } from '../../components/modal';
import { Routes } from '../../routes/routes';

import './style.scss';

export const ProfileSuccessPayment = () => {
    const navigate = useNavigate();
    const onClose = () => {
        navigate(Routes.profile);
    };

    return (
        <Modal className="payment-success-modal" isOpen onClose={onClose}>
            <div className="payment-success-container">
                <span>Your payment was successful</span>
            </div>
        </Modal>
    );
};
