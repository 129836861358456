import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { useStreamRecognition } from '../../hooks/useStreamRecognition';
import {
    addHistory,
    addRecognizeSessionId,
    addText,
    addTiming,
    reset,
    setRecognizeResult,
} from '../../redux/slices/recognizeText';
import { recognizeCurrentTextSelector, recognizeSessionIdSelector } from '../../redux/selectors/recognizeText';
import { AudioRecognizeResponseMessage } from '../../shared/common/models/recognize';
import { selectedLastItemSelector } from '../../redux/selectors/keyword';
import { addItemToWorkzoneTreeSelectedItemKeys, setWorkzoneLastItem } from '../../redux/slices/keyword';
import { modifyPhrase } from '../../shared/common/utils/modifyPhrase';
import { processDeleteKeywordsSelector, processNoGoKeywordsSelector } from '../../redux/selectors/dictionaries';
import { getDeleteKeywords } from '../../redux/actions/dictionaries';
import { AppDispatch } from '../../redux';
import { recognize } from '../../redux/actions/recognizeText';
import { authenticatedUserSelector } from '../../redux/selectors/auth';
import { useStreamRecognitionHotkeys } from '../../hooks/useStreamRecognitionHotkeys';

import { View } from './view';
import { AudioServiceState } from './services/audioService';
import { PhraseFormData } from './model';

export const Speaker = () => {
    const dispatch: AppDispatch = useDispatch();
    const text = useSelector(recognizeCurrentTextSelector);
    const hideText = Boolean(useSelector(selectedLastItemSelector));
    const [audioState, setAudioState] = useState<AudioServiceState | null>(null);
    const deleteKeywords = useSelector(processDeleteKeywordsSelector);
    const noGoKeywords = useSelector(processNoGoKeywordsSelector);
    const recognizeSessionId = useSelector(recognizeSessionIdSelector);
    const user = useSelector(authenticatedUserSelector);

    useEffect(() => {
        dispatch(getDeleteKeywords());
    }, [dispatch]);

    const debounced = useDebouncedCallback(
        async (value, recognizeSessionId) => {
            dispatch(recognize(value, recognizeSessionId));
        },
        1000,
        { maxWait: 2000 },
    );

    useEffect(() => {
        if (!text) {
            return;
        }

        const modifiedPhrase = modifyPhrase(
            text,
            deleteKeywords.map((d) => d.key),
            noGoKeywords.map((d) => d.key),
        );
        debounced(modifiedPhrase, recognizeSessionId);
    }, [text, debounced, deleteKeywords, noGoKeywords, recognizeSessionId]);

    const { start, stop, resume, pause } = useStreamRecognition<AudioRecognizeResponseMessage>(
        ({ transcriptions, isFinal, useTime }) => {
            if (isFinal) {
                dispatch(
                    addHistory(
                        modifyPhrase(
                            transcriptions[0],
                            deleteKeywords.map((d) => d.key),
                            noGoKeywords.map((d) => d.key),
                        ),
                    ),
                );
                dispatch(addText(''));
            } else {
                dispatch(
                    addText(
                        modifyPhrase(
                            transcriptions.join(''),
                            deleteKeywords.map((d) => d.key),
                            noGoKeywords.map((d) => d.key),
                        ),
                    ),
                );
            }

            dispatch(addTiming(useTime));
        },
        async (e: any) => {
            console.error(e.error);
            if (e.error === 'restarting') return;
            toast.error(e.error);
            await stop();
            setAudioState(null);
        },
        (state) => setAudioState(state),
    );

    const resetWorkzoneItems = () => {
        dispatch(addItemToWorkzoneTreeSelectedItemKeys([]));
        dispatch(setWorkzoneLastItem(null));
    };

    const onStart = async () => {
        resetWorkzoneItems();
        await start();
        dispatch(addRecognizeSessionId(`${user.id}-${Date.now()}`));
        speakerForm.reset();
    };
    const onStop = async () => {
        await stop();
        dispatch(reset());
        dispatch(
            setRecognizeResult({
                lastKeyword: undefined,
                recognizedKeywords: [],
            }),
        );
        speakerForm.reset();
    };
    const onPause = async () => {
        await pause();
    };
    const onResume = async () => {
        resetWorkzoneItems();
        await resume();
    };

    useStreamRecognitionHotkeys({
        state: audioState,
        onStart,
        onStop,
        onPause,
        onResume,
    });

    const speakerForm = useForm<PhraseFormData>({
        defaultValues: {
            phrase: text,
        },
    });
    const onSubmit = (data: PhraseFormData) => {
        dispatch(
            addHistory(
                modifyPhrase(
                    data.phrase,
                    deleteKeywords.map((d) => d.key),
                    noGoKeywords.map((d) => d.key),
                ),
            ),
        );
        dispatch(addRecognizeSessionId(`${user.id}-${Date.now()}`));
        dispatch(addText(''));
    };

    return View({
        text,
        audioState,
        hideText,
        onStart,
        onStop,
        onPause,
        onResume,
        speakerForm,
        onSubmit,
    });
};
