import React from 'react';
import { Outlet } from 'react-router-dom';

import Logo from '../../assets/2NDLogo.png';
import { Description } from '../../components/description';
import { Advantage } from '../../components/advantages';

export const View = () => {
    return (
        <div>
            <Outlet />
            <Description
                title={`2nd Opinion- \nReal-time Clinical Decision Support`}
                text={`Curated, voice-activated database by Radiologists for Radiologists to save you time and increase your accuracy.`}
                showButton
                lists={['Sodales et. Risus et.', 'Nec faucibus.', 'Nunc interdum dictum.']}
                image={Logo}
            />
            <Advantage />
        </div>
    );
};
