import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';

import { TabsConfig } from '../../components/tabs/useTabs';
import { Routes } from '../../routes/routes';
import { useAdminValidation } from '../../hooks/useAdminValidation';

import { DEFAULT_TAB, SettingsTabs } from './model';
import { View } from './view';
import { initializeTab } from './services';

export const Settings = () => {
    const navigate = useNavigate();
    const { tab: initialTab } = useParams<{ tab: SettingsTabs }>();

    const tabsConfig: TabsConfig = useMemo(
        () => ({
            onTabChanged: (tabIndex) => {
                navigate(`${Routes.settings}/${tabIndex}`);
            },
        }),
        [navigate],
    );

    const isAdmin = useAdminValidation();

    useEffect(() => {
        if (isAdmin) {
            const tab = initializeTab(initialTab);
            navigate(`${Routes.settings}/${tab}`);
        }
    }, [initialTab, navigate, isAdmin]);

    return View({
        tabsConfig,
        initialTab: initialTab ?? DEFAULT_TAB,
    });
};
