export const backendRoutes = {
    login: 'api/auth/login',
    getRecoveryUrl: 'api/auth/create-recovery-url',
    confirmPasswordRecovery: 'api/auth/confirm-password-recovery',
    getUserInfo: 'api/user',
    getCountries: 'api/country',
    updateCountries: 'api/country',
    changeRole: 'api/admin/role-changing',
    changeBlocking: 'api/admin/user-blocking',
    signUp: 'api/auth/registrate',
    confirmRegistration: 'api/auth/confirm-registration',
    getUsers: 'api/admin/users',
    // keyword
    getKeywords: 'api/knowledge/list',
    createKeyword: 'api/knowledge',
    updateKeyword: 'api/knowledge',
    deleteKeyword: 'api/knowledge',

    getDocumentsForCategory: `api/knowledge/documents/:categoryId`,
    addDocument: 'api/knowledge/document',
    updateDocument: 'api/knowledge/document',
    getDocument: (id: number) => `api/knowledge/document/${id}`,
    searchDocuments: `api/knowledge/documents`,
    deleteDocumentFromKeyword: 'api/knowledge/document/:id',
    changePassword: 'api/user/change-password',
    changeCountry: 'api/user/change-country',
    externalSource: 'api/external-source',

    getSubscriptions: 'api/subscribe',
    addSubscription: 'api/subscribe',
    getSubscription: (id: number) => `api/subscribe/${id}`,
    deleteSubscription: (id: number) => `api/subscribe/${id}`,
    updateSubscription: (id: number) => `api/subscribe/${id}`,

    getMySubscription: 'api/user-subscribe',
    getMuSubscriptionHistory: 'api/user-subscribe/history',
    getUserSubscribe: (id: number) => `api/user-subscribe/${id}`,
    addSubcription: 'api/user-subscribe',

    getDeleteKeywords: 'api/knowledge/delete_keywords',
    getNoGoKeywords: 'api/knowledge/nogo_keywords',
    getStatistic: 'api/statistic',
    updateDeleteKeywords: 'api/knowledge/delete_keywords',
    updateNoGoKeywords: 'api/knowledge/nogo_keywords',
    getOtherOptions: 'api/other-options',
    updateOtherOptions: 'api/other-options',
    getSupportOptions: 'api/other-options/support-options',
    getListResultRecognize: 'api/recognize/list',

    createPayment: 'api/payment/create',

    resources: 'api/resources',
    resourceByGuid: (guid: string) => `${process.env.REACT_APP_BACKEND}/api/resources/${guid}`,
};
