import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';

import { ColumnProps } from '../../../components/dataTable/model';
import { DeleteIcon } from '../../../components/icons/deleteIcon';
import { AppDispatch } from '../../../redux';
import { EditIcon } from '../../../components/icons/editIcon';
import {
    isSubscriptionModalOpenSelector,
    subscriptionItemsSelector,
} from '../../../redux/selectors/settings/subscriptions';
import { openNewSubscriptionModal, setEditSubscriptionId } from '../../../redux/slices/settings/subscriptions';
import { deleteSubscription, getSubscriptions } from '../../../redux/actions/settings/subscriptions';

import { View } from './view';
import { durationOptions } from './subscriptionModal/const';

export const Subscription = () => {
    const dispatch: AppDispatch = useDispatch();
    const data = useSelector(subscriptionItemsSelector);
    const isSubscriptionModalOpen = useSelector(isSubscriptionModalOpenSelector);

    const columns = useMemo<ColumnProps<any>[]>(() => {
        return [
            {
                dataField: 'id',
                columnText: '',
                width: 40,
                align: 'center',
            },
            {
                dataField: 'name',
                columnText: 'Title',
                width: 300,
                align: 'left',
            },
            {
                dataField: 'duration',
                columnText: 'Duration',
                align: 'left',
                renderer: (data) => {
                    return durationOptions.find((opt) => opt.value === data.duration)?.label;
                },
            },
            {
                dataField: 'price',
                columnText: 'Price',
                align: 'left',
            },
            {
                dataField: 'currency',
                columnText: 'Currency',
                align: 'left',
            },
            {
                dataField: 'isTrial',
                columnText: 'Trial period',
                align: 'left',
                renderer: (data) => !!data.isTrial,
            },
            {
                dataField: 'isDeleted',
                columnText: 'Active',
                align: 'left',
                renderer: (data) => !data.isDeleted,
            },
            {
                dataField: 'delete',
                columnText: 'Delete',
                align: 'center',
                headerAlign: 'center',
                width: 140,
                renderer: (data) => (
                    <div className="icons">
                        <EditIcon onClick={() => dispatch(setEditSubscriptionId(data))} />
                        <DeleteIcon onClick={() => dispatch(deleteSubscription(data.id))} />
                    </div>
                ),
            },
        ];
    }, [dispatch]);

    const openNewResourceModalCallback = useCallback(() => {
        dispatch(openNewSubscriptionModal());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSubscriptions());
    }, [dispatch]);

    return View({
        isSubscriptionModalOpen,
        data,
        columns,
        keyField: 'id',
        openNewSubscriptionModal: openNewResourceModalCallback,
    });
};
