import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '../../components/button';
import { ButtonType } from '../../common/enum/buttonType';
import { FormField } from '../../components/formField';
import { Modal } from '../../components/modal';

import { ViewProps } from './model';
import './style.scss';

export const View = ({ form, onSubmit, onClose, isSuccess, hasCode }: ViewProps) => {
    return (
        <>
            <Modal title="Forgot your password?" isOpen={!isSuccess} onClose={onClose}>
                {!hasCode && (
                    <p className="recovery-password-description">
                        Fill in the field below and we'll send you password reset instructions.
                    </p>
                )}
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        {hasCode ? (
                            <>
                                <FormField
                                    title="Password"
                                    type="password"
                                    name="password"
                                    rules={{ required: true }}
                                />
                                <FormField
                                    title="Password confirmation"
                                    type="password"
                                    name="passwordConfirmation"
                                    rules={{ required: true }}
                                />
                            </>
                        ) : (
                            <FormField title="Login or Email" type="text" name="login" rules={{ required: true }} />
                        )}
                        <Button
                            buttonType="submit"
                            title={hasCode ? 'Change password' : 'Reset password'}
                            type={ButtonType.primary}
                            className="recovery-password-submit-button"
                            disabled={form.formState.isSubmitting || isSuccess}
                        />
                    </form>
                </FormProvider>
            </Modal>
            <Modal title="Password recovery" isOpen={isSuccess} onClose={onClose}>
                <p className="recovery-password-description">Password recovery link has been sent to your email.</p>
                <Button
                    onClick={onClose}
                    title="Done"
                    type={ButtonType.primary}
                    className="recovery-password-submit-button"
                />
            </Modal>
        </>
    );
};
