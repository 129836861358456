import { AppThunk } from '../../index';
import { setCountries, setCountriesError, setCountriesLoading } from '../../slices/country';
import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { Country } from '../../../common/model/country';

export const getCountries = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setCountriesLoading(true));
            dispatch(setCountriesError(null));

            const countries = await httpService.get(backendRoutes.getCountries);
            dispatch(setCountries(countries));
        } catch (e) {
            dispatch(setCountries(null));
            dispatch(setCountriesError('Failed to get countries'));
        } finally {
            dispatch(setCountriesLoading(false));
        }
    };
};

export const updateCountries = (countries: Country[]): AppThunk => {
    return async (dispatch) => {
        await httpService.post(backendRoutes.updateCountries, countries);
        dispatch(setCountries(countries));
    };
};
