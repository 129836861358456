import { intervalToDuration } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { ButtonType } from '../../common/enum/buttonType';
import { httpService } from '../../common/http';
import { RootState } from '../../redux';
import { backendRoutes } from '../../routes/backend';
import { Routes } from '../../routes/routes';
import { SubscriptionUserViewModel } from '../../shared/common/models/subscription';
import { Button } from '../button';

import { PaymentCardProps } from './model';

import './style.scss';

export const PaymentCard = ({ isAdminView, userId }: PaymentCardProps) => {
    const navigate = useNavigate();

    const [userSubscription, setUserSubscription] = useState<SubscriptionUserViewModel | null>(null);
    const currentUserSubscription = useSelector((state: RootState) => state.userSubscription.subscription);

    useEffect(() => {
        if (!isAdminView) {
            setUserSubscription(currentUserSubscription);
        }
        httpService.get(backendRoutes.getUserSubscribe(userId)).then((sub) => setUserSubscription(sub));
    }, [isAdminView, userId, currentUserSubscription]);

    const expiredInterval = intervalToDuration({
        start: new Date(),
        end: userSubscription ? new Date(userSubscription?.expiredAt) : new Date(),
    });

    const expiredIntervalString = `${expiredInterval.years ? `${expiredInterval.years} years ` : ''}${
        expiredInterval.months ? `${expiredInterval.months} months ` : ''
    }${expiredInterval.days ? `${expiredInterval.days} days ` : ''}${
        expiredInterval.hours ? `${expiredInterval.hours} hours ` : ''
    }${expiredInterval.minutes ? `${expiredInterval.minutes} minutes ` : ''}`;
    return (
        <>
            <div className="payment-card">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 className="title">Current plan</h2>
                    <h4 className="sub-title">{userSubscription ? userSubscription.subscribe.name : 'NO PLAN'}</h4>
                    <span className="info-title end">{userSubscription ? `ends in ${expiredIntervalString}` : ''}</span>
                    {!isAdminView && userSubscription ? (
                        <span className="info-title" style={{ marginTop: 29 }}>
                            Your next payment is {userSubscription.subscribe.price}{' '}
                            {userSubscription.subscribe.currency}
                        </span>
                    ) : null}
                </div>
                {!isAdminView ? (
                    <>
                        <div style={{ height: 16 }} />
                        <Button
                            title="VIEW PAYMENT HISTORY"
                            onClick={() => navigate(Routes.profilePaymentHistory)}
                            type={ButtonType.secondary}
                            className="buttons"
                        />
                        <div style={{ height: 16 }} />
                        <Button
                            title="UPDATE YOUR PLAN"
                            onClick={() => navigate(Routes.profileSubscriptionUpdate)}
                            type={ButtonType.primary}
                            className="buttons"
                        />
                    </>
                ) : null}
            </div>
        </>
    );
};
