import { ReactComponent as DeleteSvg } from '../../assets/delete.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const DeleteIcon = (props: IconProps) => {
    return (
        <IconWrapper {...props}>
            <DeleteSvg />
        </IconWrapper>
    );
};
