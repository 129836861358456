import React from 'react';

import { Form } from './components/form';
// import { Currencies } from './components/currencies';
import { ViewProps } from './model';

import './style.scss';

export const View = ({ isLoading, ...props }: ViewProps) => {
    return (
        <div className="other-options">
            <section className="form-wrapper">
                {isLoading ? <div className="other-options-loading">Loading...</div> : <Form {...props} />}
            </section>
            {/*<Currencies />*/}
        </div>
    );
};
