import './style.scss';

import { Modal } from '../../components/modal';

import { ViewProps } from './model';
import { Form } from './components/form';

export const View = ({ isNew, onClose, document }: ViewProps) => {
    return (
        <Modal
            isRightSide
            isOpen
            onClose={onClose}
            title={isNew ? 'New article' : 'Edit the article'}
            className="article-modal"
        >
            <Form onClose={onClose} initialValue={document} />
        </Modal>
    );
};
