import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import './style.scss';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FormProvider, useForm } from 'react-hook-form';
import { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router';

import { ReactComponent as Block } from '../../assets/table/lockClosed.svg';
import { ReactComponent as Online } from '../../assets/table/online.svg';
import { ReactComponent as Active } from '../../assets/table/checkActive.svg';
import { ReactComponent as Disable } from '../../assets/table/checkDisable.svg';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import { FormField } from '../formField';
import { UserTypes } from '../../common/enum/userTypes';
import { useCountries } from '../../hooks/useCountries';

import { Page, PageListRendererProps, TableProps } from './model';

const columns = (onChange: (userId: number) => void) => [
    {
        dataField: 'isBlocked',
        text: '',
        headerStyle: () => {
            return { width: '40px' };
        },
        formatter: (cell: boolean, row: { isBlocked: boolean }) => {
            return <div>{row.isBlocked ? <Block /> : <Online />}</div>;
        },
    },
    {
        dataField: 'id',
        text: '',
        headerStyle: () => {
            return { width: '40px' };
        },
    },
    {
        dataField: 'login',
        text: 'Login',
        sort: true,
    },
    {
        dataField: 'email',
        text: 'E-mail',
        sort: true,
    },
    {
        dataField: 'country',
        text: 'Region',
        formatter: (cell: boolean, row: { country: { id: number; name: string } }) => {
            return <div>{row?.country?.name}</div>;
        },
    },
    {
        dataField: 'type',
        text: 'Administrator',
        formatter: (cell: boolean, row: { type: UserTypes }) => {
            return <div>{row.type === UserTypes.admin ? <Active /> : <Disable />}</div>;
        },
    },
    {
        dataField: 'edit',
        key: 'edit',
        text: 'Rules',
        formatter: (cell: boolean, row: { id: number }) => {
            return (
                <div>
                    <Edit className="users-table-rules-icon" onClick={() => onChange(row.id)} />
                </div>
            );
        },
    },
];

export const TableUsers = (props: TableProps) => {
    const { users } = props;
    const navigate = useNavigate();

    const countries = useCountries();
    const onChange = (id: number) => {
        navigate(`/admin/users/${id}`);
    };

    const countriesOptions = useMemo(() => {
        return countries.map(({ id, name }) => ({ label: name, value: id }));
    }, [countries]);

    const pageListRenderer = ({ pages, onPageChange }: PageListRendererProps) => {
        if (pages.length < 2) {
            return <div />;
        }
        return (
            <div className="container-page-list-renderer">
                {pages.map((p: Page) => (
                    <button className="btnCustom" onClick={() => onPageChange(p.page)}>
                        {p.page}
                    </button>
                ))}
            </div>
        );
    };

    const options = {
        pageListRenderer,
    };

    const form = useForm();

    const blocked = form.watch('isBlocked');
    const administrator = form.watch('administrator');
    const login = form.watch('login');
    const email = form.watch('email');
    const region = form.watch('region');

    const renderData = useMemo(() => {
        if (!blocked && !administrator && !login && !email && !region) {
            return users;
        }
        const filter = users.filter((val) => {
            let keep = true;
            if (blocked) {
                keep = val.isBlocked && keep;
            }
            if (administrator) {
                keep = val.type === UserTypes.admin && keep;
            }
            if (login) {
                keep = val.login.toLowerCase().includes(login.toLowerCase()) && keep;
            }
            if (email) {
                keep = val.email.toLowerCase().includes(email.toLowerCase()) && keep;
            }
            if (region) {
                keep = val.country.name === region.label && keep;
            }
            return keep;
        });
        return filter;
    }, [blocked, administrator, login, email, region, users]);

    return (
        <div className="container-table">
            <FormProvider {...form}>
                <div className="search-group">
                    <FormField name="login" type="search" title="login" />
                    <FormField name="email" type="search" title="E-mail" />
                    <FormField options={countriesOptions} title="Region" type="select" name="region" />
                </div>
                <div className="check-box-groups">
                    <div className="user-info-block-checkbox">
                        <FormField name="administrator" type="checkbox" title="Administrator only" />
                    </div>
                    <div className="user-info-block-checkbox">
                        <FormField name="isBlocked" type="checkbox" title="Show blocked users" />
                    </div>
                </div>
            </FormProvider>
            <BootstrapTable
                bootstrap4
                condensed
                hover
                classes="tableClass"
                bordered={false}
                keyField="id"
                data={renderData}
                columns={columns(onChange)}
                headerClasses="headerClass"
                pagination={paginationFactory(options)}
            />
            <Outlet />
        </div>
    );
};
