import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../redux';
import { getSupportOptions } from '../../../redux/actions/settings/otherOptions';
import { supportOptionsSelector } from '../../../redux/selectors/settings/otherOptions';

import { SupportItem } from './itemSupport';
import './style.scss';

export const Support = () => {
    const dispatch: AppDispatch = useDispatch();

    const supportOptions = useSelector(supportOptionsSelector);

    useEffect(() => {
        dispatch(getSupportOptions());
    }, [dispatch]);

    return (
        <div className="support-container">
            <div className="support-content">
                <h1>Support</h1>
                <div className="support-contacts">
                    <SupportItem type="phone" text={supportOptions.data?.support_phone ?? ''} />
                    <SupportItem type="mail" text={supportOptions.data?.support_email ?? ''} />
                </div>
            </div>
        </div>
    );
};
