import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KeywordDocument } from '../../../common/model/database';

export interface documentsState {
    documents: KeywordDocument[];
    documentsCount: number;
}

const initialState: documentsState = {
    documents: [],
    documentsCount: 0,
};

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setDocuments: (state, { payload }: PayloadAction<KeywordDocument[]>) => {
            state.documents = payload;
        },
        setDocumentsCount: (state, { payload }: PayloadAction<number>) => {
            state.documentsCount = payload;
        },
        reset: () => initialState,
    },
});

export const { setDocuments, setDocumentsCount, reset } = documentsSlice.actions;

export default documentsSlice.reducer;
