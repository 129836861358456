import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../../common/model/user';
import { LoadableData } from '../../../common/types';

export interface UsersState {
    users: User[];
    user: LoadableData<User>;
}

const initialState: UsersState = {
    users: [],
    user: {
        data: null,
        error: null,
        isLoading: false,
    },
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        setUsers: (state, { payload }: PayloadAction<UsersState['users']>) => {
            state.users = payload;
        },
        setUser: (state, { payload }: PayloadAction<User | null>) => {
            state.user.data = payload;
        },
        setUserLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.user.isLoading = payload;
        },
        setUserError: (state, { payload }: PayloadAction<string | null>) => {
            state.user.error = payload;
        },
        reset: () => initialState,
    },
});

export const { setUsers, setUser, setUserLoading, setUserError, reset } = userSlice.actions;

export default userSlice.reducer;
