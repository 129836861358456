import useCollapse from 'react-collapsed';

import { ReactComponent as ArrowSection } from '../../assets/arrow-section.svg';

import { Props } from './model';

import './style.scss';

export const Collapsible = (props: Props) => {
    const { defaultExpanded = false, collapsedHeight = 0, title, children } = props;

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded, collapsedHeight });

    return (
        <div className="collapsible">
            <div className="collapsible-header" {...getToggleProps()}>
                <div className="collapsible-title">{title}</div>
                <div className="collapsible-icon">
                    <ArrowSection style={{ transform: `rotate(${isExpanded ? 180 : 0}deg)` }} />
                </div>
            </div>
            <div {...getCollapseProps()}>
                <div className="collapsible-content">{children}</div>
            </div>
        </div>
    );
};
