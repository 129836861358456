import { useCallback, useEffect, useState } from 'react';

import { TabKey } from './tabItem/model';

export interface TabsConfig {
    onTabChanged: (tabIndex: TabKey) => void;
}

export interface UseTabsProps {
    initialIndex?: TabKey;
    config?: TabsConfig;
}

export function useTabs(props: UseTabsProps) {
    const { initialIndex = 0, config } = props;
    const [currentTabIndex, setCurrentTabIndex] = useState(initialIndex);

    const onTabItemClick = useCallback(
        (value: TabKey) => {
            setCurrentTabIndex(value);
            config?.onTabChanged && config.onTabChanged(value);
        },
        [config],
    );

    useEffect(() => {
        setCurrentTabIndex(initialIndex);
    }, [initialIndex]);

    return {
        onTabItemClick,
        currentTabIndex,
    };
}
