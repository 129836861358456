import { useEffect } from 'react';

import { AudioServiceState } from '../../components/speaker/services/audioService';

export interface UseStreamRecognitionHotkeysProps {
    state: AudioServiceState | null;
    onStart: () => void | Promise<void>;
    onPause: () => void | Promise<void>;
    onResume: () => void | Promise<void>;
    onStop: () => void | Promise<void>;
}

export const useStreamRecognitionHotkeys = ({
    state,
    onStart,
    onStop,
    onPause,
    onResume,
}: UseStreamRecognitionHotkeysProps): void => {
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.code === 'F6') {
                event.preventDefault();
            }
        };
        const onKeyUp = (event: KeyboardEvent) => {
            if (event.code !== 'F6') return;

            if (event.altKey) {
                onStop();
                return;
            }

            switch (state) {
                case null:
                case AudioServiceState.Closed:
                    onStart();
                    break;
                case AudioServiceState.Running:
                    onPause();
                    break;
                case AudioServiceState.Suspended:
                    onResume();
                    break;
            }
        };

        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [state, onPause, onResume, onStart, onStop]);

    return;
};
