import { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../redux';
import { countriesSelector } from '../../../redux/selectors/country';
import { deleteKeywordsSelector, noGoKeywordsSelector } from '../../../redux/selectors/dictionaries';
import { getDeleteKeywords, getNoGoKeywords } from '../../../redux/actions/dictionaries';
import { getCountries } from '../../../redux/actions/country';
import { otherOptionsSelector } from '../../../redux/selectors/settings/otherOptions';
import { getOtherOptions } from '../../../redux/actions/settings/otherOptions';
import { OtherOptions as IOtherOptions } from '../../../shared/common/models/settings';

import { View } from './view';

export const OtherOptions = () => {
    const dispatch: AppDispatch = useDispatch();

    const keywords = useSelector(deleteKeywordsSelector);
    const noGoKeywords = useSelector(noGoKeywordsSelector);
    const regions = useSelector(countriesSelector);
    const options = useSelector(otherOptionsSelector);

    useLayoutEffect(() => {
        dispatch(getOtherOptions());
        dispatch(getDeleteKeywords());
        dispatch(getNoGoKeywords());
        dispatch(getCountries());
    }, [dispatch]);

    return View({
        isLoading: regions.isLoading || options.isLoading || keywords.isLoading,
        options: options.data ?? ({} as IOtherOptions),
        deleteKeywords: keywords.data ?? [],
        noGoKeywords: noGoKeywords.data ?? [],
        regions: regions.data ?? [],
    });
};
