import classNames from 'classnames';

import { AddIcon } from '../../../components/icons/addIcon';
import { CheckIcon } from '../../../components/icons/checkIcon';
import { CloseIcon } from '../../../components/icons/closeIcon';

import { ViewProps } from './model';

import './style.scss';

export const View = ({ text, creating, onClick, showInput, value, onCancel, onChange, onCreate }: ViewProps) => {
    return (
        <div className="add-item-node">
            <div className={classNames('add-item-node-input', { ['add-item-node-input__show']: showInput })}>
                <input placeholder="New item" onInput={onChange} value={value} />
                <CheckIcon onClick={creating ? undefined : onCreate} />
                <CloseIcon onClick={creating ? undefined : onCancel} />
            </div>
            <div
                className={classNames('add-item-node-button', { ['add-item-node-button__hide']: showInput })}
                onClick={onClick}
            >
                <AddIcon />
                {text}
            </div>
        </div>
    );
};
