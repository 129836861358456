import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../..';
import { DeleteKeyword, NoGoKeyword } from '../../../common/model/dictionaries';

export const deleteKeywordsSelector = (state: RootState) => state.dictionaries.deleteKeywords;

export const processDeleteKeywordsSelector = createSelector(
    deleteKeywordsSelector,
    ({ data, isLoading, error }): DeleteKeyword[] => {
        if (isLoading || error || !data) return [];
        return data;
    },
);

export const noGoKeywordsSelector = (state: RootState) => state.dictionaries.noGoKeywords;

export const processNoGoKeywordsSelector = createSelector(
    noGoKeywordsSelector,
    ({ data, isLoading, error }): NoGoKeyword[] => {
        if (isLoading || error || !data) return [];
        return data;
    },
);
