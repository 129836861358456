import { TabsConfig } from '../../components/tabs/useTabs';

export enum SettingsTabs {
    Subscriptions = 'subscriptions',
    InternetResources = 'internet-resources',
    OtherOptions = 'other-options',
}

export const DEFAULT_TAB = SettingsTabs.InternetResources;

export interface ViewProps {
    tabsConfig: TabsConfig;
    initialTab: SettingsTabs;
}
