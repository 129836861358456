import * as yup from 'yup';

export const signUpSchema = yup.object().shape({
    login: yup
        .string()
        .required('Please enter your login')
        .matches(
            /^.*(?=.{2,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Login must contain at least 2 characters, one uppercase, one number and one special case character',
        ),
    country: yup.object().required('Please select your region'),
    email: yup.string().required('Please enter your email').email(),
    password: yup
        .string()
        .required('Please enter your password')
        .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character',
        ),
    repeatPassword: yup
        .string()
        .required('Please confirm your password')
        .oneOf([yup.ref('password'), null], "Passwords don't match."),
});
