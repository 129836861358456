import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAdminValidation } from '../../../hooks/useAdminValidation';
import { AppDispatch } from '../../../redux';
import { getResultRecognize } from '../../../redux/actions/reports';
import { listResultCountSelector, listResultRecognizeSelector } from '../../../redux/selectors/reports';

import { View } from './view';

const count = 10;

export const RecognitionPhrase = () => {
    useAdminValidation();

    const dispatch: AppDispatch = useDispatch();
    const resultRecognizeData = useSelector(listResultRecognizeSelector);
    const countData = useSelector(listResultCountSelector);

    const [page, setPage] = useState(0);

    useEffect(() => {
        dispatch(getResultRecognize(page + 1, count));
    }, [dispatch, page]);

    return View({
        data: resultRecognizeData,
        countData,
        page,
        setPage,
        count,
    });
};
