import { Outlet, useMatch } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

export const Wrapper = () => {
    const navigate = useNavigate();
    const { params } = useMatch<'*', string>({ path: '/admin/database/article/*' }) ?? { params: { '*': '' } };

    useEffect(() => {
        if (!params['*']) {
            navigate('/admin/database', { replace: true });
        }
    }, [params, navigate]);

    return <Outlet />;
};
