import React from 'react';
import classNames from 'classnames';

import { ButtonProps } from './model';

import './style.scss';

export const ButtonView = (props: ButtonProps) => {
    const { uppercase = true, icon } = props;
    return (
        <button
            type={props.buttonType || 'button'}
            className={classNames('button', props.type, props.className, { 'button-uppercase': uppercase })}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <div className="button-content">
                {icon}
                <span>{props.title}</span>
            </div>
        </button>
    );
};
