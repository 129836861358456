import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExternalSource } from '../../../../common/model/externalSource';

export interface InternetResourcesState {
    externalSources: ExternalSource[];
    isSourceModalOpen: boolean;
    editableSource: ExternalSource | null;
}

const initialState: InternetResourcesState = {
    externalSources: [],
    isSourceModalOpen: false,
    editableSource: null,
};

const internetResourcesSlice = createSlice({
    name: 'internetResources',
    initialState,
    reducers: {
        setExternalSources: (state, action: PayloadAction<ExternalSource[]>) => {
            state.externalSources = action.payload;
        },
        openNewResourceModal: (state) => {
            state.isSourceModalOpen = true;
        },
        closeNewResourceModal: (state) => {
            state.isSourceModalOpen = false;
            state.editableSource = null;
        },
        setEditSourceId: (state, action: PayloadAction<ExternalSource>) => {
            state.editableSource = action.payload;
        },
    },
});

export const { setExternalSources, openNewResourceModal, closeNewResourceModal, setEditSourceId } =
    internetResourcesSlice.actions;
export const internetResourcesReducer = internetResourcesSlice.reducer;
