import { intervalToDuration } from 'date-fns';
import { useState } from 'react';

import { ButtonType } from '../../../../common/enum/buttonType';
import { httpService } from '../../../../common/http';
import { backendRoutes } from '../../../../routes/backend';
import { SubscriptionsViewModel } from '../../../../shared/common/models/subscription';
import { Button } from '../../../button';

import { PaymentForm } from './paymentForm';

import './style.scss';

export const SubscriptionCard = ({ subscription }: { subscription: SubscriptionsViewModel }) => {
    const duration = intervalToDuration({ start: 0, end: subscription.duration || 0 });
    const [showModal, setShowModal] = useState(false);
    const [clientSecret, setClientSecret] = useState<string | null>(null);

    const onSubscribe = async () => {
        setShowModal(true);
        const result = await httpService.post(backendRoutes.createPayment, { tariffId: subscription.id });
        setClientSecret(result.client);
    };

    return (
        <div className="subscription-card-in-modal">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h4 className="sub-name">{subscription.name}</h4>
                <span>Duration: {duration.months} months</span>
                <span>
                    Price: {subscription.price} {subscription.currency}
                </span>
            </div>
            <div style={{ height: 40 }} />
            <Button type={ButtonType.primary} title="SUBSCRIBE" onClick={onSubscribe} />
            {showModal && clientSecret ? (
                <PaymentForm
                    subscription={subscription}
                    clientSecret={clientSecret}
                    onClose={() => setShowModal(false)}
                />
            ) : null}
        </div>
    );
};
