import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { KeywordInfo } from '../../../common/model/database';
import { LoadableData } from '../../../common/types';

export interface KeywordState {
    itemsMap: Record<number, KeywordInfo> | null;
    items: LoadableData<KeywordInfo[]>;
    selectedNode: KeywordInfo | null;
    selectedNodeDocuments: any[];
    selectedNodeDocumentsCount: number;
    filterSelectedNodeDocuments: string | null;
    workzoneTreeSelectedItemKeys: string[];
    workzoneTreeSelectedLastItem: KeywordInfo | null;
    documentsSearchPhrase: string;
}

const initialState: KeywordState = {
    itemsMap: null,
    selectedNode: null,
    selectedNodeDocuments: [],
    selectedNodeDocumentsCount: 0,
    filterSelectedNodeDocuments: null,
    workzoneTreeSelectedItemKeys: [],
    workzoneTreeSelectedLastItem: null,
    documentsSearchPhrase: '',
    items: {
        data: null,
        isLoading: false,
        error: null,
    },
};

export const keywordSlice = createSlice({
    name: 'keyword',
    initialState,
    reducers: {
        setChosenItem: (state, { payload }: PayloadAction<KeywordInfo | null>) => {
            state.selectedNode = payload;
        },
        setSelectedNodeDocuments: (state, { payload }: PayloadAction<any[]>) => {
            state.selectedNodeDocuments = payload;
        },
        setSelectedNodeDocumentsCount: (state, { payload }: PayloadAction<number>) => {
            state.selectedNodeDocumentsCount = payload;
        },
        setKeywords: (state, { payload }: PayloadAction<KeywordInfo[] | null>) => {
            state.items.data = payload;
        },
        setKeywordsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.items.isLoading = payload;
        },
        setKeywordsError: (state, { payload }: PayloadAction<string | null>) => {
            state.items.data = null;
            state.items.error = payload;
        },
        addItemToWorkzoneTreeSelectedItemKeys: (state, { payload }: PayloadAction<string[]>) => {
            state.workzoneTreeSelectedItemKeys = payload;
        },
        setWorkzoneLastItem: (state, { payload }: PayloadAction<KeywordInfo | null>) => {
            state.workzoneTreeSelectedLastItem = payload;
        },
        setDocumentsSearchPhrase: (state, { payload }: PayloadAction<string>) => {
            state.documentsSearchPhrase = payload;
        },
        reset: () => initialState,
    },
});

export const {
    setKeywords,
    setKeywordsLoading,
    setKeywordsError,
    reset,
    setChosenItem,
    setSelectedNodeDocuments,
    setSelectedNodeDocumentsCount,
    addItemToWorkzoneTreeSelectedItemKeys,
    setWorkzoneLastItem,
    setDocumentsSearchPhrase,
} = keywordSlice.actions;

export default keywordSlice.reducer;
