import cn from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';

import { FontTitleProps } from './model';

import './style.scss';

export const View = (props: PropsWithChildren<FontTitleProps>) => {
    const { level, children, marginBottom, marginTop } = props;
    const className = cn(`font-title font-title_h${props.level}`, props.className);
    const style: CSSProperties = {
        marginBottom,
        marginTop,
    };
    switch (level) {
        case 3:
            return (
                <h3 style={style} className={className}>
                    {children}
                </h3>
            );
        case 2:
            return (
                <h2 style={style} className={className}>
                    {children}
                </h2>
            );
        case 1:
        default:
            return (
                <h1 style={style} className={className}>
                    {children}
                </h1>
            );
    }
};
