import { ReactComponent as ArrowRightSvg } from '../../assets/arrow-right.svg';

import { IconWrapper } from './iconWrapper';
import { IconProps } from './model';

export const ArrowRightIcon = (props: IconProps) => {
    return (
        <IconWrapper {...props}>
            <ArrowRightSvg />
        </IconWrapper>
    );
};
