import { createSelector } from 'reselect';

import { RootState } from '../../../index';

const internetResourcesSelector = (state: RootState) => state.settings.internetResources;

export const externalSourcesSelector = createSelector(internetResourcesSelector, (state) => state.externalSources);

export const editableSourceSelector = createSelector(internetResourcesSelector, (state) => state.editableSource);

export const isSourceModalOpenSelector = createSelector(
    internetResourcesSelector,
    (state) => state.isSourceModalOpen || state.editableSource !== null,
);
