import { format, parseISO } from 'date-fns';

import { Modal } from '../../../components/modal';
import { FontTitle } from '../../../components/fontTitle';
import { ExternalSources } from '../externalSources';

import { ViewProps } from './model';
import './style.scss';

export const View = (props: ViewProps) => {
    const { keywordDocument, onClose, keywords, html } = props;

    return (
        <Modal isRightSide isOpen onClose={onClose} className="document-details">
            <FontTitle marginBottom={18} level={2}>
                {keywordDocument.name}
            </FontTitle>
            <p>{format(parseISO(keywordDocument.createdAt), 'dd.MM.yyyy')}</p>
            <div className="sticker-list">
                {keywords.map((keyword, i) => (
                    <span key={i} className="sticker">
                        {keyword}
                    </span>
                ))}
            </div>
            <div
                dangerouslySetInnerHTML={{
                    __html: html,
                }}
            />
            <ExternalSources linkKeywordsExternal={[keywordDocument.name]} />
        </Modal>
    );
};
