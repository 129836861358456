import { ExternalSource } from '../../../common/model/externalSource';

import { ExternalSourceLink } from './model';

const VARIABLE_VALUE = '{variables}';

export function prepareExternalSourceLink(source: ExternalSource, words: string[]): ExternalSourceLink {
    const queryParameters: string[] = [];

    if (source.parameters?.length) {
        const preparedCategories = words.join('+');
        for (const parameter of source.parameters) {
            if (parameter.value === VARIABLE_VALUE && words.length > 0) {
                queryParameters.push(`${parameter.key}=${preparedCategories}`);
            } else {
                queryParameters.push(`${parameter.key}=${parameter.value}`);
            }
        }
    }

    return {
        id: source.id,
        label: source.title,
        href: `${source.url}?${queryParameters.join('&')}`,
    };
}
