import { SelectOption } from '../../model';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';

export interface Props {
    value?: SelectOption | SelectOption[];
    onDeselect: (opt: SelectOption) => void;
}

export const Selected = ({ value, onDeselect }: Props) => {
    if (!value || (Array.isArray(value) && !value.length)) {
        return null;
    }

    if (Array.isArray(value)) {
        return (
            <div className="base-select-selected-list">
                {value.map((opt) => {
                    return (
                        <div key={opt.value} className="base-select-selected-list-item">
                            <span>{opt.label}</span>
                            <span
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDeselect(opt);
                                }}
                            >
                                <CloseIcon className="base-select-selected-list-item-remove" />
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }

    return <>{value.label}</>;
};
