import React from 'react';

import { ReactComponent as EmailIcon } from '../../../../assets/mail-support.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/phone-support.svg';

import { Props } from './model';

import './style.scss';

const Icon = {
    phone: PhoneIcon,
    mail: EmailIcon,
};

export const SupportItem = (props: Props) => {
    const Logo = Icon[props.type];
    return (
        <div className="support-item-container">
            <Logo />
            {props.type === 'mail' ? (
                <a className="support-item-text" href={`mailto:${props.text}`}>
                    {props.text}
                </a>
            ) : (
                <a className="support-item-text" href={`tel:${props.text}`}>
                    {props.text}
                </a>
            )}
        </div>
    );
};
