import React from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '../../components/button';
import { ButtonType } from '../../common/enum/buttonType';
import { FormField } from '../../components/formField';
import './style.scss';
import { Modal } from '../../components/modal';

import { ViewProps } from './model';

export const View = (props: ViewProps) => {
    return (
        <>
            <Modal title="Sign up" isOpen={!props.isSuccess} onClose={props.onClose}>
                <FormProvider {...props.methods}>
                    <form onSubmit={props.methods.handleSubmit(props.onSubmit)}>
                        <FormField title="Login" type="text" name="login" rules={{ required: true }} />
                        <FormField title="E-mail" type="text" name="email" rules={{ required: true }} />
                        <FormField
                            type="select"
                            options={props.countriesOptions}
                            name="country"
                            title="Region"
                            rules={{ required: true }}
                        />
                        <FormField title="Password" type="password" name="password" rules={{ required: true }} />
                        <FormField
                            title="Repeat password"
                            type="password"
                            name="repeatPassword"
                            rules={{ required: true }}
                        />
                        <div className="btn-container">
                            <Button
                                buttonType="submit"
                                title="Registration"
                                type={ButtonType.primary}
                                disabled={props.methods.formState.isSubmitting}
                            />
                            <Button onClick={props.onClose} title="CANCEL" type={ButtonType.secondary} />
                        </div>
                    </form>
                </FormProvider>
            </Modal>
            <Modal title="Email confirmation" isOpen={props.isSuccess} onClose={props.onClose}>
                <p className="recovery-password-description">Email confirmation link has been sent to your email.</p>
                <Button
                    onClick={props.onClose}
                    title="Done"
                    type={ButtonType.primary}
                    className="recovery-password-submit-button"
                />
            </Modal>
        </>
    );
};
