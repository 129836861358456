import { RootState } from '../../index';

export const keywordsSelector = (state: RootState) => state.keyword.items;

export const keywordsItemsDataSelector = (state: RootState) => state.keyword.items.data;

export const selectedNodeSelector = (state: RootState) => state.keyword.selectedNode;

export const selectedNodeDocumentsSelector = (state: RootState) => state.keyword.selectedNodeDocuments;

export const selectedNodeDocumentsCountSelector = (state: RootState) => state.keyword.selectedNodeDocumentsCount;

export const selectedLastItemSelector = (state: RootState) => state.keyword.workzoneTreeSelectedLastItem;

export const documentsSearchPhraseSelector = (state: RootState) => state.keyword.documentsSearchPhrase;
