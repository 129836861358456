export const modifyPhrase = (phrase: string, deleteKeywords: string[], noGoKeywords: string[]) => {
    if (noGoKeywords.some(v => phrase.includes(v))) {
        let tmpPharse = phrase;
        for (let i = 0; i < noGoKeywords.length; i++) {
            const noGoKeyword = noGoKeywords[i];
            const rg = new RegExp(`(?<=\\b${noGoKeyword.toLowerCase()}[,\\s]*)(\\w+)`, '');
            tmpPharse = phrase.toLowerCase().replace(rg, '');
        }
        return tmpPharse;
    }
    let tmpPharse = phrase;
    for (let i = 0; i < deleteKeywords.length; i++) {
        const deleteKeyword = deleteKeywords[i];
        const rg = new RegExp(`[a-z]+ ${deleteKeyword.toLowerCase()}`, 'g');
        tmpPharse = phrase.toLowerCase().replace(rg, '');
    }
    return tmpPharse;
}
