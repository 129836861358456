import { PropsWithChildren } from 'react';
import cn from 'classnames';

import { IconProps } from './model';

import './style.scss';

export const IconWrapper = (props: PropsWithChildren<IconProps>) => {
    const { onClick, className } = props;
    return (
        <span className={cn(className, 'icon-wrapper')} onClick={onClick}>
            {props.children}
        </span>
    );
};
