import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { login } from '../../redux/actions/auth';
import { AppDispatch } from '../../redux';

import { View } from './view';
import { LoginFormData } from './model';

export const Login = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const methods = useForm<LoginFormData>({
        defaultValues: {
            login: process.env.REACT_APP_DEV_LOGIN ?? '',
            password: process.env.REACT_APP_DEV_PASSWORD ?? '',
        },
    });

    const onClose = () => {
        navigate('/', { replace: true });
    };

    const onForgotPass = () => {
        navigate('/password-recovery');
    };

    const onSubmit = async (data: LoginFormData) => {
        try {
            await dispatch(login(data));
        } catch (e: any) {
            if (e.response.status === 400) {
                methods.setError('password', { message: e.response.data.message });
            } else if (e.response.status === 403) {
                methods.setError('errorBlock', { types: { errorBlock: e.response.data.message } });
            }
        }
    };

    return View({
        methods,
        onSubmit,
        onClose,
        onForgotPass,
    });
};
