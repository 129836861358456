import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { User } from '../../../../common/model/user';
import { UserTypes } from '../../../../common/enum/userTypes';
import { updateUser } from '../../../../redux/actions/users';
import { AppDispatch } from '../../../../redux';

import { FormProps } from './model';
import { View } from './view';

export const Form = ({ data, onClose }: FormProps) => {
    const dispatch: AppDispatch = useDispatch();
    const form = useForm<User & { isAdmin: boolean }>({
        defaultValues: { ...data, isAdmin: data.type === UserTypes.admin },
    });
    const onSubmit = form.handleSubmit(async (data: User & { isAdmin: boolean }) => {
        try {
            await dispatch(
                updateUser({
                    ...data,
                    type: data.isAdmin ? UserTypes.admin : UserTypes.user,
                }),
            );

            form.reset(data);
            toast.success('User data was updated successfully');
        } catch (e) {
            form.reset();
            toast.error('Failed to update user data');
        }
    });

    return View({ form, onSubmit, onClose });
};
