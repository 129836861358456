import { Currency } from '../../../../common/model/currency';

export const CURRENCIES: Currency[] = [
    {
        id: 1,
        currencySymbol: '$',
        currencyCode: 'USD',
    },
    /*{
        id: 2,
        currencySymbol: '€',
        currencyCode: 'EUR',
    },*/
    {
        id: 3,
        currencySymbol: '$',
        currencyCode: 'CAD',
    },
];
