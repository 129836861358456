import React from 'react';

import { AdvantageProps } from './model';
import './style.scss';

export const AdvantageView = (props: AdvantageProps) => {
    const { Icon } = props;
    return (
        <div className="container-content">
            <Icon />
            <h3 className="advantage-title">{props.title}</h3>
            <div className="content">{props.content}</div>
        </div>
    );
};
