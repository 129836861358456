import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { verifyEmail } from '../../redux/actions/signUp';
import { authUserSelector } from '../../redux/selectors/auth';
import 'react-toastify/dist/ReactToastify.css';
import { AppDispatch } from '../../redux';
import { Routes } from '../../routes/routes';
import { LinkType } from '../../common/enum/linkType';

import { View } from './view';

export const StartPage = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const user = useSelector(authUserSelector);
    const [params] = useSearchParams();

    const hasCode = params.has('code') && params.has('type');

    useEffect(() => {
        if (user && user.type) {
            navigate(Routes.workzone);
        }
    }, [user, navigate]);

    useEffect(() => {
        if (hasCode && params.get('type') === LinkType.userConfirmation) {
            dispatch(verifyEmail(params.get('code') ?? '', params.get('type') ?? ''))
                .then(() => toast.success('Successful email verification'))
                .catch(() =>
                    toast.error(`We can't verify your email. Please contact us by email support@support.support`),
                );
        }
    }, [dispatch, hasCode, params]);

    return View();
};
