import React from 'react';
import { Outlet } from 'react-router-dom';

import { SearchResultscreen } from '../../components/searchResultScreen';
import { Speaker } from '../../components/speaker';
import { CategoryTree } from '../../components/categoryTree';
import { CatalogResult } from '../../components/catalogResult';

import { ExternalSources } from './externalSources';
import './style.scss';

export const View = () => {
    return (
        <section className="workzone">
            <Speaker />
            <section className="workzone-content">
                <div className="workzone-content-right">
                    <SearchResultscreen />
                    <CatalogResult />
                    <ExternalSources />
                </div>
                <div className="workzone-content-left">
                    <CategoryTree />
                </div>
            </section>
            <Outlet />
        </section>
    );
};
