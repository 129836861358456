import { FieldValues } from 'react-hook-form';

import { ModalData, ModalState } from '../editModal/model';

export const FIELD_KEY_NAME = 'field_id';

export interface FormItem {
    id: number;
    name: string;
}

export interface FormItemsProps {
    name: 'deleteKeywords' | 'noGoKeywords' | 'regions';
    title: string;
    addButtonTitle: string;
    modalTitle: string;
}

export interface ViewProps extends Omit<FormItemsProps, 'name'> {
    modalData: ModalData | null;
    fields: FieldValues[];
    onShowModal: (state: ModalState, field?: FieldValues, index?: number) => void;
    onCloseModal: () => void;
    onAddUpdate: (newValue: string) => void;
    onDelete: (index: number) => void;
}
