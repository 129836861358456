import { FormProvider } from 'react-hook-form';

import { Modal } from '../../../../components/modal';
import { FontTitle } from '../../../../components/fontTitle';
import { FormField } from '../../../../components/formField';
import { CreateExternalSource } from '../../../../common/model/externalSource';
import { ButtonsWrapper } from '../../../../components/buttonsWrapper';
import { Button } from '../../../../components/button';
import { ButtonType } from '../../../../common/enum/buttonType';
import { AddIcon } from '../../../../components/icons/addIcon';
import { DeleteIcon } from '../../../../components/icons/deleteIcon';

import { ViewProps } from './model';

import './style.scss';

const helpText = `* If you want to use query key parameter for place of your keywords - use "{variables}"`;

export const View = (props: ViewProps) => {
    const { methods, addParameter, removeParameter, parameters, onSubmit, onClose } = props;
    return (
        <Modal className="add-resource-modal" isOpen={true} onClose={onClose}>
            <FontTitle marginBottom={32} level={2}>
                New Internet resource
            </FontTitle>
            <span style={{ fontSize: 14, marginBottom: 20 }}>{helpText}</span>
            <FormProvider {...methods}>
                <form className="resources-form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="resources-fields">
                        <FormField<CreateExternalSource> title="Title" type="text" name="title" />
                        <FormField<CreateExternalSource> title="URL Template" type="text" name="url" />

                        <div className="parameters-list">
                            {parameters.map((item, index) => (
                                <div key={item.id} className="parameter-wrapper">
                                    <FormField<CreateExternalSource>
                                        error={methods.formState.errors['parameters']?.[index]?.key}
                                        title="Key"
                                        type="text"
                                        rules={{ required: true }}
                                        name={`parameters[${index}].key`}
                                    />
                                    <FormField<CreateExternalSource>
                                        error={methods.formState.errors['parameters']?.[index]?.value}
                                        title="Value*"
                                        type="text"
                                        rules={{ required: true }}
                                        name={`parameters[${index}].value`}
                                    />
                                    <DeleteIcon
                                        onClick={() => removeParameter(index)}
                                        className="delete-parameter-icon"
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <Button
                        className="add-parameter-button"
                        onClick={addParameter}
                        uppercase={false}
                        title="Add parameter"
                        type={ButtonType.borderOffPrimary}
                        icon={<AddIcon />}
                    />
                    {methods.formState.errors.parameters?.message && (
                        <p className="parameters-error">{methods.formState.errors.parameters?.message}</p>
                    )}

                    <ButtonsWrapper columnGap={24}>
                        <Button title="Set up" type={ButtonType.primary} buttonType="submit" />
                        <Button onClick={onClose} title="Cancel" type={ButtonType.secondary} />
                    </ButtonsWrapper>
                </form>
            </FormProvider>
        </Modal>
    );
};
