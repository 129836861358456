import React from 'react';
import { Outlet } from 'react-router-dom';

import { Description } from '../../components/description';
import { Collapsible } from '../../components/сollapsible';

import './style.scss';
import { Support } from './support';

export const View = () => {
    return (
        <div>
            <Outlet />
            <Description
                title="About us"
                text={`The field of Radiology is ever expanding with new information and knowledge. It is a challenge for the general radiologist (and even subspecialized radiologist) to keep up to date on all the new and latest information.\n Often, when posed with a challenging case, precious time is used researching various web-based sources of information to aid in interpreting the case.\n Also, getting a second opinion from a colleague can be very beneficial. However, doing frequent internet searches throughout the day and interrupting your colleagues can lead to more and more distractions and disruptions, potentially leading to medical errors. `}
                showButton={false}
                lists={[]}
                videoUrl={'https://www.youtube.com/embed/v=QD0Wlsm_Sdk'}
            />
            <div className="collapsible-conteiner">
                <Collapsible title="Reason">
                    <div className="section-content">
                        <p>
                            So, we decided to create a curated database that is voice activated that can be used by any
                            radiologists without interrupting their workflow. This way, no matter where you practice,
                            any time of the day, you can have access to real-time clinical support and second opinions.{' '}
                            <br />
                        </p>
                    </div>
                </Collapsible>
                <Collapsible title="Who made it">
                    <div className="section-content">
                        <p>
                            2nd Opinion was designed by a radiologist with the support of an IT team as an ideal
                            companion for a radiologist working a busy practice and striving for excellence and
                            accuracy. 2nd Opinion removes the redundancies of reputedly searching multiple databases and
                            seamlessly integrates into your workflow.
                        </p>
                    </div>
                </Collapsible>
            </div>
            <Support />
        </div>
    );
};
