import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeleteKeyword, NoGoKeyword } from '../../../common/model/dictionaries';
import { LoadableData } from '../../../common/types';

export interface DictionariesState {
    deleteKeywords: LoadableData<DeleteKeyword[]>;
    noGoKeywords: LoadableData<NoGoKeyword[]>;
}

const initialState: DictionariesState = {
    deleteKeywords: {
        data: null,
        error: null,
        isLoading: false,
    },
    noGoKeywords: {
        data: null,
        error: null,
        isLoading: false,
    },
};

export const dictionarySlice = createSlice({
    name: 'dictionarySlice',
    initialState,
    reducers: {
        setDeleteKeywords: (state, { payload }: PayloadAction<DeleteKeyword[]>) => {
            state.deleteKeywords.data = payload;
        },
        setDeleteKeywordsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.deleteKeywords.isLoading = payload;
        },
        setDeleteKeywordsError: (state, { payload }: PayloadAction<string | null>) => {
            state.deleteKeywords.error = payload;
        },
        setNoGoKeywords: (state, { payload }: PayloadAction<NoGoKeyword[]>) => {
            state.noGoKeywords.data = payload;
        },
        setNoGoKeywordsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.noGoKeywords.isLoading = payload;
        },
        setNoGoKeywordsError: (state, { payload }: PayloadAction<string | null>) => {
            state.noGoKeywords.error = payload;
        },
        reset: () => initialState,
    },
});

export const {
    setDeleteKeywords,
    setDeleteKeywordsLoading,
    setDeleteKeywordsError,
    setNoGoKeywords,
    setNoGoKeywordsLoading,
    setNoGoKeywordsError,
} = dictionarySlice.actions;

export default dictionarySlice.reducer;
