import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { AppDispatch } from '../../redux';
import { authTokenSelector } from '../../redux/selectors/auth';
import { logout } from '../../redux/actions/auth';

export const useAuthValidation = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector(authTokenSelector);

    useEffect(() => {
        if (!token) {
            dispatch(logout());
            navigate('/', { replace: true });
        }
    }, [dispatch, token, navigate]);
};
