import { AppThunk } from '../../../index';
import {
    setCurrencies,
    setOtherOptionsLoading,
    setOtherOptionsError,
    setOtherOptions,
    setSupportOptionsLoading,
    setSupportOptionsError,
    setSupportOptions,
} from '../../../slices/settings/otherOptions';
import { httpService } from '../../../../common/http';
import { backendRoutes } from '../../../../routes/backend';
import { OtherOptions } from '../../../../shared/common/models/settings';

import { CURRENCIES } from './mocks';

export const getOtherOptions = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setOtherOptionsLoading(true));
            dispatch(setOtherOptionsError(null));

            const options = await httpService.get(backendRoutes.getOtherOptions);
            dispatch(setOtherOptions(options));
        } catch (e) {
            dispatch(setOtherOptions(null));
            dispatch(setOtherOptionsError('Failed to get other options'));
        } finally {
            dispatch(setOtherOptionsLoading(false));
        }
    };
};

export const getCurrencies = (): AppThunk => {
    return async (dispatch) => {
        dispatch(setCurrencies(CURRENCIES));
    };
};

export const updateOtherOptions = (options: OtherOptions): AppThunk => {
    return async (dispatch) => {
        await httpService.patch(backendRoutes.updateOtherOptions, options);
        dispatch(setOtherOptions(options));
    };
};

export const getSupportOptions = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setSupportOptionsLoading(true));
            dispatch(setSupportOptionsError(null));

            const options = await httpService.get(backendRoutes.getSupportOptions);
            dispatch(setSupportOptions(options));
        } catch (e) {
            dispatch(setSupportOptions(null));
            dispatch(setSupportOptionsError('Failed to get support options'));
        } finally {
            dispatch(setSupportOptionsLoading(false));
        }
    };
};
