import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserSubscriptionState {
    subscription: any;
}

const initialState: UserSubscriptionState = {
    subscription: null,
};

export const userSubscriptionSlice = createSlice({
    name: '@radiology/user_subscription',
    initialState,
    reducers: {
        setSubscription: (state, { payload }: PayloadAction<any>) => {
            state.subscription = payload;
        },
        reset: () => initialState,
    },
});

export const { setSubscription, reset } = userSubscriptionSlice.actions;

export default userSubscriptionSlice.reducer;
