import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { AppDispatch } from '../../../../redux';
import { closeNewSubscriptionModal } from '../../../../redux/slices/settings/subscriptions';
import { addSubscription, updateSubscription } from '../../../../redux/actions/settings/subscriptions';
import { editableSubscriptionSelector } from '../../../../redux/selectors/settings/subscriptions';
import { CURRENCIES } from '../../../../redux/actions/settings/otherOptions/mocks';
import { SubscriptionsCreateModel } from '../../../../shared/common/models/subscription';

import { createExternalSourceSchema } from './validation';
import { View } from './view';
import { durationOptions } from './const';

export const SourceModal = () => {
    const dispatch: AppDispatch = useDispatch();
    const editableSubsctiption = useSelector(editableSubscriptionSelector);
    const chosenCurrency = editableSubsctiption
        ? CURRENCIES.find((c) => c.currencyCode === editableSubsctiption?.currency)
        : undefined;
    const methods = useForm<SubscriptionsCreateModel & { id?: number }>({
        defaultValues: {
            id: editableSubsctiption?.id,
            name: editableSubsctiption?.name,
            duration: editableSubsctiption
                ? (durationOptions.find((opt) => opt.value === +editableSubsctiption?.duration) as any)
                : undefined,
            price: editableSubsctiption?.price,
            currency: chosenCurrency
                ? ({ label: chosenCurrency.currencyCode, value: chosenCurrency.currencyCode } as any)
                : undefined,
            isTrial: editableSubsctiption?.isTrial,
        },
        resolver: yupResolver(createExternalSourceSchema),
    });

    const onSubmit = useCallback(
        (data: SubscriptionsCreateModel) => {
            editableSubsctiption
                ? dispatch(updateSubscription(editableSubsctiption.id, data))
                : dispatch(addSubscription(data));
        },
        [dispatch, editableSubsctiption],
    );

    const closeNewSubscriptionodalCallback = useCallback(() => {
        dispatch(closeNewSubscriptionModal());
    }, [dispatch]);

    return <View methods={methods} isOpen onClose={closeNewSubscriptionodalCallback} onSubmit={onSubmit} />;
};
