import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getExternalSources } from '../../../redux/actions/catalog';
import { AppDispatch } from '../../../redux';
import { externalSourcesSelector } from '../../../redux/selectors/catalog';

import { View } from './view';
import { prepareExternalSourceLink } from './utils';
import { useLinkKeywords } from './hooks';

export interface ExternalSourcesProps {
    linkKeywordsExternal?: string[];
}

export const ExternalSources = ({ linkKeywordsExternal }: ExternalSourcesProps) => {
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        dispatch(getExternalSources());
    }, [dispatch]);

    const externalSources = useSelector(externalSourcesSelector);

    const linkKeywords = useLinkKeywords();

    const externalSourceLinks = useMemo(
        () => externalSources.map((source) => prepareExternalSourceLink(source, linkKeywordsExternal || linkKeywords)),
        [linkKeywords, externalSources, linkKeywordsExternal],
    );
    return View({
        externalSourceLinks,
    });
};
