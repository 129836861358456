import './style.scss';
import { FontTitle } from '../../../components/fontTitle';
import { ExternalLinkIcon } from '../../../components/icons/externalLinkIcon';

import { ViewProps } from './model';

export const View = (props: ViewProps) => {
    const { externalSourceLinks } = props;
    return (
        <div className="external-sources">
            <FontTitle marginTop={44} marginBottom={25} level={3}>
                External Internet resources
            </FontTitle>
            <ul className="source-list">
                {externalSourceLinks.map((source) => (
                    <li key={source.id}>
                        <a className="source-link" href={source.href} target="_blank">
                            <ExternalLinkIcon />
                            {source.label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};
