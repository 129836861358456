import { statisticData } from '../../pages/reports/recognitionMinutes/model';

export interface Page {
    page: number;
    active: boolean;
    disabled: boolean;
    title: string;
}

export interface PageListRendererProps {
    pages: Page[];
    onPageChange: any;
}

export interface TableProps {
    data: statisticData[];
}

export interface PageButtonRenderer {
    page: number | string;
    active: boolean;
    disabled: boolean;
    title: string;
    onPageChange: any;
}

export enum StatisticTypeTranslate {
    fullBilled = 'full billed (in seconds)',
    fullBilledDay = 'full billed (in a day)',
    fullBilledWeek = 'full billed (in week)',
    fullBilledMonth = 'full billed month (per month)',
    userCount = 'user count',
    userActivityDay = 'user activity (in a day)',
    userActivityWeek = 'user activity week (in week)',
    userActivityMonth = 'user activity (per month)',
}
