import { createSelector } from 'reselect';

import { RootState } from '../../../index';

const subscriptionsSelector = (state: RootState) => state.settings.subscriptopns;

export const subscriptionItemsSelector = createSelector(subscriptionsSelector, (state) => state.subscriptions);

export const editableSubscriptionSelector = createSelector(
    subscriptionsSelector,
    (state) => state.editableSubscription,
);

export const isSubscriptionModalOpenSelector = createSelector(
    subscriptionsSelector,
    (state) => state.isSubscriptionModalOpen || state.editableSubscription !== null,
);
