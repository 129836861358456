import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { Routes } from '../../../routes/routes';
import { AppDispatch } from '../../../redux';
import { setChosenItem, setSelectedNodeDocuments } from '../../../redux/slices/keyword';
import {
    documentsSearchPhraseSelector,
    selectedNodeDocumentsCountSelector,
    selectedNodeDocumentsSelector,
    selectedNodeSelector,
} from '../../../redux/selectors/keyword';
import { getKeywordDocuments, searchDocuments } from '../../../redux/actions/keyword';

import { View } from './view';
import { CatalogArticlesProps } from './model';

const count = 6;

export const CatalogArticles = (props: CatalogArticlesProps) => {
    const navigate = useNavigate();
    const onNewArticleCLick = useCallback(() => navigate(Routes.databaseNewArticle), [navigate]);
    const selectedNode = useSelector(selectedNodeSelector);
    const selectedDocuments = useSelector(selectedNodeDocumentsSelector);
    const selectedDocumentsCount = useSelector(selectedNodeDocumentsCountSelector);
    const [page, setPage] = useState(0);
    const dispatch: AppDispatch = useDispatch();
    const documentsSearchPhrase = useSelector(documentsSearchPhraseSelector);

    const [debouncedSearch] = useDebounce(documentsSearchPhrase, 500);

    useEffect(() => {
        if (!selectedNode) {
            dispatch(setSelectedNodeDocuments([]));
        } else {
            dispatch(getKeywordDocuments(page + 1, count));
        }
    }, [selectedNode, dispatch, page]);

    // reset page to 0 each time, when search have been changed
    useEffect(() => {
        setPage(0);
    }, [debouncedSearch]);

    useEffect(() => {
        dispatch(setChosenItem(null));
        dispatch(searchDocuments(debouncedSearch, page + 1, count));
    }, [debouncedSearch, dispatch, page]);

    useEffect(() => {
        setPage(0);
    }, [selectedNode]);

    return (
        <View
            {...props}
            onNewArticleClick={onNewArticleCLick}
            articles={selectedDocuments}
            showButton={!!selectedNode}
            page={page}
            pages={selectedDocumentsCount / count}
            onChangePage={(i: any) => setPage(i.selected)}
        />
    );
};
