import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import './style.scss';

import BootstrapTable from 'react-bootstrap-table-next';

import { TableProps } from './model';

const columns = [
    {
        dataField: 'user',
        text: 'User',
        sort: true,
        headerStyle: () => {
            return { width: '10%' };
        },
    },
    {
        dataField: 'phrase',
        text: 'Phrase',
    },
    {
        dataField: 'keywords',
        text: 'Keywords',
        headerStyle: () => {
            return { width: '30%' };
        },
    },
];

export const TableResultRecognize = (props: TableProps) => {
    const { data } = props;

    return (
        <div className="container-table-recognize">
            <BootstrapTable
                bootstrap4
                condensed
                hover
                classes="table-class-recognize"
                bordered={false}
                keyField="id"
                data={data}
                columns={columns}
                headerClasses="headerClass-recognize"
            />
        </div>
    );
};
