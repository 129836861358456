import { createSelector } from 'reselect';

import { RootState } from '../../index';

export const recognizeHistorySelector = createSelector(
    (state: RootState) => state.recognize.history,
    (history) => history.join(' '),
);

export const recognizeTextSelector = (state: RootState) => state.recognize.text;

export const recognizeTimingSelector = (state: RootState) => state.recognize.timing;

export const recognizeSessionIdSelector = (state: RootState) => state.recognize.recognizeSessionId;

const recognizeResultSelector = (state: RootState) => state.recognize.recognizeResult;

export const recognizedWordsSelector = createSelector(recognizeResultSelector, (state) =>
    state.recognizedKeywords.map((keyword) => keyword.key),
);

export const mainRecognizedKeywordIdSelector = createSelector(
    recognizeResultSelector,
    (state) => state.lastKeyword?.id,
);

export const recognizeCurrentTextSelector = createSelector(
    recognizeHistorySelector,
    recognizeTextSelector,
    (history, text) => `${history} ${text}`.trim(),
);
