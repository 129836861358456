import { FieldValues } from 'react-hook-form';

export enum ModalState {
    New,
    Edit,
}

export interface ModalData {
    state: ModalState;
    field?: FieldValues;
    index?: number;
}

export interface EditModalProps {
    data: ModalData | null;
    title: string;
    isOpen: boolean;
    onAddUpdate: (newValue: string) => void;
    onClose: () => void;
}
