import { useCallback, useEffect, useRef, useState } from 'react';
import { stringify } from 'qs';

import { httpService } from '../../common/http';
import { backendRoutes } from '../../routes/backend';
import { Resource } from '../../common/model/resources/resource';

import { View } from './view';
import { SelectImageModalProps } from './model';

export function SelectResourcesModal(props: SelectImageModalProps) {
    const { onClose } = props;
    const [resources, setResources] = useState<Resource[]>([]);
    const currentPageRef = useRef(1);
    const totalCountRef = useRef(-1);
    const currentCountRef = useRef(0);

    useEffect(() => {
        currentCountRef.current = resources.length;
    }, [resources]);

    const getResources = useCallback(() => {
        const query = stringify({
            page: currentPageRef.current,
            size: 10,
        });
        if (currentCountRef.current >= totalCountRef.current && totalCountRef.current !== -1) return;

        httpService
            .get<{ count: number; resources: Resource[] }>(`${backendRoutes.resources}?${query}`)
            .then((response) => {
                setResources((prev) => [...prev, ...response.resources]);
                currentPageRef.current = currentPageRef.current + 1;
                totalCountRef.current = response.count;
            });
    }, []);

    useEffect(() => {
        getResources();
    }, [getResources]);

    return View({ onClose, resources, getResources });
}
