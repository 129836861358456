import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Outlet } from 'react-router';

import { ButtonType } from '../../common/enum/buttonType';
import { Button } from '../../components/button';
import { ContentWrapper } from '../../components/contentWrapper';
import { FontTitle } from '../../components/fontTitle';
import { FormField } from '../../components/formField';
import { Modal } from '../../components/modal';
import { PaymentCard } from '../../components/paymentCard';

import { ViewProps } from './model';

import './style.scss';

export const View = ({ methods, onSubmit, onChangePass, countriesOptions, user, isOpen, onClose }: ViewProps) => {
    return (
        <ContentWrapper>
            <Outlet />
            <div className="profile">
                <div className="profile-header">
                    <FontTitle level={1}>Profile</FontTitle>
                </div>
                <div className="content-container">
                    <div className="content-profile">
                        <div className="profile-info">
                            <span>Login</span>
                            <p>{user.login}</p>
                            <span>E-mail</span>
                            <p>{user.email}</p>
                        </div>

                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="profile-select">
                                    <FormField options={countriesOptions} title="Region" type="select" name="country" />
                                </div>
                                <div className="btn-update">
                                    <Button
                                        onClick={onChangePass}
                                        title="UPDATE PASSWORD"
                                        type={ButtonType.secondary}
                                    />
                                </div>
                                <div className="btn-container">
                                    <Button
                                        buttonType="submit"
                                        title="SAVE CHANGES"
                                        type={ButtonType.primary}
                                        disabled={methods.formState.isSubmitting}
                                    />
                                    <Button
                                        onClick={() => methods.reset()}
                                        title="CANCEL"
                                        type={ButtonType.secondary}
                                    />
                                </div>
                            </form>
                        </FormProvider>
                        <Modal title="Change password" isOpen={isOpen} onClose={onClose}>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmit)}>
                                    <FormField
                                        title="Old password"
                                        type="password"
                                        name="oldPassword"
                                        rules={{ required: true }}
                                    />
                                    <FormField
                                        title="Password"
                                        type="password"
                                        name="password"
                                        rules={{ required: true }}
                                    />
                                    <FormField
                                        title="Password confirmation"
                                        type="password"
                                        name="passwordConfirmation"
                                        rules={{ required: true }}
                                    />
                                    <div className="btn-container">
                                        <Button
                                            buttonType="submit"
                                            title="SAVE CHANGES"
                                            type={ButtonType.primary}
                                            disabled={methods.formState.isSubmitting}
                                        />
                                        <Button onClick={onClose} title="CANCEL" type={ButtonType.secondary} />
                                    </div>
                                </form>
                            </FormProvider>
                        </Modal>
                    </div>
                    <PaymentCard userId={user.id} isAdminView={false} />
                </div>
            </div>
        </ContentWrapper>
    );
};
