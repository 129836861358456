import cn from 'classnames';

import { ViewProps } from './model';

import './style.scss';

export const View = (props: ViewProps) => {
    const { onClick, children, isActive } = props;
    return (
        <div className={cn('tab-item', { 'tab-item--active': isActive })} onClick={onClick}>
            {children}
            {isActive && <span className="active-underline" />}
        </div>
    );
};
