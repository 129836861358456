import { TabsConfig } from '../../components/tabs/useTabs';

export enum ReportsTabs {
    CashIncome = 'cash-income',
    RecognitionMinutes = 'recognition-minutes',
    RecognitionPhrase = 'recognition-phrase',
}

export const DEFAULT_TAB = ReportsTabs.RecognitionMinutes;

export interface ViewProps {
    tabsConfig: TabsConfig;
    initialTab: ReportsTabs;
}
