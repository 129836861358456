import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { ButtonType } from '../../../../../common/enum/buttonType';
import { httpService } from '../../../../../common/http';
import { Button } from '../../../../../components/button';
import { Input } from '../../../../../components/input';
import { Modal } from '../../../../../components/modal';
import { ParentNodeSelector } from '../../../../../components/parentNodeSelector';
import { AppDispatch } from '../../../../../redux';
import { getKeywordDocument } from '../../../../../redux/actions/catalog';
import { selectedKeywordDocumentSelector } from '../../../../../redux/selectors/catalog';
import { selectedNodeSelector } from '../../../../../redux/selectors/keyword';
import { setSelectedKeywordDocument } from '../../../../../redux/slices/catalog';
import { backendRoutes } from '../../../../../routes/backend';
import './style.scss';

export const CopyModal = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const { id } = useParams<'id'>();
    const document = useSelector(selectedKeywordDocumentSelector);
    const parentNode = useSelector(selectedNodeSelector);

    const form = useForm<any>({
        defaultValues: {
            parent_node: {
                label: parentNode?.key,
                value: parentNode?.id,
            },
        },
    });

    useEffect(() => {
        if (id) {
            dispatch(getKeywordDocument(+id));
        }
    }, [id, navigate, dispatch]);

    if (id && !document) {
        return null;
    }

    const onClose = () => {
        dispatch(setSelectedKeywordDocument(null));
        navigate('/admin/database');
    };

    const onSubmit = form.handleSubmit(async (data) => {
        if (document && document.countries) {
            const dto = {
                name: document.name,
                keywordId: data.parent_node.value,
                content: document.content,
                countryIds: document.countries.map(({ id }) => id),
            };
            await httpService.post(backendRoutes.addDocument, dto);
            onClose();
            toast.success(`Article "${document.name}" was successfully copied to ${data.parent_node.label} `);
        }
    });

    return (
        <Modal isOpen onClose={onClose} title="Copy article" className="copy-modal">
            {document && (
                <Input
                    value={document.keyword.key}
                    title="Current parent node"
                    type="text"
                    className="parent-node-selector-modal-search"
                />
            )}
            <FormProvider {...form}>
                <form onSubmit={onSubmit}>
                    <Controller
                        render={({ field }) => (
                            <ParentNodeSelector showEdit onChange={field.onChange} value={field.value} />
                        )}
                        name="parent_node"
                    />
                    <div className="parent-node-selector-modal-buttons">
                        <Button
                            title="Save"
                            type={ButtonType.primary}
                            buttonType="submit"
                            disabled={form.formState.isSubmitting}
                        />
                        <Button
                            title="Cancel"
                            type={ButtonType.secondary}
                            onClick={onClose}
                            disabled={form.formState.isSubmitting}
                        />
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};
