import { toast } from 'react-toastify';

import { Modal } from '../modal';
import { backendRoutes } from '../../routes/backend';
import { FontTitle } from '../fontTitle';
import { ScrollableContainer } from '../scrollableContainer';

import { ViewProps } from './model';

import './style.scss';

export const View = (props: ViewProps) => {
    const { onClose, resources, getResources } = props;
    return (
        <Modal className="select-image-modal" isOpen={true} onClose={onClose}>
            <FontTitle level={3}>Click the image to select url</FontTitle>
            <ScrollableContainer className="files-wrapper" onScrollToBottom={getResources}>
                {resources.map((file) => (
                    <div className="image-wrapper" key={file.linkFileName}>
                        <img
                            onClick={() => {
                                toast.info('link copied to clipboard');
                                navigator.clipboard.writeText(backendRoutes.resourceByGuid(file.linkFileName));
                            }}
                            className="image"
                            alt={file.originalFileName}
                            src={backendRoutes.resourceByGuid(file.linkFileName)}
                        />
                        <div className="original-file-name">{file.originalFileName}</div>
                    </div>
                ))}
            </ScrollableContainer>
        </Modal>
    );
};
