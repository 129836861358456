import { forwardRef, useCallback, useState } from 'react';

import { SelectOption, SelectProps } from './model';
import { SelectView } from './view';

export const Select = forwardRef<HTMLDivElement, SelectProps>(({ onChange, ...props }, forwardRef) => {
    const [focused, setFocused] = useState(false);

    const onClose = useCallback(() => setFocused(false), []);
    const onSelect = useCallback(
        (opt: SelectOption) => {
            if (props.multiple) {
                const prev = (props.value as SelectOption[]) ?? [];
                onChange([...prev, opt]);
            } else {
                onChange(opt);
                onClose();
            }
        },
        [props.multiple, props.value, onChange, onClose],
    );
    const onDeselect = useCallback(
        (opt: SelectOption) => {
            onChange((props.value as SelectOption[]).filter((i) => i.value !== opt.value));
        },
        [props.value, onChange],
    );

    return SelectView({
        ...props,
        forwardRef,
        focused,
        onSelect,
        onDeselect,
        onClose,
        setFocused,
    });
});
