import { RootState } from '../../index';
import { User } from '../../../common/model/user';
import { Country } from '../../../common/model/country';

export const authTokenSelector = (state: RootState) => state.auth.token;

export const authUserSelector = (state: RootState) => (state.auth.token ? state.auth.user : undefined);

export const authenticatedUserSelector = (state: RootState) =>
    state.auth.user ??
    ({
        country: {} as Country,
    } as User);

export const userSelector = (state: RootState) => state.users.user;

export const usersSelector = (state: RootState) => state.users.users;
