import io, { Socket } from 'socket.io-client';

export enum WsMessage {
    Speak = 'audio',
    Start = 'start-audio',
    Stop = 'stop-audio',
    Resume = 'resume-audio',
    Pause = 'pause-audio',
}

export class WsService {
    private url = process.env.REACT_APP_BACKEND_WS ?? '';
    private socket: Socket;

    public start<T>(token: string, onMessage?: (data: T) => void, onError?: (e: any) => void) {
        if (this.socket?.connected) {
            return;
        }

        this.socket = io(this.url, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: Infinity,
            auth: { token },
        });

        this.socket.on('error', (e) => {
            onError?.(e);
        });
        this.socket.on('message', (data: T) => {
            onMessage?.(data);
        });

        this.socket.emit(WsMessage.Start);
    }

    public send(buffer: ArrayBuffer) {
        this.socket?.emit(WsMessage.Speak, buffer);
    }

    public pause() {
        this.socket?.emit(WsMessage.Pause);
    }

    public resume() {
        this.socket?.emit(WsMessage.Resume);
    }

    public stop() {
        this.socket?.emit(WsMessage.Stop);
        this.socket?.close();
    }
}
