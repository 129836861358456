import { useNavigate } from 'react-router';

import { Modal } from '../../components/modal';
import { PaymentHistoryModal } from '../../components/paymentCard/paymentHistoryModal';
import { Routes } from '../../routes/routes';

export const ProfilePaymentHistory = () => {
    const navigate = useNavigate();
    const onClose = () => {
        navigate(Routes.profile);
    };

    return (
        <Modal title="PAYMENT HISTORY" className="payment-history-modal" isOpen onClose={onClose}>
            <PaymentHistoryModal />
        </Modal>
    );
};
