import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../redux';
import { selectedKeywordDocumentSelector } from '../../redux/selectors/catalog';
import { getKeywordDocument } from '../../redux/actions/catalog';
import { setSelectedKeywordDocument } from '../../redux/slices/catalog';

import { View } from './view';
import { ArticleProps } from './model';

export { Wrapper as ArticleWrapper } from './components/wrapper';

export const Article = ({ isNew = false }: ArticleProps) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    const { id } = useParams<'id'>();
    const document = useSelector(selectedKeywordDocumentSelector);

    useEffect(() => {
        if (id) {
            dispatch(getKeywordDocument(+id));
        }
    }, [id, isNew, navigate, dispatch]);

    if (id && !document) {
        return null;
    }

    return View({
        isNew,
        onClose: () => {
            dispatch(setSelectedKeywordDocument(null));
            navigate('/admin/database');
        },
        document,
    });
};
