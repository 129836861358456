import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../redux';
import { AudioService, AudioServiceState } from '../../components/speaker/services/audioService';
import { WsService } from '../../components/speaker/services/wsService';

export const useStreamRecognition = <T>(
    onReceive?: (data: T) => void,
    onError?: (e: any) => void,
    onstatechange?: (state: AudioServiceState) => void,
) => {
    const token = useSelector((state: RootState) => state.auth.token);
    const prevToken = useRef(token);

    const onReceiveRef = useRef(onReceive);
    if (onReceiveRef.current !== onReceive) {
        onReceiveRef.current = onReceive;
    }
    const onErrorRef = useRef(onError);
    if (onErrorRef.current !== onError) {
        onErrorRef.current = onError;
    }
    const onStatechangeRef = useRef(onstatechange);
    if (onStatechangeRef.current !== onstatechange) {
        onStatechangeRef.current = onstatechange;
    }

    const audioService = useRef(new AudioService()).current;
    const wsService = useRef(new WsService()).current;

    const handlers = useMemo(() => {
        return {
            start: async () => {
                if (!token) return;

                try {
                    await audioService.start(wsService.send.bind(wsService), onStatechangeRef.current);
                    wsService.start(token, onReceiveRef.current, onErrorRef.current);
                } catch (e) {
                    wsService.stop();
                    await audioService.stop();
                }
            },
            resume: async () => {
                await audioService.resume();
                wsService.resume();
            },
            pause: async () => {
                await audioService.pause();
                wsService.pause();
            },
            stop: async () => {
                await audioService.stop();
                wsService.stop();
            },
        };
    }, [audioService, token, wsService]);

    useEffect(() => {
        if (token && token !== prevToken.current) {
            handlers.start();
        }

        return () => {
            handlers.stop();
        };
    }, [handlers, token]);

    return handlers;
};
