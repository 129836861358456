import { AppThunk } from '../..';
import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import { setDocuments, setDocumentsCount } from '../../slices/documents';

export const getByIdDocuments = (page = 1, size = 6, categoryId: number, filterByCountry?: boolean): AppThunk => {
    return async (dispatch) => {
        try {
            const result = await httpService.get(
                `${backendRoutes.getDocumentsForCategory.replace(
                    ':categoryId',
                    categoryId.toString(),
                )}?page=${page}&size=${size}&filterByCountry=${filterByCountry}`,
            );
            dispatch(setDocuments(result.documents));
            dispatch(setDocumentsCount(result.count));
        } catch (e) {
            console.error(e);
        }
    };
};
