import { AppThunk } from '../../index';
import { httpService } from '../../../common/http';
import { backendRoutes } from '../../../routes/backend';
import {
    setDeleteKeywords,
    setDeleteKeywordsError,
    setDeleteKeywordsLoading,
    setNoGoKeywords,
    setNoGoKeywordsError,
    setNoGoKeywordsLoading,
} from '../../slices/dictionaries';
import { DeleteKeyword, NoGoKeyword } from '../../../common/model/dictionaries';

export const getDeleteKeywords = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setDeleteKeywordsLoading(true));
            dispatch(setDeleteKeywordsError(null));

            const data = await httpService.get<DeleteKeyword[]>(backendRoutes.getDeleteKeywords);
            dispatch(setDeleteKeywords(data));
        } catch (e) {
            dispatch(setDeleteKeywordsError('Failed to get delete keywords'));
        } finally {
            dispatch(setDeleteKeywordsLoading(false));
        }
    };
};

export const updateDeleteKeywords = (data: DeleteKeyword[]): AppThunk => {
    return async (dispatch) => {
        await httpService.post(backendRoutes.updateDeleteKeywords, data);
        dispatch(setDeleteKeywords(data));
    };
};

export const getNoGoKeywords = (): AppThunk => {
    return async (dispatch) => {
        try {
            dispatch(setNoGoKeywordsLoading(true));
            dispatch(setNoGoKeywordsError(null));

            const data = await httpService.get<NoGoKeyword[]>(backendRoutes.getNoGoKeywords);
            dispatch(setNoGoKeywords(data));
        } catch (e) {
            dispatch(setNoGoKeywordsError('Failed to get delete keywords'));
        } finally {
            dispatch(setNoGoKeywordsLoading(false));
        }
    };
};

export const updateNoGoKeywords = (data: NoGoKeyword[]): AppThunk => {
    return async (dispatch) => {
        await httpService.post(backendRoutes.updateNoGoKeywords, data);
        dispatch(setNoGoKeywords(data));
    };
};
