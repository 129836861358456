import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { FC } from 'react';

import { Button } from '../button';
import { Title } from '../title';
import { ButtonType } from '../../common/enum/buttonType';
import { authUserSelector } from '../../redux/selectors/auth';
import { Routes } from '../../routes/routes';

import { AuthHeader } from './headerAuthorized';

import './style.scss';

export const Header: FC<any> = (props: any) => {
    const navigate = useNavigate();
    const user = useSelector(authUserSelector);

    const toLoginPageClick = () => {
        navigate(Routes.login);
    };
    const toSignUpPageClick = () => {
        navigate(Routes.signUp);
    };

    const toAboutUsPageClick = () => {
        navigate(Routes.aboutUs);
    };
    const toMainPageClick = () => {
        navigate(Routes.home);
    };

    return (
        <>
            {!user ? (
                <div className="header">
                    <Title type="header" onClick={toMainPageClick} />
                    <div className="button-container">
                        <Button type={ButtonType.borderOff} title="About us" onClick={toAboutUsPageClick} />
                        <Button type={ButtonType.secondary} title="LOG IN" onClick={toLoginPageClick} />
                        <Button type={ButtonType.primary} title="SIGN UP" onClick={toSignUpPageClick} />
                    </div>
                </div>
            ) : (
                <AuthHeader />
            )}
            {props.children}
        </>
    );
};
