import { forwardRef, PropsWithChildren } from 'react';
import cn from 'classnames';

import { ViewProps } from './model';

import './style.scss';

export const View = forwardRef<HTMLDivElement, PropsWithChildren<ViewProps>>(
    (props: PropsWithChildren<ViewProps>, ref) => {
        const { children, className } = props;

        return (
            <div className={cn(className, 'scrollable-container')} ref={ref}>
                {children}
            </div>
        );
    },
);

View.displayName = 'View';
