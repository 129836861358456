import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { store } from '../../redux';

export class HttpService {
    private readonly api: AxiosInstance;

    constructor(baseURL: string) {
        this.api = axios.create({ baseURL });
        this.api.interceptors.request.use(this.requestInterceptors.bind(this));
    }

    public async post<T = any>(url: string, data: any, headers?: any): Promise<T> {
        const res = await this.api.post<T>(url, data, { headers });
        return res.data;
    }

    public async patch<T = any>(url: string, data: any, headers?: any): Promise<T> {
        const res = await this.api.patch<T>(url, data, { headers });
        return res.data;
    }

    public async put<T = any>(url: string, data: any, headers?: any): Promise<T> {
        const res = await this.api.put<T>(url, data, { headers });
        return res.data;
    }

    public async delete<T = any>(url: string, headers?: any): Promise<T> {
        const res = await this.api.delete<T>(url, headers);
        return res.data;
    }

    public async get<T = any>(url: string, headers?: any): Promise<T> {
        const res = await this.api.get<T>(url, { headers });
        return res.data;
    }

    private requestInterceptors(config: AxiosRequestConfig): AxiosRequestConfig {
        const state = store.getState();
        if (state.auth.token) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${state.auth.token}`,
            };
        }

        return config;
    }
}

export const httpService = new HttpService(process.env.REACT_APP_BACKEND ?? '');
