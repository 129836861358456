import { FontTitle } from '../fontTitle';

import { ViewProps } from './model';

import './style.scss';

export const View = (props: ViewProps) => {
    const { message, title } = props;
    return (
        <div className="popup-message">
            <FontTitle className="popup-message_title" level={3}>
                {title}
            </FontTitle>
            <p className="popup-message_text">{message}</p>
        </div>
    );
};
