import { PropsWithChildren, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { ScrollableContainerProps } from './model';
import { View } from './view';

export function ScrollableContainer(props: PropsWithChildren<ScrollableContainerProps>) {
    const { children, onScrollToBottom, className } = props;

    const ref = useRef<HTMLDivElement>(null);

    const onScroll = useDebouncedCallback(() => {
        if (!ref.current) return;

        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        const bottom = scrollTop + clientHeight + 20 >= scrollHeight;
        if (bottom) {
            onScrollToBottom();
        }
    }, 200);

    useEffect(() => {
        ref.current && ref.current.addEventListener('scroll', onScroll);

        const current = ref.current;
        return () => {
            current && current.removeEventListener('scroll', onScroll);
        };
    }, [onScroll]);

    return <View ref={ref} children={children} className={className} />;
}
