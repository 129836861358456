import { FormProvider } from 'react-hook-form';
import React from 'react';

import { ButtonType } from '../../../../common/enum/buttonType';
import { Button } from '../../../../components/button';
import { FormField } from '../../../../components/formField';

import { ViewProps } from './model';

export const View = ({ form, onSubmit, onClose }: ViewProps) => {
    const formValues = form.getValues();

    return (
        <FormProvider {...form}>
            <form onSubmit={onSubmit} className="user-form">
                <div className="user-info-block">
                    <div className="user-info-block-row">
                        <p className="user-info-block-title">Login</p>
                        <p className="user-info-block-value">{formValues.login}</p>
                    </div>
                    <div className="user-info-block-row">
                        <p className="user-info-block-title">E-mail</p>
                        <p className="user-info-block-value">{formValues.email}</p>
                    </div>
                    <div className="user-info-block-row">
                        <p className="user-info-block-title">Region</p>
                        <p className="user-info-block-value">{formValues.country.name}</p>
                    </div>
                    <div className="user-info-block-checkboxes">
                        <div className="user-info-block-checkbox">
                            <FormField name="isAdmin" type="checkbox" title="Appoint as administrator" />
                        </div>
                        <div className="user-info-block-checkbox">
                            <FormField name="isBlocked" type="checkbox" title="Block user" />
                        </div>
                    </div>
                </div>
                <div className="user-subscription-history-block">
                    <h3>Subscription history</h3>
                </div>
                <div className="user-form-buttons-block">
                    <Button
                        buttonType="submit"
                        title="Save changes"
                        type={ButtonType.primary}
                        disabled={form.formState.isSubmitting || !form.formState.isDirty}
                    />
                    <Button
                        title="Cancel"
                        type={ButtonType.secondary}
                        onClick={onClose}
                        disabled={form.formState.isSubmitting}
                    />
                </div>
            </form>
        </FormProvider>
    );
};
